import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native';
import Button from '../components/Button';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import Notes from './Notes';
import Api from '../api/Api';
import Config from '../config/Config';
import { useSelector } from 'react-redux';
import * as accountActions from '../redux/actions/account';
import Notifications from '../pages/Notifications';

// eslint-disable-next-line no-sparse-arrays
const DATA = [
  {
    id: 1,
    name: 'Dashboard',
    path: '/dashboard',
    icon: require('../assets/Icons/Dashboard.png'),
  },
  // {
  //   id: 2,
  //   name: 'Analytics',
  //   path: '/analytics',
  // },
  {
    id: 3,
    name: 'Πελάτες',
    path: '/clients',
    icon: require('../assets/Icons/Clients.png'),
  },
  {
    id: 4,
    name: 'Εταιρίες',
    path: '/companies',
    icon: require('../assets/Icons/Companies.png'),
  },
  {
    id: 5,
    name: 'Εγκαταστάσεις',
    path: '/sites',
    icon: require('../assets/Icons/Sites.png'),
  },
  {
    id: 6,
    name: 'Συντήρηση',
    path: '/works',
    icon: require('../assets/Icons/Works.png'),
  },
  {
    id: 7,
    name: 'Τεχνικοί',
    path: '/staff',
    icon: require('../assets/Icons/Staff.png'),
  },
  // {
  //   id: 8,
  //   name: 'Υλικά',
  //   path: '/materials',
  //   icon: require('../assets/Icons/Materials.png'),
  // },
];

// eslint-disable-next-line no-sparse-arrays
const administrationData = [
  { id: 10, name: 'Χρήστες', path: '/Users' },
  // { id: 20, name: 'Δικαιώματα Χρηστών', path: '/roles' },
  { id: 30, name: 'Συμβόλαια', path: '/contracts' },
  { id: 40, name: 'Υπηρεσίες', path: '/services' },
  // { id: 50, name: 'Κατηγορίες Υλικών', path: '/materialCategories' },
  { id: 60, name: 'Κατασκευαστές/Προμηθευτές', path: '/suppliers' },
  // { id: 70, name: 'Κατηγορίες Εγκαταστάσεων', path: '/siteCategories' },
  {
    id: 80,
    name: 'Service Tasks Templates',
    path: '/serviceTaskTemplates',
  },
  // { id: 90, name: 'Tasks', path: '/tasks' },
  // { id: 100, name: 'Task Categories', path: '/taskCategories' },
  // { id: 110, name: 'Αρχείο Αλλαγών', path: '/changeloglist' },
];

function Item({ item }) {
  let history = useHistory();
  return (
    <TouchableOpacity
      style={styles.item}
      onPress={() => history.push(item.path)}
    >
      <View
        style={{
          flexDirection: 'row',
          marginLeft: 30,
        }}
      >
        <Image style={{ width: 70, height: 70 }} source={item.icon} />
        <Text style={styles.itemName}>{item.name}</Text>
      </View>
    </TouchableOpacity>
  );
}

function InnerItem({ path, name, onPress }) {
  return (
    <TouchableOpacity style={styles.item} onPress={onPress}>
      <Text style={styles.itemName2}>{name}</Text>
    </TouchableOpacity>
  );
}

const AdminLayout = (props) => {
  if (!cookie.load('token')) {
    window.location.replace('/login');
  }

  const [toggleState, setToggleState] = useState(true);
  const [toggleAdministrationState, setToggleAdministrationState] = useState(
    true
  );
  const [language, setLanguage] = useState('GR');

  const state = useSelector((state) => state.accountReducer);

  function toggle() {
    setToggleState(!toggleState);
  }

  function toggleAdministration() {
    setToggleAdministrationState(!toggleAdministrationState);
  }
  let history = useHistory();

  const [optionsState, setOptionsState] = useState(true);

  const [dashboardState, setToggleDashboard] = useState(true);

  const toggleDashboard = () => {
    setToggleDashboard(!dashboardState);
  };

  function DashboardItem({ item }) {
    return (
      <TouchableOpacity style={styles.item} onPress={() => toggleDashboard()}>
        <View
          style={{
            flexDirection: 'row',
            marginLeft: 30,
          }}
        >
          <Image style={{ width: 70, height: 70 }} source={item.icon} />
          <Text style={styles.itemName}>{item.name}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  const toggleOptions = () => {
    console.log('Current State', state);
    setOptionsState(!optionsState);
  };

  function renderAdministrationChoices() {
    return (
      <View>
        {administrationData.map((item) => (
          <InnerItem name={item.name} onPress={() => history.push(item.path)} />
        ))}
      </View>
    );
  }

  const myCallBack = () => {
    toggle();
    return (
      <View style={{ position: 'absolute' }}>
        <TouchableOpacity
          onPress={() => toggle()}
          style={styles.myPvSoftContainer}
        >
          {/* <Text style={styles.pvText}>PV</Text>
          <View style={styles.myPvSoft}>
            <Text style={styles.pvText2}>MYPV</Text>
            <Text style={styles.pvText2}>SOFT</Text>
          </View> */}
        </TouchableOpacity>
      </View>
    );
  };

  const renderDashboardList = () => {
    return (
      <View style={{ bottom: 45, height: 100 }}>
        <InnerItem
          name="Main Dashboard"
          onPress={() => history.push('/dashboard')}
        />
        <InnerItem
          name="Analytics"
          onPress={() => history.push('/analytics')}
        />
        <InnerItem
          name="Statistics"
          onPress={() => history.push('/statistics')}
        />
      </View>
    );
  };

  const logout = async () => {
    await Api.get('/account/logout');
    window.location.replace('/login');
  };

  const renderOptions = () => {
    return (
      <View style={{ marginLeft: 5, position: 'absolute', top: 35 }}>
        <TouchableOpacity onPress={logout}>
          <Text style={styles.logout}>LOGOUT</Text>
        </TouchableOpacity>
      </View>
    );
  };

  useEffect(() => {
    accountActions.fetchAccount();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.rowDirection}>
        <View style={[styles.container, { width: toggleState ? 300 : 0 }]}>
          <ScrollView>
            <View>
              <TouchableOpacity
                onPress={() => myCallBack()}
                style={styles.myPvSoftContainer}
              >
                <Image
                  style={{ width: 150, height: 50 }}
                  source={require('../assets/Icons/MY PV SOFT_LOGO_RGB-WHITE.png')}
                />
              </TouchableOpacity>
              <DashboardItem item={DATA[0]} />
              {dashboardState ? null : renderDashboardList()}
              {DATA.slice(1).map((item) => (
                <Item item={item} />
              ))}

              <TouchableOpacity
                style={styles.item}
                onPress={() => toggleAdministration()}
              >
                <Text style={styles.administration}>Administration</Text>
              </TouchableOpacity>
              {!toggleAdministrationState
                ? renderAdministrationChoices()
                : null}
            </View>
          </ScrollView>
        </View>

        <View style={styles.layout}>
          <View>
            <View style={styles.headerStyle}>
              <Button
                onPress={toggle}
                style={[
                  styles.close,
                  {
                    backgroundColor: '#eeeeee',
                    width: 50,
                    height: 30,
                    bottom: 10,
                  },
                ]}
              >
                <Text style={{ fontSize: 20 }}>&#8801;</Text>
              </Button>
              <View style={styles.headerIcons}>
                <View style={{ flexDirection: 'row', bottom: 91 }}>
                  <TouchableOpacity style={styles.dropdown}>
                    <Text style={styles.dropdownText}>EN ⏷</Text>
                  </TouchableOpacity>
                  <View>
                    <TouchableOpacity
                      onPress={toggleOptions}
                      style={styles.dropdown}
                    >
                      <Text style={styles.dropdownText}> ADMIN ⏷</Text>
                    </TouchableOpacity>
                    {optionsState ? null : renderOptions()}
                  </View>
                </View>
              </View>
            </View>
          </View>
          {props.children}
        </View>
        <View style={styles.notesContainer}>
          <Notes />
          <View style={{ right: 38, zIndex: -1 }}>
            <Notifications />
          </View>
          <View
            style={{
              position: 'fixed',
              bottom: 0,
              marginBottom: 15,
              width: 200,
            }}
          >
            <Button
              title="wizard"
              onPress={() => window.location.replace('/wizard')}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default AdminLayout;

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    backgroundColor: '#eaeaea',
    padding: 30,
  },

  logout: {
    color: '#ffffff',
    backgroundColor: 'orangered',
    borderRadius: 25,
    width: 80,
    borderWidth: 1,
    borderColor: 'orangered',
    textAlign: 'center',
  },
  headerStyle: {
    flex: 1,
    backgroundColor: '#eaeaea',
  },

  myPvSoftContainer: {
    marginTop: 40,
    justifyContent: 'flex-end',
    marginRight: 30,
    flexDirection: 'row',
  },

  pvText: {
    fontSize: '40px',
    wordSpacing: '20px',
    color: '#ffffff',
    marginRight: 5,
    fontWeight: 'bold',
  },
  pvText2: {
    fontSize: '15px',
    wordSpacing: '20px',
    color: '#ffffff',
    marginRight: 5,
    fontWeight: 'bold',
  },
  myPvSoft: {
    marginTop: 8,
  },
  close: {
    padding: 10,
    width: 200,
    height: 50,
    backgroundColor: 'lightgray',
  },
  container: {
    flexDirection: 'column',
    alignSelf: 'flexStart',
    backgroundColor: Config.COLORS.primary.background,
  },

  item: {
    marginTop: 20,
    paddingVertical: 10,
  },

  dropVisible: {
    position: 'absolute',
    backgroundColor: 'white',
    height: 100,
    width: 100,
    top: 100,
  },

  administration: {
    marginLeft: 100,
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
  },

  headerIcons: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 58,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },

  itemName: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 24,
    marginLeft: 20,
  },

  itemName2: {
    color: '#ffffff',
    fontSize: 14,
    marginLeft: 130,
  },

  notesContainer: {
    paddingHorizontal: 45,
    backgroundColor: '#eaeaea',
  },

  administrationText: {
    fontSize: 14,
    marginLeft: 15,
  },
  rowDirection: { flex: 1, flexDirection: 'row' },
  listAlign: { alignSelf: 'center', color: 'white' },

  dropdown: {
    width: 90,
    borderWidth: 1,
    borderRadius: 25,
    backgroundColor: Config.COLORS.primary.background,
    borderColor: Config.COLORS.primary.background,
    marginRight: 10,
  },

  dropdownText: {
    textAlign: 'center',
    color: '#ffffff',
    marginTop: 2,
  },
});
