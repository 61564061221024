import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native-web';
import { Table, Row, Rows } from 'react-native-table-component';
import { ScrollView } from 'react-native-gesture-handler';

class DataTable extends Component {
  tableData = () =>
    this.props.data.map((item) => {
      let newRow = [];
      for (let key in this.props.dataMap) {
        newRow.push(item[key]);
      }

      return newRow;
    });

  tableHead = Object.values(this.props.dataMap);

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.container} horizontal={true}>
          <Table
            style={styles.container}
            borderStyle={[styles.border, this.props.borderStyle]}
          >
            <Row
              widthArr={this.props.widthArr}
              data={this.tableHead}
              style={[styles.head, this.props.rowStyle]}
              textStyle={styles.rowText}
            />
            <Rows
              widthArr={this.props.widthArr}
              data={this.tableData()}
              textStyle={styles.text}
            />
          </Table>
        </ScrollView>
      </View>
    );
  }
}

export default DataTable;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  head: {
    flex: 1,
    backgroundColor: 'white',
    height: 50,
  },
  rowText: {
    color: '#ee4023',
    backgroundColor: 'red',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 5,
  },
  text: { fontSize: 14, marginTop: 2, fontFamily: 'ManropeRegular' },
});
