import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import InputText from '../components/Inputs/InputText';
import InputLookUp from '../components/Inputs/InputLookUp';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Api from '../api/Api';
import Button from '../components/Button';
import CardBlock from '../components/CardBlock';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import DetailedTable from '../components/Tables/DetailedTable';

export default (props) => {
  const [name, setName] = useState();
  const [service, setService] = useState({ name: '', id: null });
  const [status, setStatus] = useState();
  const [viewMode, setViewMode] = useState('view');
  const history = useHistory();

  let viewPath = useRouteMatch({
    path: '/servicetasktemplates/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/servicetasktemplates/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/servicetasktemplates/add',
    exact: true,
  });

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(
                `/servicetasktemplates/${props.match.params.id}/edit`
              )
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  async function addServiceTaskTemplate() {
    let res = await Api.post('/admin/serviceTaskTemplates', {
      name: name,
      service_id: service.id,
      status: status,
    });
    if (res?.data?.status === 'ok') {
      history.replace('/servicetasktemplates');
    }
  }

  async function editServiceTaskTemplate() {
    await Api.put('/admin/serviceTaskTemplates/' + props.match.params.id, {
      name: name,
      service_id: service.id,
      status: status,
    });
  }

  function clearState() {
    setName('');
    setStatus();
    setService();
  }

  useEffect(() => {
    const fetchServiceTaskTemplate = async () => {
      const res = await Api.get(
        '/admin/serviceTaskTemplates/' + props.match.params.id
      );
      let data = res.data.data;
      console.log(data);
      setName(data.name);
      setService({ id: data.service_id, name: data.service });
      setStatus(data.status);
    };
    if (props.match.params.id > 0) {
      fetchServiceTaskTemplate();
    }
  }, [props.match.params.id, viewMode]);

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    if (viewMode === 'add') {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => addServiceTaskTemplate()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => editServiceTaskTemplate()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    }
  }
  return (
    <View>
      <CardBlock padding={20}>
        <View style={{ width: 300 }}>
          <InputText
            displayTitle="Ονομα"
            viewMode={isView}
            value={name}
            onChangeText={(e) => setName(e)}
            multiline={true}
          />
          <View style={{ marginTop: 20, marginLeft: 13 }}>
            <InputLookUp
              viewMode={isView}
              displayTitle="Υπηρεσία"
              value={service}
              displayKey="service"
              endpoint="admin/services"
              searchQueryKey="search"
              onSelect={(item) => setService(item)}
            />
          </View>
          <PickerChoiceSelection
            items={[
              { value: 0, label: 'Disabled', key: 0 },
              { value: 1, label: 'Enabled', key: 1 },
            ]}
            selectedValue={status}
            onValueChange={(item) => setStatus(item)}
            displayTitle="Κατάσταση"
            viewMode={isView}
            value={status}
            textStyle={{ marginLeft: 13, marginTop: 10 }}
            textStyleValue={{ marginLeft: 14 }}
          />
        </View>
        {renderButtons()}
        {renderEditButton()}
      </CardBlock>
      {props.match.params.id !== 'add' && (
        <CardBlock>
          <DetailedTable
            {...tasksPreset}
            viewUrl={`/admin/serviceTaskTemplates/${props.match.params.id}/tasks`}
            addUrl={`/admin/serviceTaskTemplates/${props.match.params.id}/tasks`}
            editUrl={`/admin/serviceTaskTemplates/${props.match.params.id}/tasks/:id`}
            addData={{ tasks_services_template_id: props.match.params.id }}
          />
        </CardBlock>
      )}
    </View>
  );
};

const tasksPreset = {
  fieldList: {
    task_id: {
      name: 'Task',
      component: InputLookUp,
      props: { displayKey: 'task', endpoint: '/admin/tasks' },
    },
  },
};

const styles = StyleSheet.create({
  buttonForm: {
    width: 300,
    flexDirection: 'row',
    marginRight: 20,
  },
  button: {
    width: 120,
    marginLeft: 15,
    marginTop: 25,
  },
});
