import React, { Component } from 'react';
import PersonalInfo from './PersonalInfo.js';
import CompanyDetails from './CompanyDetails';
import SiteInfo from './SiteInfo';
import Api from '../../api/Api';
import Messages from '../Messages';
import { View } from 'react-native';

export class StepForm extends Component {
  state = {
    step: 1,

    // step 1
    firstName: '',
    lastName: '',
    username: '',
    address: '',
    city: '',
    country: '',
    email: '',
    status: '',
    createUser: false,

    // step 2
    name: '',
    companyCity: '',
    companyAddress: '',
    postCode: '',
    taxAuthority: '',
    vat: '',
    statArea: '',

    //step 3
    siteName: '',
    description: '',
    power: '',
    gaugeNumber: '',
    provisionNumber: '',
    initialPerformance: '',
    lat: '',
    lng: '',
    location: '',
    category: '',

    errorMessage: '',
  };

  validate = () => {
    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.username ||
      !this.state.name ||
      !this.state.siteName ||
      !this.state.gaugeNumber ||
      !this.state.provisionNumber
    ) {
      this.setState({
        errorMessage: 'Required Fields are missing',
      });
      return false;
    }
    this.setState({
      errorMessage: '',
    });
    return true;
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  submit = async () => {
    const st = this.state;
    let client = {
      client: st.firstName + ' ' + st.lastName,
      address: st.address,
      city: st.city,
      country: st.country,
      email: st.email,
      status: st.status,
    };

    let user;
    if (st.createUser) {
      user = {
        first_name: st.firstName,
        last_name: st.lastName,
        email: st.username,
        address: st.address,
        city: st.city,
        country: st.country,
        status: st.status,
      };
    }

    let company = {
      name: st.name,
      city: st.companyCity,
      address: st.companyAddress,
      post_code: st.postCode,
      tax_authority: st.taxAuthority,
      vat: st.vat,
      stat_area: st.statArea,
    };

    let site = {
      name: st.siteName,
      description: st.description,
      power: st.power,
      number_metritis: st.gaugeNumber,
      number_paroxi: st.provisionNumber,
      initial_performance: st.initialPerformance,
      lat: st.lat,
      lng: st.lng,
      location: st.location,
      category: st.category,
    };

    if (this.validate()) {
      await Api.post('/admin/wizards/client', {
        client: client,
        company: company,
        site: site,
        user,
      })
        .then((res) => {
          if (res.data?.status === 'ok') {
            window.location.replace('/dashboard');
          } else {
            this.setState({
              errorMessage: 'Error: ' + (res.data.error || ''),
            });
          }
        })
        .catch(() => {
          this.setState({
            errorMessage: 'Network Error',
          });
        });
    }
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  showStep = () => {
    const {
      step,
      firstName,
      lastName,
      username,
      address,
      city,
      country,
      name,
      companyCity,
      companyAddress,
      postCode,
      taxAuthority,
      vat,
      statArea,
      siteName,
      description,
      power,
      gaugeNumber,
      provisionNumber,
      initialPerformance,
      lat,
      lng,
      location,
      category,
      createUser,
      email,
      status,
    } = this.state;

    if (step === 1) {
      return (
        <PersonalInfo
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          firstName={firstName}
          lastName={lastName}
          address={address}
          username={username}
          city={city}
          country={country}
          createUser={createUser}
          email={email}
          status={status}
        />
      );
    }
    if (step === 2) {
      return (
        <CompanyDetails
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChange={this.handleChange}
          country={country}
          name={name}
          companyCity={companyCity}
          companyAddress={companyAddress}
          postCode={postCode}
          taxAuthority={taxAuthority}
          vat={vat}
          statArea={statArea}
        />
      );
    }
    if (step === 3) {
      return (
        <>
          <SiteInfo
            finish={this.submit}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            siteName={siteName}
            description={description}
            power={power}
            gaugeNumber={gaugeNumber}
            provisionNumber={provisionNumber}
            initialPerformance={initialPerformance}
            lat={lat}
            lng={lng}
            location={location}
            category={category}
          />
          <View style={{ width: 300 }}>
            <Messages
              type={'error'}
              isVisible={!!this.state.errorMessage}
              message={this.state.errorMessage}
            />
          </View>
        </>
      );
    }
  };

  render() {
    const { step } = this.state;

    return (
      <>
        <h2>Step {step} of 3.</h2>
        {this.showStep()}
      </>
    );
  }
}

export default StepForm;
