import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useRouteMatch } from 'react-router-dom';
import { GridLayout, Row, Col } from '../components/Grid';
import { createBrowserHistory } from 'history';
import { TabView, Tab } from 'react-native-tab-view-easy';
import { TabBar } from 'react-native-tab-view';
import DetailedTable from '../components/Tables/DetailedTable';
import CardBlock from '../components/CardBlock';
import Api from '../api/Api';
import Button from '../components/Button';
import InputText from '../components/Inputs/InputText';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import Messages from '../components/Messages';

export default (props) => {
  const [viewMode, setViewMode] = useState('view');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [company, setCompany] = useState('');
  const [speciality, setSpeciality] = useState();
  const [status, setStatus] = useState();
  const [fatherName, setFatherName] = useState();
  const [vatNo, setVatNo] = useState();
  const [vatOffice, setVatOffice] = useState();
  const [socialSecurity, setSocialSecurity] = useState();
  const [mobile, setMobile] = useState();
  const [tel, setTel] = useState();
  const [fax, setFax] = useState();
  const [email, setEmail] = useState();
  const [web, setWeb] = useState();
  const [userId, setUserId] = useState();
  const [address, setAddress] = useState();
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [notes, setNotes] = useState();
  const [contract, setContract] = useState({ id: null, name: '' });
  const [city, setCity] = useState();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [siteName, setSiteName] = useState({ id: null, name: '' });
  const [contractName, setContractName] = useState({ id: null, name: '' });
  const [siteContractStatus, setSiteContractStatus] = useState();
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [staffSiteContracts, setStaffSiteContracts] = useState([]);

  let history = createBrowserHistory();

  let viewPath = useRouteMatch({
    path: '/staff/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/staff/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/staff/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  useEffect(() => {
    const fetchStaff = async () => {
      const res = await Api.get('/admin/staff/' + props.match.params.id);
      let data = res.data.data;
      setLastName(data.lastname);
      setFirstName(data.firstname);
      setFatherName(data.fathername);
      setSpeciality(data.speciality);
      setVatNo(data.vat_no);
      setVatOffice(data.vat_office);
      setSocialSecurity(data.socialSecurity);
      setCompany(data.company_name);
      setMobile(data.mobile);
      setTel(data.tel);
      setFax(data.fax);
      setEmail(data.email);
      setWeb(data.web);
      setUserId(data.userId);
      setAddress(data.address);
      setCity(data.city);
      setPostalCode(data.postal_code);
      setCountry(data.country);
      setStatus(data.status);
      setNotes(data.notes);
    };
    if (props.match.params.id > 0) {
      fetchStaff();
    }
  }, [props.match.params.id, viewMode]);

  useEffect(() => {
    const fetchStaffContracts = async () => {
      const res = await Api.get(
        '/admin/staff/' + props.match.params.id + '/staffcontracts'
      );
      let data = res.data.data;
      console.log(data);
      setStaffSiteContracts(data);
    };
    if (props.match.params.id > 0) {
      fetchStaffContracts();
    }
  }, [props.match.params.id, viewMode]);

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(`/staff/${props.match.params.id}/edit`)
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  const validate = () => {
    if (!lastName || !firstName || !speciality || !company || !status) {
      setErrorMessage('Required fields');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  async function addStaff() {
    if (validate()) {
      let res = await Api.post('/admin/staff', {
        lastname: lastName,
        firstname: firstName,
        fathername: fatherName,
        speciality: speciality,
        vat_no: vatNo,
        vat_office: vatOffice,
        social_security: socialSecurity,
        company_name: company,
        mobile: mobile,
        tel: tel,
        fax: fax,
        email: email,
        web: web,
        user_id: userId,
        address: address,
        city: city,
        country: country,
        contract_id: contract.id,
        notes: notes,
        status: status,
        postal_code: postalCode,
      }).then((response) => {
        history.goBack();
      });
    }
  }

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      scrollEnabled={true}
      // tabStyle={{width: 'auto'}}
      style={{ backgroundColor: '#ee4023' }}
      getLabelText={({ route }) => route.title}
    />
  );

  async function editStaff() {
    await Api.put('/admin/staff/' + props.match.params.id, {
      lastname: lastName,
      firstname: firstName,
      fathername: fatherName,
      speciality: speciality,
      vat_no: vatNo,
      vat_office: vatOffice,
      social_security: socialSecurity,
      company_name: company,
      mobile: mobile,
      tel: tel,
      fax: fax,
      email: email,
      web: web,
      user_id: userId,
      address: address,
      city: city,
      country: country,
      contract_id: contract.id,
      notes: notes,
      status: status,
      postal_code: postalCode,
    }).then((res) => {
      history.goBack();
    });
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addStaff()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editStaff()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  function clearState() {
    setLastName('');
    setFirstName('');
    setFatherName('');
    setSpeciality('');
    setVatNo('');
    setVatOffice('');
    setSocialSecurity('');
    setCompany('');
    setMobile('');
    setTel('');
    setFax('');
    setEmail('');
    setWeb('');
    setUserId('');
    setAddress('');
    setCity('');
    setPostalCode('');
    setCountry('');
    setStatus('');
    setContract('');
    setNotes('');
  }
  return (
    <CardBlock padding={20}>
      <GridLayout>
        <Row>
          <View style={{ width: 300 }}>
            <Col lg={6} md={12}>
              <InputText
                viewMode={isView}
                displayTitle="Επώνυμο"
                value={lastName}
                onChangeText={(e) => setLastName(e)}
                multiline={true}
                placeholder="Επώνυμο"
                isRequired={true}
              />
              <InputText
                viewMode={isView}
                displayTitle="Ονομα"
                value={firstName}
                onChangeText={(e) => setFirstName(e)}
                multiline={true}
                placeholder="'Ονομα "
                isRequired={true}
              />
              <InputText
                viewMode={isView}
                displayTitle="Εταιρία"
                value={company}
                onChangeText={(e) => setCompany(e)}
                multiline={true}
                placeholder="Εταιρία"
                isRequired={true}
              />
            </Col>
          </View>
          <Col lg={6} md={12}>
            <PickerChoiceSelection
              items={[
                { value: 2, label: 'Disabled', key: 0 },
                { value: 1, label: 'Enabled', key: 1 },
              ]}
              selectedValue={status}
              onValueChange={(item) => setStatus(item)}
              displayTitle="Κατάσταση"
              viewMode={isView}
              value={status}
              isRequired={true}
              textStyle={{ marginTop: 6 }}
            />

            <PickerChoiceSelection
              items={[
                { value: 1, label: 'Ηλεκτρολόγος', key: 0 },
                { value: 2, label: 'Συνεργ.Χλοοκοπτών', key: 1 },
              ]}
              selectedValue={speciality}
              onValueChange={(item) => setSpeciality(item)}
              displayTitle="Ειδικότητα"
              viewMode={isView}
              value={speciality}
              isRequired={true}
              textStyle={{ marginTop: 28 }}
            />
          </Col>
        </Row>
      </GridLayout>
      <TabView
        style={{ maxWidth: 1320, width: '100%', marginTop: 5 }}
        renderTabBar={renderTabBar}
      >
        <Tab title="Κύρια Στοιχεία">
          <GridLayout>
            <Row>
              <Col lg={6} md={12}>
                <View style={{ width: 300 }}>
                  <InputText
                    viewMode={isView}
                    displayTitle="Πατρώνυμο"
                    value={fatherName}
                    onChangeText={(e) => setFatherName(e)}
                    multiline={true}
                    placeholder="Πατρώνυμο"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Α.Φ.Μ"
                    value={vatNo}
                    onChangeText={(e) => setVatNo(e)}
                    multiline={true}
                    placeholder="Α.Φ.Μ"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Δ.Ο.Υ."
                    value={vatOffice}
                    onChangeText={(e) => setVatOffice(e)}
                    multiline={true}
                    placeholder="Δ.Ο.Υ."
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Α.Μ.Κ.Α"
                    value={socialSecurity}
                    onChangeText={(e) => setSocialSecurity(e)}
                    multiline={true}
                    placeholder="Α.Μ.Κ.Α"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Κινητό Τηλ"
                    value={mobile}
                    onChangeText={(e) => setMobile(e)}
                    multiline={true}
                    placeholder="Κινητό Τηλ"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Σταθερό Τηλ."
                    value={tel}
                    onChangeText={(e) => setTel(e)}
                    multiline={true}
                    placeholder="Σταθερό Τηλ."
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Fax"
                    value={fax}
                    onChangeText={(e) => setFax(e)}
                    multiline={true}
                    placeholder="Fax"
                  />
                </View>
              </Col>
              <Col lg={6} md={12}>
                <View style={{ width: 300 }}>
                  <InputText
                    viewMode={isView}
                    displayTitle="Email"
                    value={email}
                    onChangeText={(e) => setEmail(e)}
                    multiline={true}
                    placeholder="Email"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="web"
                    value={web}
                    onChangeText={(e) => setWeb(e)}
                    multiline={true}
                    placeholder="web"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Διεύθυνση"
                    value={address}
                    onChangeText={(e) => setAddress(e)}
                    multiline={true}
                    placeholder="Διεύθυνση"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Πόλη"
                    value={city}
                    onChangeText={(e) => setCity(e)}
                    multiline={true}
                    placeholder="Πόλη"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Ταχ. Κωδ."
                    value={postalCode}
                    onChangeText={(e) => setPostalCode(e)}
                    multiline={true}
                    placeholder="Ταχ. Κωδ."
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Χώρα"
                    value={country}
                    onChangeText={(e) => setCountry(e)}
                    multiline={true}
                    placeholder="Χώρα"
                  />
                  <InputText
                    viewMode={isView}
                    displayTitle="Σημειώσεις"
                    value={notes}
                    onChangeText={(e) => setNotes(e)}
                    multiline={true}
                    placeholder="Σημειώσεις"
                  />
                </View>
              </Col>
              <View style={{ width: 250, marginBottom: 5 }}>
                {renderButtons()}
                {renderEditButton()}
              </View>
            </Row>
          </GridLayout>
        </Tab>
      </TabView>
      <View style={{ marginTop: 10 }}>
        {viewMode !== 'add' ? (
          <TabView
            style={{ maxWidth: 1320, width: '100%' }}
            renderTabBar={renderTabBar}
          >
            <Tab title="Υπηρεσίες">
              <DetailedTable
                preset="staffService"
                viewUrl={`/admin/staff/${props.match.params.id}/staffservice`}
                addUrl={`/admin/staff/${props.match.params.id}/staffservice`}
                editUrl={`/admin/staff/${props.match.params.id}/staffservice/:id`}
                addData={{ staff_id: props.match.params.id }}
              />
            </Tab>
            <Tab title="Συμβόλαια">
              <DetailedTable
                preset="staffSiteContract"
                viewUrl={`/admin/staff/${props.match.params.id}/staffcontracts`}
                addUrl={`/admin/staff/${props.match.params.id}/staffcontracts`}
                editUrl={`/admin/staff/${props.match.params.id}/staffcontracts/:id`}
                addData={{ staff_id: props.match.params.id }}
              />
            </Tab>
          </TabView>
        ) : null}
      </View>
    </CardBlock>
  );
};

const styles = StyleSheet.create({
  buttonForm: {
    width: 280,
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 110,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },

  customButton: {
    width: 110,
    height: 35,
    marginTop: 30,
  },
});
