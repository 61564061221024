import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native-web';
import InputText from '../components/Inputs/InputText';
import { useRouteMatch } from 'react-router-dom';
import Api from '../api/Api';
import Button from '../components/Button';
import { TabView, Tab } from '../components/Tabs';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { GridLayout, Row, Col } from '../components/Grid';
import CardBlock from '../components/CardBlock';
import { TabBar } from 'react-native-tab-view';
import DetailedTable from '../components/Tables/DetailedTable';
import Messages from '../components/Messages';
import { createBrowserHistory } from 'history';
let history = createBrowserHistory();

export default function ContractsForm(props) {
  const [name, setName] = useState('');
  const [siteCategory, setSiteCategory] = useState('');
  const [duration, setDuration] = useState('');
  const [status, setStatus] = useState('');
  const [category, setCategory] = useState();
  const [notes, setNotes] = useState();
  const [description, setDescription] = useState();
  const [service, setService] = useState();
  const [period, setPeriod] = useState();
  const [period_step, setPeriodStep] = useState();
  const [price, setPrice] = useState();
  const [viewMode, setViewMode] = useState('view');
  const [errorMessage, setErrorMessage] = useState('');

  let viewPath = useRouteMatch({
    path: '/contracts/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/contracts/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/contracts/add',
    exact: true,
  });

  const validate = () => {
    if (!siteCategory || !name || !status || !category) {
      setErrorMessage('Required fields');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(
                `/contracts/${props.match.params.id}/edit`
              )
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  async function addContracts() {
    if (validate()) {
      let res = await Api.post('/admin/contracts', {
        site_category_id: siteCategory,
        name: name,
        duration: duration,
        contract_category: category,
        status: status,
        notes: notes,
        description: description,
      }).then((res) => {
        history.goBack();
      });
      console.log(res);
    }
  }

  async function editContracts() {
    await Api.put('/admin/contracts/' + props.match.params.id, {
      site_category_id: siteCategory,
      name: name,
      duration: duration,
      contract_category: category,
      status: status,
      service: service,
      period: period,
      period_step: period_step,
      price: price,
      notes: notes,
      description: description,
    }).then((res) => {
      history.goBack();
    });
  }

  useEffect(() => {
    const fetchContracts = async () => {
      const res = await Api.get('/admin/contracts/' + props.match.params.id);
      let data = res.data.data;
      console.log(data);
      setSiteCategory(data.site_category_id);
      setName(data.name);
      setDescription(data.description);
      setService(data.service);
      setNotes(data.notes);
      setStatus(data.status);
      setPrice(data.price);
      setPeriod(data.period);
      setPeriodStep(data.period_step);
      setDuration(data.duration);
      setCategory(data.contract_category);
    };
    if (props.match.params.id) {
      fetchContracts();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setSiteCategory('');
    setName('');
    setDescription('');
    setService('');
    setNotes('');
    setStatus('');
    setPrice('');
    setPeriod('');
    setPeriodStep('');
    setDuration('');
    setCategory('');
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addContracts()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editContracts()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      scrollEnabled={true}
      // tabStyle={{width: 'auto'}}
      style={{ backgroundColor: '#ee4023' }}
      getLabelText={({ route }) => route.title}
    />
  );
  return (
    <View style={styles.container}>
      <CardBlock padding={20}>
        <GridLayout>
          <Row>
            <Col lg={4} md={12}>
              <View style={{ width: 300 }}>
                <InputText
                  viewMode={isView}
                  displayTitle="Ονομα"
                  style={[styles.inputText, styles.margins]}
                  value={name}
                  onChangeText={(e) => setName(e)}
                  multiline={true}
                  placeholder="Add Name"
                  isRequired={true}
                />

                <InputText
                  viewMode={isView}
                  displayTitle="Διαρκεια"
                  style={[styles.inputText, styles.margins]}
                  value={duration}
                  onChangeText={(e) => setDuration(e)}
                  placeholder="Add duration"
                  isRequired={true}
                />

                <InputText
                  viewMode={isView}
                  displayTitle="Σημειώσεις"
                  style={[styles.inputText, styles.margins]}
                  value={notes}
                  onChangeText={(e) => setNotes(e)}
                  multiline={true}
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Περιγραφή"
                  style={[styles.inputText, styles.margins]}
                  value={description}
                  onChangeText={(e) => setDescription(e)}
                  multiline={true}
                />
              </View>
            </Col>

            <Col lg={4} md={12}>
              <PickerChoiceSelection
                items={[
                  { value: 0, label: 'Please Select' },
                  { value: 1, label: 'Φ/Β ΠΑΡΚΟ' },
                  { value: 2, label: 'Φ/Β ΣΤΕΓΗ' },
                  { value: 3, label: 'Φ/Β ΑΓΡΟΤΙΚΟ' },
                  { value: 4, label: 'Φ/Β ΑΥΤΟΝΟΜΟ' },
                  { value: 5, label: 'ΚΤΗΡΙΟ' },
                ]}
                selectedValue={siteCategory}
                onValueChange={(item) => setSiteCategory(item)}
                displayTitle="Κατηγορια Εργου"
                viewMode={isView}
                value={siteCategory}
                isRequired={true}
                textStyle={{ marginTop: 6 }}
              />

              <PickerChoiceSelection
                items={[
                  { value: 0, label: 'Please Select' },
                  { value: 2, label: 'Disabled' },
                  { value: 1, label: 'Enabled' },
                ]}
                selectedValue={status}
                onValueChange={(item) => setStatus(item)}
                displayTitle="Κατάσταση"
                viewMode={isView}
                value={status}
                isRequired={true}
                textStyle={{ marginTop: 9 }}
              />

              <PickerChoiceSelection
                items={[
                  { value: 0, label: 'Please Select', key: 0 },
                  { value: 1, label: 'Συμβόλαιο Πελάτη', key: 1 },
                  { value: 2, label: 'Συμβόλαιο Συνεργάτη', key: 2 },
                ]}
                selectedValue={category}
                onValueChange={(item) => setCategory(item)}
                displayTitle="Κατηγορια"
                viewMode={isView}
                value={category}
                isRequired={true}
                textStyle={{ marginTop: 9 }}
              />
            </Col>
          </Row>
        </GridLayout>
        {renderButtons()}
        {renderEditButton()}
        <TabView
          style={{ maxWidth: 1320, width: '100', marginTop: 10 }}
          renderTabBar={renderTabBar}
        >
          <Tab title="Υπηρεσίες Συμβολαίων">
            <DetailedTable
              viewUrl={`/admin/contracts/${props.match.params.id}/services`}
              editUrl={`/admin/contracts/${props.match.params.id}/services/:id`}
              addUrl={`/admin/contracts/${props.match.params.id}/services`}
              preset="contractServices"
            />
          </Tab>
        </TabView>
      </CardBlock>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonForm: {
    width: 300,
    flexDirection: 'row',
    marginRight: 20,
  },
  button: {
    width: 120,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },
});
