import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native-web';
import InputLookUp from '../components/Inputs/InputLookUp';
import InputText from '../components/Inputs/InputText';
import { useRouteMatch } from 'react-router-dom';
import Api from '../api/Api';
import Button from '../components/Button';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { GridLayout, Row, Col } from '../components/Grid';
import { createBrowserHistory } from 'history';
import CardBlock from '../components/CardBlock';
import { TabView, Tab } from 'react-native-tab-view-easy';
import { TabBar } from 'react-native-tab-view';
import DetailedTable from '../components/Tables/DetailedTable';
import Messages from '../components/Messages';

export default function AddCompanyForm(props) {
  const [client, setClient] = useState({ client: '', id: null });
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [post_code, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [vat, setVat] = useState();
  const [tax_authority, setTaxAuthority] = useState();
  const [status, setStatus] = useState();
  const [stat_area, setStatArea] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [viewMode, setViewMode] = useState('view');

  let history = createBrowserHistory();

  let viewPath = useRouteMatch({
    path: '/companies/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/companies/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/companies/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  const validate = () => {
    if (!client || !name || !status || !stat_area) {
      setErrorMessage(`Required fields`);
      return false;
    }
    setErrorMessage('');
    return true;
  };

  async function addCompany() {
    if (validate()) {
      let res = await Api.post('/admin/companies', {
        client_id: client.id,
        name: name,
        address: address,
        city: city,
        post_code: post_code,
        country: country,
        vat: vat,
        tax_authority: tax_authority,
        status: status,
        stat_area: stat_area,
      }).then((response) => {
        history.goBack();
      });
    }
  }

  async function editCompany() {
    await Api.put('/admin/companies/' + props.match.params.id, {
      client_id: client.id,
      name: name,
      address: address,
      city: city,
      post_code: post_code,
      country: country,
      vat: vat,
      tax_authority: tax_authority,
      status: status,
      stat_area: stat_area,
    }).then((res) => {
      history.goBack();
    });
  }

  useEffect(() => {
    const fetchCompany = async () => {
      const res = await Api.get('/admin/companies/' + props.match.params.id);
      let data = res.data.data;
      console.log(data);
      setClient({ client: data.client, id: data.client_id });
      setName(data.name);
      setAddress(data.address);
      setCity(data.city);
      setPostalCode(data.post_code);
      setStatus(data.status);
      setStatArea(data.stat_area);
      setVat(data.vat);
      setTaxAuthority(data.tax_authority);
      setCountry(data.country);
    };
    if (props.match.params.id) {
      fetchCompany();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setClient('');
    setName('');
    setAddress('');
    setCity('');
    setPostalCode('');
    setCountry('');
    setVat('');
    setTaxAuthority('');
    setStatus('');
    setStatArea('');
  }

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(
                `/companies/${props.match.params.id}/edit`
              )
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addCompany()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editCompany()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      scrollEnabled={true}
      // tabStyle={{width: 'auto'}}
      style={{ backgroundColor: '#ee4023' }}
      getLabelText={({ route }) => route.title}
    />
  );

  return (
    <View style={styles.container}>
      <CardBlock>
        <GridLayout>
          <Row>
            <Col lg={6} md={12}>
              <View style={{ marginLeft: 15 }}>
                <InputLookUp
                  viewMode={isView}
                  displayTitle="Πελατης"
                  value={client}
                  displayKey="client"
                  endpoint="/admin/client"
                  searchQueryKey="search"
                  onSelect={(item) => setClient(item)}
                  isRequired={true}
                  titleStyle={{ marginLeft: 12 }}
                />
              </View>
              <View style={{ width: 200, marginLeft: 15 }}>
                <InputText
                  viewMode={isView}
                  displayTitle="Εταιρία"
                  value={name}
                  onChangeText={(e) => setName(e)}
                  multiline={true}
                  placeholder="Add company"
                  isRequired={true}
                />

                <InputText
                  viewMode={isView}
                  displayTitle="Εφορία"
                  value={tax_authority}
                  onChangeText={(e) => setTaxAuthority(e)}
                  multiline={true}
                  placeholder="Add tax authority"
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Στατ. Περιοχή"
                  value={stat_area}
                  onChangeText={(e) => setStatArea(e)}
                  multiline={true}
                  placeholder="Add tax authority"
                  isRequired={true}
                />
              </View>

              <PickerChoiceSelection
                items={[
                  { value: 0, label: 'Disabled', key: 0 },
                  { value: 1, label: 'Enabled', key: 1 },
                ]}
                selectedValue={status}
                onValueChange={(item) => setStatus(item)}
                displayTitle="Κατάσταση"
                viewMode={isView}
                value={status}
                isRequired={true}
                textStyle={{ marginLeft: 26, marginTop: 10 }}
                textStyleValue={{ marginLeft: 26 }}
                pickerStyle={{ marginLeft: 17 }}
              />
            </Col>
            <Col lg={6} md={12}>
              <View style={{ width: 200 }}>
                <InputText
                  viewMode={isView}
                  displayTitle="Πόλη"
                  value={city}
                  onChangeText={(e) => setCity(e)}
                  multiline={true}
                  placeholder="Add city"
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Ταχ.Κωδ."
                  value={post_code}
                  onChangeText={(e) => setPostalCode(e)}
                  multiline={true}
                  placeholder="Add postal_code"
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Χώρα"
                  value={country}
                  onChangeText={(e) => setCountry(e)}
                  multiline={true}
                  placeholder="Add country"
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Α.Φ.Μ"
                  value={vat}
                  onChangeText={(e) => setVat(e)}
                  multiline={true}
                  placeholder="Add vat"
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Διευθυνση"
                  value={address}
                  onChangeText={(e) => setAddress(e)}
                  multiline={true}
                  placeholder="Add address"
                />
              </View>
            </Col>
          </Row>
        </GridLayout>
        <View style={{ width: 250 }}>{renderButtons()}</View>
        <View style={{ marginBottom: 20, marginLeft: 15 }}>
          {renderEditButton()}
        </View>
      </CardBlock>
      {viewMode !== 'add' ? (
        <View style={{ marginTop: 50 }}>
          <CardBlock>
            <TabView
              style={{ maxWidth: 1320, width: '100%', marginTop: 5 }}
              renderTabBar={renderTabBar}
            >
              <Tab title="Επαφές">
                <DetailedTable
                  preset="companyContact"
                  viewUrl={`/admin/companies/${props.match.params.id}/contacts`}
                  addUrl={`/admin/companies/${props.match.params.id}/contacts`}
                  editUrl={`/admin/companies/${props.match.params.id}/contacts/:id`}
                  addData={{ company_id: props.match.params.id }}
                />
              </Tab>
              <Tab title="Εγκαταστάσεις">
                <DetailedTable
                  preset="sites"
                  viewUrl={`/admin/sites?company_id=${props.match.params.id}`}
                  addData={{ company_id: props.match.params.id }}
                />
              </Tab>
            </TabView>
          </CardBlock>
        </View>
      ) : (
        ''
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  buttonForm: {
    width: 280,
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 110,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },
});
