import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  Image,
} from 'react-native-web';
import Button from './Button';
import Config from '../config/Config';
import { connect } from 'react-redux';
import * as notesActions from '../redux/actions/notes';
import InputText from './Inputs/InputText';

const colors = [
  Config.COLORS.primary.background,
  Config.COLORS.secondary.background,
  Config.COLORS.third.background,
  '#ffffff',
];

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formDisplayNotes: true,
      formDisplayAddNotes: true,
      textNote: '',
      selectedColor: '',
      visibleModal: false,
      editNote: {
        id: null,
        color_hex: '',
        note: '',
      },
    };
  }

  componentDidMount() {
    this.props.fetchNotes(0);
  }

  Toggle() {
    this.setState((currentState) => ({
      formDisplayNotes: !currentState.formDisplayNotes,
    }));
  }

  toggleAddNotes() {
    this.setState((currentState) => ({
      formDisplayAddNotes: !currentState.formDisplayAddNotes,
    }));
  }

  handleText = (text) => {
    this.setState({
      textNote: text,
    });
  };

  loadMoreNotes = () => {
    this.props.fetchNotes();
  };

  renderForm = () => {
    return (
      <View style={styles.renderFormView}>
        <FlatList
          keyExtractor={(item) => item.id}
          data={this.props.notes}
          renderItem={({ item }) => this.renderNotes(item)}
          extraData={this.state.editNote}
        />
      </View>
    );
  };

  onEditNote = (text) => {
    this.setState({
      editNote: {
        note: text,
      },
    });
  };

  addAndToggle = () => {
    this.toggleAddNotes();
    this.props.addNotes({
      note: this.state.textNote,
      color_hex: this.state.selectedColor,
    });
  };

  renderAddForm = () => {
    return (
      <View>
        <TextInput
          onChangeText={this.handleText}
          value={this.state.textNote}
          multiline={true}
          style={styles.noteInputBox}
          placeholder="Add note"
        />
        {this.renderColorChoice()}
        <Button
          onPress={() => this.addAndToggle()}
          style={styles.addButton}
          title="add note"
        />
      </View>
    );
  };

  renderNotes = (item) => {
    return (
      <View style={{ backgroundColor: 'white', paddingVertical: 20 }}>
        <View style={[styles.notes, { backgroundColor: item.color_hex }]}>
          {this.state.editNote?.id === item.note_id ? (
            <>
              <TextInput
                style={{
                  borderColor: 'black',
                  borderWidth: 1,
                }}
                value={item.note}
                onChangeText={(text) => this.onEditNote(text)}
              />
              <Button
                title="save"
                onPress={() => this.props.editNotes(this.state.editNote)}
              />
            </>
          ) : (
            <Text>{item.note}</Text>
          )}

          {this.renderDeleteAndEditButton(item)}
        </View>
      </View>
    );
  };

  renderDeleteAndEditButton = (item) => {
    return (
      <View style={styles.renderDeleteAndEditButtonStyle}>
        <View style={styles.buttonBorders}>
          <View style={{ marginHorizontal: 5 }}>
            <TouchableOpacity
              onPress={() => {
                console.log('hello');
                this.setState({
                  editNote: {
                    id: item.note_id,
                    color_hex: item.color_hex,
                    note: item.note,
                  },
                });
              }}
            >
              <Text style={styles.buttonText}>E</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.buttonText}>|</Text>
          <View style={{ marginHorizontal: 5 }}>
            <TouchableOpacity
              onPress={() => this.props.deleteNotes(item.note_id)}
              style={styles.deleteButton}
            >
              <Text style={styles.buttonText}>D</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  renderColorChoice = () => {
    return (
      <View>
        <View style={styles.renderColorView}>
          <Text style={{ marginVertical: 13 }}>Colors</Text>
          {colors.map((item, i) => {
            let opacity = item === this.state.selectedColor ? 4 : 0;
            return (
              <TouchableOpacity
                onPress={() => this.setState({ selectedColor: item })}
                style={[
                  styles.box,
                  { backgroundColor: colors[i], borderWidth: opacity },
                ]}
              />
            );
          })}
        </View>
      </View>
    );
  };

  render() {
    return (
      <View style={{ marginTop: 60, right: 40 }}>
        <View
          style={[
            styles.addNote,
            {
              borderRadius: this.state.formDisplayNotes ? 25 : 0,
              borderBottomWidth: this.state.formDisplayNotes ? 1 : 0,
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Image
              style={{ width: 50, height: 30, left: 10 }}
              source={require('../assets/Icons/Notes.png')}
            />
            <TouchableOpacity onPress={() => this.Toggle()}>
              <Text style={[styles.text]}>NOTES</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            borderWidth: this.state.formDisplayNotes ? 0 : 1,
            borderColor: Config.COLORS.primary.background,
            borderTopWidth: this.state.formDisplayNotes ? 0 : 0,
          }}
        >
          {!this.state.formDisplayNotes && this.renderForm()}
        </View>
        <View
          style={[
            styles.addNewNote,
            {
              borderRadius: this.state.formDisplayAddNotes ? 25 : 0,
              backgroundColor: this.state.formDisplayAddNotes ? 'red' : 'white',
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              marginRight: 15,
            }}
          >
            <Image
              style={{ width: 50, height: 30, left: 10, marginLeft: 25 }}
              source={require('../assets/Icons/AddNotes.png')}
            />
            <TouchableOpacity onPress={() => this.toggleAddNotes()}>
              <Text
                style={
                  this.state.formDisplayAddNotes ? styles.text1 : styles.text
                }
              >
                NEW NOTE
              </Text>
            </TouchableOpacity>
          </View>
          {!this.state.formDisplayAddNotes && this.renderAddForm()}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.notesReducer });

export default connect(mapStateToProps, notesActions)(Notes);

const styles = StyleSheet.create({
  addNote: {
    borderWidth: 1,
    borderColor: Config.COLORS.primary.background,
    backgroundColor: Config.COLORS.primary.text,
    cursor: 'pointer',
    width: 200,
  },

  addNewNote: {
    borderWidth: 1,
    borderColor: Config.COLORS.primary.background,
    cursor: 'pointer',
    borderRadius: 25,
    marginTop: 15,
    width: 200,
  },

  text: {
    color: Config.COLORS.primary.background,
    fontWeight: 'bold',
    marginTop: 5,
    marginRight: 30,
  },

  text1: {
    color: Config.COLORS.primary.text,
    fontWeight: 'bold',
    marginTop: 5,
    marginRight: 40,
  },

  notes: {
    width: '100%',
    height: 150,
    textAlign: 'center',
    paddingTop: 25,
    marginTop: 10,
    justifyContent: 'center',
  },

  renderFormView: {},

  renderColorView: { flex: 1, flexDirection: 'row', alignSelf: 'center' },

  buttonBorders: {
    borderWidth: 1,
    flexDirection: 'row',
    borderRadius: 25,
    borderColor: 'white',
    color: Config.COLORS.primary.text,
  },

  buttonText: {
    color: Config.COLORS.primary.text,
    fontWeight: 'bold',
    fontSize: 12,
  },

  box: {
    height: 30,
    width: 30,
    margin: 5,
  },

  renderDeleteAndEditButtonStyle: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: 15,
  },

  noteInputBox: {
    borderWidth: 1,
    height: 100,
  },
  addButton: {
    borderWidth: 1,
    marginHorizontal: 10,
    marginVertical: 20,
    borderRadius: 15,
    borderColor: Config.COLORS.primary.background,
  },
});
