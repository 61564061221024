import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import Button from '../components/Button';
import Config from '../config/Config';
import Api from '../api/Api';

class RequestPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  requestReset = async () => {
    await Api.post('account/request-reset', {
      email: this.state.email,
    }).then((res) => {
      console.log(res);
    });
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.layout}>
          <Text style={styles.text}>MyPVSoft Reset Password</Text>
          <Text>Please enter your email</Text>
          <TextInput
            style={styles.input}
            placeholder="email"
            onChangeText={(text) => {
              this.setState({ email: text });
            }}
          />

          <Button
            style={styles.button}
            onPress={() => this.requestReset()}
            title="Επιβεβαιωση"
          />
        </View>
      </View>
    );
  }
}

export default RequestPassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: Config.COLORS.primary.background,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  input: {
    placeholderTextColor: 'black',
    backgroundColor: 'white',
    borderRadius: '10px',
    margin: '10px',
    padding: '20px',
    borderWidth: 2,
    borderColor: Config.COLORS.primary.background,
  },
  layout: {
    padding: 50,
    backgroundColor: '#eeeeee',
  },
  button: {
    marginLeft: 5,
    width: 270,
  },

  text: {
    textAlign: 'center',
    paddingBottom: 20,
    fontSize: 20,
    color: Config.COLORS.primary.background,
    marginReft: 15,
  },
});
