import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native-web';
import Button from '../components/Button';
import Api from '../api/Api';
import InputLookUp from '../components/Inputs/InputLookUp';
import Table from '../components/Tables/Table';
import { withRouter } from 'react-router-dom';
import CardBlock from '../components/CardBlock';
import InputText from '../components/Inputs/InputText';
import Filters from '../components/Filters';

class AdminSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      clientData: [],
      filters: {},
      displayPanel: false,
      filteredSites: [],
      searchInput: '',
    };
  }

  fetchSites = () => {
    Api.get('/admin/sites', {
      params: {
        client_id: this.state.filters.clientId,
        search: this.state.searchInput,
      },
    })
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        this.setState({ data: data, filteredSites: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.fetchSites();
  }

  clientSelected = (item) => {
    this.setState(
      { filters: { ...this.state.filters, clientId: item.id } },
      () => {
        this.fetchSites();
      }
    );
  };

  agentSelected = (item) => {
    this.setState({
      filters: { ...this.state.filters, agentName: item.agent },
    });
  };

  companySelected = (item) => {
    this.setState({
      filters: { ...this.state.filters, companyName: item.name },
    });
  };

  toggle = () => {
    this.setState({
      displayPanel: !this.state.displayPanel,
    });
  };

  deleteSite = async (id) => {
    await Api.delete('admin/sites/' + id);
    console.log('deleted item with id ' + id);
  };

  searchTimeout;
  filteredSites = (text) => {
    this.setState({
      searchInput: text,
      filteredSites: this.state.data.filter((item) => item.name.includes(text)),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchSites(), 1000);
  };

  renderSearchPanel = () => {
    return (
      <View>
        <Filters
          displayTitle="Εταιρία"
          onChangeFilters={(newFilters) => {
            this.fetchSites({ client: newFilters.client });
          }}
          fields={[
            {
              type: 'InputLookUp',
              key: 'id',
              lookupProps: {
                displayKey: 'client',
                displayTitle: 'Πελατης',
                endpoint: 'admin/client',
                searchQueryKey: 'search',
              },
            },
          ]}
        />
        {/* <InputLookUp displayTitle="Ανάθεση σε συνεργάτη" /> */}
      </View>
    );
  };

  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  render() {
    let filteredSites =
      !this.state.searchInput && this.state.filteredSites.length === 0
        ? this.state.data
        : this.state.filteredSites;

    this.state.data.map((item) => {
      return (item.buttons = (
        <>
          <View
            style={{
              width: 60,
              flexDirection: 'row',
              marginLeft: 15,
              marginBottom: 7,
            }}
          >
            <Button
              onPress={() => this.props.history.push(`sites/${item.id}/edit`)}
              title="E"
              style={{ width: 30, height: 30, borderRadius: '100%' }}
            />
            <Button
              onPress={() => this.props.history.push(`/sites/${item.id}`)}
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
              }}
            />
          </View>
        </>
      ));
    });

    this.state.data.map((item) => {
      item.deleteButton = (
        <Button onPress={() => this.deleteSite(item.id)} title="Delete" />
      );
      return item;
    });

    return (
      <View style={styles.container}>
        {console.log(this.state.filters)}
        <View>
          <View style={{ width: 300, flexDirection: 'row' }}>
            <Button
              title="add new site"
              onPress={() => this.props.history.push('/sites/add')}
            />
            <Button
              title="close panel"
              onPress={() => this.toggle()}
              style={{ marginLeft: 5 }}
            />
          </View>
          {!this.state.displayPanel && (
            <View style={{ width: 200, marginBottom: 10 }}>
              <InputText
                placeholder="search"
                onChangeText={this.filteredSites}
                value={this.state.searchInput}
              />
            </View>
          )}
        </View>
        <View style={{ marginTop: 10 }}>
          <CardBlock title="SITES">
            <Table
              data={filteredSites}
              dataMap={{
                buttons: '',
                name: 'Ονομα',
                power: 'Ισχυς',
                company_name: 'Εταιρια',
                number_paroxi: 'Αριθμος Παροχης',
                client: 'Πελατης',
              }}
            />
          </CardBlock>
        </View>
      </View>
    );
  }
}

export default withRouter(AdminSites);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  sitesContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },

  fontSize: {
    fontSize: '30px',
  },

  lookUp: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  clientInput: {
    padding: 10,
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: 'black',
  },
});
