import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import Table from '../components/Tables/Table';
import Button from '../components/Button';
import CardBlock from '../components/CardBlock';
import Api from '../api/Api';
import InputText from '../components/Inputs/InputText';

class Staff extends Component {
  state = {
    data: [],
    filteredStaff: [],
    searchInput: '',
    formDisplay: false,
  };
  fetchStaff = async () => {
    const res = await Api.get('/admin/staff', {
      params: {
        search: this.state.searchInput,
      },
    });
    let data = res.data.data;
    data.map((item, i) => {
      data[i].status = item.status === 1 ? 'Enabled' : 'Disabled';
      data[i].speciality =
        item.speciality === 1 ? 'Ηλεκτρολόγος' : 'Συνεργ.Χλοοκοπτών';
    });

    this.setState({
      data: data,
      filteredStaff: data,
    });
  };

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  searchTimeout;
  filteredStaff = (text) => {
    this.setState({
      searchInput: text,
      filteredStaff: this.state.data.filter((item) =>
        item.firstname.includes(text)
      ),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchStaff(), 1000);
  };

  componentDidMount() {
    this.fetchStaff();
  }

  render() {
    let filteredStaff =
      !this.state.searchInput && this.state.filteredStaff.length === 0
        ? this.state.data
        : this.state.filteredStaff;
    this.state.data.map((item) => {
      return (item.buttons = (
        <>
          <View
            style={{
              width: 60,
              flexDirection: 'row',
              marginLeft: 15,
              marginBottom: 7,
            }}
          >
            <Button
              onPress={() => this.props.history.push(`staff/${item.id}/edit`)}
              title="E"
              style={{ width: 30, height: 30, borderRadius: '100%' }}
            />
            <Button
              onPress={() => this.props.history.push(`/staff/${item.id}`)}
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
              }}
            />
          </View>
        </>
      ));
    });
    return (
      <View>
        <View style={{ width: 350, flexDirection: 'row' }}>
          <Button
            title="add new staff"
            onPress={() => this.props.history.push('/staff/add')}
          />
          <Button
            title="close panel"
            onPress={() => this.Toggle()}
            style={{ marginLeft: 5 }}
          />
        </View>
        {!this.state.formDisplay && (
          <View style={{ width: 200, marginBottom: 10 }}>
            <InputText
              placeholder="search"
              onChangeText={this.filteredStaff}
              value={this.state.searchInput}
            />
          </View>
        )}
        <CardBlock title="staff">
          <Table
            widthArr={[
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
              150,
            ]}
            data={filteredStaff}
            dataMap={{
              buttons: '',
              lastname: 'Επωνυμο',
              firstname: 'Ονομα',
              speciality: 'Ειδικοτητα',
              vat_no: 'Α.Φ.Μ',
              vat_office: 'Εφορία',
              social_security: 'Α.Μ.Κ.Α',
              company_name: 'Εταιρια',
              mobile: 'Κινητο',
              tel: 'Σταθερο',
              email: 'Email',
              address: 'Διευθυνση',
              country: 'Χώρα',
              notes: 'Σημειωσεις',
              status: 'Κατασταση',
            }}
          />
        </CardBlock>
      </View>
    );
  }
}

export default Staff;
