import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import InputText from '../components/Inputs/InputText';
import { useRouteMatch } from 'react-router-dom';
import Api from '../api/Api';
import Button from '../components/Button';
import CardBlock from '../components/CardBlock';

export default (props) => {
  const [siteCategory, setSiteCategory] = useState();
  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/siteCategories/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/siteCategories/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/siteCategories/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  async function addSiteCategory() {
    let res = await Api.post('/admin/sitesCategories', {
      site_category: siteCategory,
    });
    console.log(res);
  }

  async function editSiteCategory() {
    await Api.put('/admin/sitesCategories/' + props.match.params.id, {
      site_category: siteCategory,
    });
  }

  function clearState() {
    setSiteCategory('');
  }

  useEffect(() => {
    const fetchService = async () => {
      const res = await Api.get(
        '/admin/sitesCategories/' + props.match.params.id
      );
      let data = res.data.data;
      console.log(data);
      setSiteCategory(data.site_category);
    };
    if (props.match.params.id) {
      fetchService();
    }
  }, [props.match.params.id, viewMode]);

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    if (viewMode === 'add') {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => addSiteCategory()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => editSiteCategory()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    }
  }
  return (
    <View>
      <CardBlock>
        <View style={{ width: 300, alignSelf: 'center' }}>
          <InputText
            displayTitle="ΥΠΗΡΕΣΙΑ"
            viewMode={isView}
            value={siteCategory}
            onChangeText={(e) => setSiteCategory(e)}
            multiline={true}
          />
        </View>
        {renderButtons()}
      </CardBlock>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonForm: {
    width: 300,
    flexDirection: 'row',
    alignSelf: 'center',
    marginRight: 20,
  },
  button: {
    width: 120,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },
});
