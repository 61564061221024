import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import Api from '../api/Api';
import CardBlock from '../components/CardBlock';
import Table from '../components/Tables/Table';
import Button from '../components/Button';
import InputText from '../components/Inputs/InputText';

export default (props) => {
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [search, setSearch] = useState('');
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleFiltering = (text) => {
    setSearch(text);
    setFilteredServices(services.filter((item) => item.service.includes(text)));
  };

  useEffect(() => {
    const fetchServices = async () => {
      let res = await Api.get('/admin/services', {
        params: {
          search: search,
        },
      });
      let data = res.data.data;
      data.map((item, i) => {
        data[i].status = item.status === 1 ? 'Enabled' : 'Disabled';
        data[i].period =
          item.period === 1
            ? 'Ημέρα'
            : item.period === 2
            ? 'Εβδομάδα'
            : item.period === 3
            ? 'Μήνας'
            : item.period === 4
            ? 'Έτος'
            : null;
        data[i].buttons = (
          <>
            <View style={{ width: 60, flexDirection: 'row', marginLeft: 10 }}>
              <Button
                onPress={() => props.history.push(`services/${item.id}/edit`)}
                title="E"
                style={{ width: 30, height: 30, borderRadius: '100%' }}
              />
              <Button
                onPress={() => props.history.push(`/services/${item.id}`)}
                title="V"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: '100%',
                  marginLeft: 5,
                }}
              />
            </View>
          </>
        );
      });
      setServices(data);
    };
    fetchServices();
  }, [search]);

  let filterServices =
    !search && filteredServices.length === 0 ? services : filteredServices;

  return (
    <View>
      <View style={{ width: 400, flexDirection: 'row' }}>
        <Button
          title="add new service"
          onPress={() => props.history.push('/services/add')}
        />
        <Button
          title="close panel"
          onPress={() => this.handleToggle()}
          style={{ marginLeft: 5 }}
        />
      </View>
      {!toggle && (
        <View style={{ width: 250, marginBottom: 10 }}>
          <InputText
            onChangeText={handleFiltering}
            value={search}
            placeholder="search for a service"
          />
        </View>
      )}
      <CardBlock title="SERVICES">
        <Table
          data={filterServices}
          dataMap={{
            buttons: '',
            service: 'ΟΝΟΜΑ',
            period: 'ΠΕΡΙΟΔΟΣ',
            period_step: 'ΒΗΜΑ ΠΕΡΙΟΔΟΥ',
            mean_manhours: 'ΩΡΕΣ ΕΡΓΑΣΙΑΣ',
            cost: 'ΚΟΣΤΟΣ',
          }}
        />
      </CardBlock>
    </View>
  );
};
