/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import InputText from '../components/Inputs/InputText';
import InputLookUp from '../components/Inputs/InputLookUp';
import Button from '../components/Button';
import Api from '../api/Api';
import { Picker } from 'react-native';
import { View, Text, StyleSheet } from 'react-native-web';
import { createBrowserHistory } from 'history';
import { useRouteMatch } from 'react-router-dom';
import { TabView, Tab } from 'react-native-tab-view-easy';
import { TabBar } from 'react-native-tab-view';
import { Row, Col, GridLayout } from '../components/Grid';
import DetailedTable from '../components/Tables/DetailedTable';
import CardBlock from '../components/CardBlock';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import Messages from '../components/Messages';
let history = createBrowserHistory();

export default function AddSitesForm(props) {
  const [name, setName] = useState();
  const [power, setPower] = useState();
  const [category, setCategory] = useState();
  const [client, setClient] = useState({ client: '', id: null });
  const [company, setCompany] = useState({ name: '', id: null });
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [notes, setNotes] = useState();
  const [monitoringDate, setMonitoringDate] = useState();
  const [inititialProductionRecord, setInitialProducationRecord] = useState();
  const [provisionNumber, setProvisionNumber] = useState();
  const [gaugeNumber, setGaugeNumber] = useState();
  const [contract, setContract] = useState();
  const [staff, setStaff] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/sites/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/sites/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/sites/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  const validate = () => {
    if (
      !client ||
      !name ||
      !power ||
      !category ||
      !location ||
      !provisionNumber ||
      !gaugeNumber ||
      !inititialProductionRecord
    ) {
      setErrorMessage(`Required fields`);
      return false;
    }
    setErrorMessage('');
    return true;
  };

  function addSites() {
    if (validate()) {
      Api.post('/admin/sites', {
        name: name,
        power: power,
        site_category_id: category,
        client_id: client.id,
        company_id: company.id,
        description: description,
        location: location,
        lat: lat,
        lng: lng,
        notes: notes,
        number_metritis: gaugeNumber,
        number_paroxi: provisionNumber,
        initial_performance: inititialProductionRecord,
      }).then((res) => {
        history.goBack();
      });
    }
  }

  function editSite() {
    Api.put('/admin/sites/' + props.match.params.id, {
      name: name,
      power: power,
      site_category_id: category,
      client_id: client.id,
      company_id: company.id,
      description: description,
      location: location,
      lat: lat,
      lng: lng,
      notes: notes,
      number_metritis: gaugeNumber,
      number_paroxi: provisionNumber,
      initial_performance: inititialProductionRecord,
    }).then((res) => {
      history.goBack();
    });
  }

  useEffect(() => {
    const fetchSite = async () => {
      const res = await Api.get('/admin/sites/' + props.match.params.id);
      let data = res.data.data;
      console.log(data);
      setName(data.name);
      setCategory(data.site_category_id);
      setClient({ client: data.client, id: data.client.id });
      setPower(data.power);
      setCompany({ name: data.company_name, id: data.company_id });
      setLocation(data.location);
      setLat(data.lat);
      setLng(data.lng);
      setDescription(data.description);
      setGaugeNumber(data.number_metritis);
      setProvisionNumber(data.number_paroxi);
      setInitialProducationRecord(data.initial_performance);
      setNotes(data.notes);
      setStaff({ lastname: data.staff_name, id: data.staff_id });
      console.log(data);
      console.log(company);
    };
    if (props.match.params.id) {
      fetchSite();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setName('');
    setPower('');
    setCategory(0);
    setClient();
    setCompany();
    setDescription();
    setLocation();
    setLat();
    setLng();
  }

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(`/sites/${props.match.params.id}/edit`)
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addSites()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editSite()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  return (
    <View style={styles.container}>
      <CardBlock padding={20}>
        <View style={{ width: 200, marginBottom: 20 }}>
          <InputText
            viewMode={isView}
            displayTitle="Ονομα"
            value={name}
            onChangeText={(e) => setName(e)}
            multiline={true}
            placeholder="Add name"
            isRequired={true}
          />
          <InputText
            viewMode={isView}
            displayTitle="Ισχυς"
            value={power}
            onChangeText={(e) => setPower(e)}
            multiline={true}
            placeholder="Add power"
            isRequired={true}
          />
        </View>
        <TabView
          style={{ maxWidth: 1420, width: '100%' }}
          renderTabBar={renderTabBar}
        >
          <Tab title="Κύρια Στοιχεία">
            <GridLayout>
              <Row>
                <Col lg={4} md={12}>
                  <View>
                    <PickerChoiceSelection
                      items={[
                        { value: 0, label: 'Please Select' },
                        { value: 1, label: 'Φ/Β ΠΑΡΚΟ' },
                        { value: 2, label: 'Φ/Β ΣΤΕΓΗ' },
                        { value: 3, label: 'Φ/Β ΑΓΡΟΤΙΚΟ' },
                        { value: 4, label: 'Φ/Β ΑΥΤΟΝΟΜΟ' },
                        { value: 5, label: 'ΚΤΗΡΙΟ' },
                      ]}
                      selectedValue={category}
                      onValueChange={(item) => setCategory(item)}
                      displayTitle="Κατηγορια"
                      viewMode={isView}
                      value={category}
                      isRequired={true}
                      textStyle={{ marginLeft: 6 }}
                      textStyleValue={{ marginLeft: 6 }}
                    />
                    <View>
                      <InputLookUp
                        viewMode={isView}
                        displayTitle="Πελάτης"
                        displayKey="client"
                        endpoint="admin/client"
                        searchQueryKey="search"
                        onSelect={(item) => setClient(item)}
                        value={client}
                        isRequired={true}
                      />
                    </View>
                    <View>
                      <InputLookUp
                        viewMode={isView}
                        displayTitle="Εταιρία"
                        displayKey="name"
                        endpoint="admin/companies"
                        searchQueryKey="search"
                        onSelect={(item) => setCompany(item)}
                        value={company}
                        isRequired={true}
                      />
                    </View>
                    <View>
                      <InputLookUp
                        viewMode={isView}
                        displayTitle="Τεχνικος"
                        displayKey="lastname"
                        endpoint="admin/staff"
                        searchQueryKey="search"
                        onSelect={(item) => setStaff(item)}
                        value={staff}
                      />
                    </View>
                  </View>
                </Col>
                <Col lg={4} md={12}>
                  <View style={{ width: 250 }}>
                    <InputText
                      viewMode={isView}
                      displayTitle="Αρχική Καταγραφή Παραγωγής"
                      value={inititialProductionRecord}
                      placeholder="Αρχική Καταγραφή Παραγωγής"
                      onChangeText={(e) => setInitialProducationRecord(e)}
                      isRequired={true}
                    />
                    <InputText
                      viewMode={isView}
                      displayTitle="Αριθμός Παροχής"
                      value={provisionNumber}
                      placeholder="Αριθμός Παροχής"
                      onChangeText={(e) => setProvisionNumber(e)}
                      isRequired={true}
                    />
                    <InputText
                      viewMode={isView}
                      displayTitle="Αριθμός Μετρητή"
                      value={gaugeNumber}
                      placeholder="Αριθμός Μετρητή"
                      onChangeText={(e) => setGaugeNumber(e)}
                      isRequired={true}
                    />
                  </View>
                </Col>
                <Col lg={4} md={12}>
                  <View style={{ width: 250 }}>
                    <InputText
                      viewMode={isView}
                      displayTitle="Συμβόλαιο"
                      value={contract}
                      placeholder="Συμβόλαιο"
                      onChangeText={(e) => setContract(e)}
                    />
                    <InputText
                      viewMode={isView}
                      displayTitle="Σημειώσεις"
                      value={notes}
                      placeholder="Σημειώσεις"
                      onChangeText={(e) => setNotes(e)}
                    />
                    <View style={{ width: 250 }}>
                      <InputText
                        viewMode={isView}
                        displayTitle="Περιγραφή"
                        value={description}
                        placeholder="Περιγραφή"
                        onChangeText={(e) => setDescription(e)}
                      />
                    </View>
                  </View>
                </Col>
              </Row>
            </GridLayout>
          </Tab>
          <Tab title="Γεωγραφικά Δεδομένα">
            <View style={{ width: 250 }}>
              <InputText
                viewMode={isView}
                displayTitle="Τοποθεσία"
                value={location}
                placeholder="Τοποθεσία"
                onChangeText={(e) => setLocation(e)}
                isRequired={true}
              />
              <InputText
                viewMode={isView}
                displayTitle="Γεωγρ. Πλάτος"
                value={lat}
                placeholder="Γεωγρ. Πλάτος"
                onChangeText={(e) => setLat(e)}
              />
              <InputText
                viewMode={isView}
                displayTitle="Γεωγρ. Μήκος"
                value={lng}
                placeholder="Γεωγρ. Μήκος"
                onChangeText={(e) => setLng(e)}
              />
            </View>
          </Tab>
        </TabView>
        <View style={{ top: 100 }}>
          {renderButtons()} {renderEditButton()}
        </View>

        <View style={{ marginTop: 300 }}>
          {viewMode !== 'add' ? (
            <TabView
              style={{ maxWidth: 1420, width: '100%', marginTop: 5 }}
              renderTabBar={renderTabBar}
            >
              <Tab title="Προσβάσεις">
                <DetailedTable
                  preset="siteCredentials"
                  viewUrl={`/admin/sites/${props.match.params.id}/credentials`}
                  editUrl={`/admin/sites/${props.match.params.id}/credentials/:id`}
                  addUrl={`/admin/sites/${props.match.params.id}/credentials`}
                  addData={{ site_id: props.match.params.id }}
                />
              </Tab>
              <Tab title="Χαρακτηριστικά">
                <DetailedTable
                  preset="siteAttributes"
                  viewUrl={`/admin/sites/${props.match.params.id}/attributes`}
                  editUrl={`/admin/sites/${props.match.params.id}/attributes/:id`}
                  addUrl={`/admin/sites/${props.match.params.id}/attributes`}
                  addData={{ site_id: props.match.params.id }}
                />
              </Tab>
              <Tab title="Εξοπλισμος">
                <DetailedTable
                  preset="siteMaterials"
                  viewUrl={`/admin/sites/${props.match.params.id}/materials`}
                  addUrl={`/admin/sites/${props.match.params.id}/materials`}
                  editUrl={`/admin/sites/${props.match.params.id}/materials/:id`}
                  addData={{ site_id: props.match.params.id }}
                />
              </Tab>
              <Tab title="Αποδοση">
                <DetailedTable
                  preset="sitePerformance"
                  viewUrl={`/admin/sites/${props.match.params.id}/performance`}
                  editUrl={`/admin/sites/${props.match.params.id}/performance/:id`}
                  addUrl={`/admin/sites/${props.match.params.id}/performance`}
                  addData={{ site_id: props.match.params.id }}
                />
              </Tab>
              <Tab title="Συμβολαια">
                <DetailedTable
                  preset="siteContract"
                  editUrl={`/admin/sites/${props.match.params.id}/sitecontract/:id`}
                  viewUrl={`/admin/sites/${props.match.params.id}/sitecontract`}
                  addUrl={`/admin/sites/${props.match.params.id}/sitecontract`}
                  addData={{ site_id: props.match.params.id }}
                />
              </Tab>
              <Tab title="Υπηρεσιες">
                <DetailedTable
                  preset="siteServices"
                  editUrl={`/admin/sites/${props.match.params.id}/services/:id`}
                  viewUrl={`/admin/sites/${props.match.params.id}/services`}
                  addUrl={`/admin/sites/${props.match.params.id}/services`}
                  addData={{ site_id: props.match.params.id }}
                />
              </Tab>
            </TabView>
          ) : null}
        </View>
      </CardBlock>
    </View>
  );
}

const renderTabBar = (props) => (
  <TabBar
    {...props}
    scrollEnabled={true}
    // tabStyle={{width: 'auto'}}
    style={{ backgroundColor: 'red' }}
    getLabelText={({ route }) => route.title}
  />
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  picker: {
    height: 35,
    width: 150,
    borderRadius: 25,
    backgroundColor: '#ee4023',
    borderWidth: 1,
    textAlign: 'center',
    marginLeft: 30,
    margin: 15,
  },

  buttonForm: {
    width: 300,
    flexDirection: 'row',
    alignItems: 'center',
  },
  pickerView: { flex: 1, flexDirection: 'row', marginLeft: 5 },

  categoryText: { fontSize: 14, fontWeight: 'bold' },
});
