import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import Button from '../Button';
import InputText from '../Inputs/InputText';
import { Col, GridLayout, Row } from '../Grid';

class CompanyDetails extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      name,
      companyCity,
      companyAddress,
      country,
      postCode,
      taxAuthority,
      vat,
      statArea,
      handleChange,
    } = this.props;
    return (
      <>
        <Text>Enter your Company information:</Text>
        <GridLayout>
          <Row>
            <Col lg={6}>
              <TextInput
                type="text"
                name="name"
                value={name}
                onChange={handleChange('name')}
                placeholder="name *"
                style={styles.input}
              />

              <TextInput
                type="text"
                companyCity="companyCity"
                value={companyCity}
                onChange={handleChange('companyCity')}
                placeholder="city"
                style={styles.input}
              />

              <TextInput
                type="text"
                companyAddress="companyAddress"
                value={companyAddress}
                onChange={handleChange('companyAddress')}
                placeholder="address"
                style={styles.input}
              />
              <TextInput
                type="text"
                name="country"
                value={country}
                onChange={handleChange('country')}
                placeholder="country"
                style={styles.input}
              />
            </Col>
            <Col lg={6}>
              <TextInput
                type="text"
                name="postCode"
                value={postCode}
                onChange={handleChange('postCode')}
                placeholder="post Code"
                style={styles.input}
              />

              <TextInput
                type="text"
                name="taxAuthority"
                value={taxAuthority}
                onChange={handleChange('taxAuthority')}
                placeholder="tax Authority"
                style={styles.input}
              />
              <TextInput
                type="text"
                name="vat"
                value={vat}
                onChange={handleChange('vat')}
                placeholder="vat"
                style={styles.input}
              />

              <TextInput
                type="text"
                statArea="statArea"
                value={statArea}
                onChange={handleChange('statArea')}
                placeholder="stat Area"
                keyboardType="numeric"
                style={styles.input}
              />
            </Col>
          </Row>
        </GridLayout>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            width: 300,
          }}
        >
          <Button title="« Back" className="Back" onPress={this.back} />
          <Button
            style={{ marginHorizontal: 5 }}
            title=" Next »"
            className="Next"
            onPress={this.continue}
          />
        </View>
      </>
    );
  }
}

export default CompanyDetails;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: '#ee4023',
    padding: 20,
    borderRadius: 15,
    width: 250,
    fontSize: 18,
    marginVertical: 20,
  },
});
