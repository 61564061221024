import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import CardBlock from '../components/CardBlock';
import Select from 'react-select';
import Api from '../api/Api';

import Chart from 'react-google-charts';

class Statistics extends Component {
  state = {
    clients: [],
    sites: [],
    filteredSites: [],
    statistics: [],
    filteredStatistics: [],
    selectedClient: null,
    selectedSite: null,
    closed: 0,
    canceled: 0,
    pending: 0,
    highPriority: 0,
    allFaults: 0,
    lowPriority: 0,
    lowSeverity: 0,
    highSeverity: 0,
    majorSeverity: 0,
  };
  handleSelectedClient = (selectedClient) => {
    this.setState({ selectedClient });
    if (selectedClient) {
      let clientIds = selectedClient.map((item) => item.value);
      this.setState(
        {
          filteredSites: clientIds?.length
            ? this.state.sites.filter((item) =>
                clientIds.includes(item.client_id)
              )
            : this.state.sites,
        },
        () => {
          this.fetchStatistics();
        }
      );
    }
  };
  handleSelectedSite = (selectedSite) => {
    this.setState({ selectedSite }, () => {
      this.fetchStatistics();
    });
  };

  fetchClients = () => {
    Api.get('/admin/client')
      .then((res) => {
        let data = res.data.data.map((element) => ({
          label: element.client,
          value: element.id,
        }));
        this.setState({ clients: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchSites = () => {
    Api.get('/admin/sites', {
      params: {
        client_id: this.state.selectedClient
          ?.map((item) => item.value)
          ?.join(','),
      },
    })
      .then((res) => {
        let data = res.data.data.map((element) => ({
          label: element.name,
          value: element.id,
          client_id: element.client_id,
        }));
        this.setState({ sites: data, filteredSites: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchStatistics = () => {
    Api.get('/admin/statistics', {
      params: {
        sites: this.state.selectedSite?.map((item) => item.value)?.join(','),
        clients: this.state.selectedClient
          ?.map((item) => item.value)
          ?.join(','),
      },
    })
      .then((res) => {
        let data = res.data.data;
        let closed = res.data.data.closed;
        let canceled = res.data.data.canceled;
        let highPriority = res.data.data.highPriority;
        let allFaults = res.data.data.allFaults;
        let pending = res.data.data.pending;
        let lowPriority = res.data.data.lowPriority;
        console.log(data);
        this.setState({
          closed: closed,
          canceled: canceled,
          highPriority: highPriority,
          allFaults: allFaults,
          pending: pending,
          lowPriority: lowPriority,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchSeverity = () => {
    Api.get('/admin/statistics/severity', {
      params: {
        sites: this.state.selectedSite?.map((item) => item.value)?.join(','),
        clients: this.state.selectedClient
          ?.map((item) => item.value)
          ?.join(','),
      },
    })
      .then((res) => {
        let data = res.data.data;
        let lowSeverity = data.lowSeverity;
        let highSeverity = data.highSeverity;
        let majorSeverity = data.majorSeverity;
        this.setState({
          lowSeverity: lowSeverity,
          highSeverity: highSeverity,
          majorSeverity: majorSeverity,
        });
        console.log(this.state.severities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.fetchClients();
    this.fetchSites();
    this.fetchStatistics();
    this.fetchSeverity();
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, flexDirection: 'row', bottom: 50 }}>
          <View style={{ width: 300 }}>
            <Select
              value={this.state.selectedClient}
              onChange={this.handleSelectedClient}
              options={this.state.clients}
              isMulti={true}
            />
          </View>
          <View style={{ width: 300, marginLeft: 10 }}>
            <Select
              value={this.state.selectedSite}
              onChange={this.handleSelectedSite}
              options={this.state.filteredSites}
              isMulti={true}
            />
          </View>
        </View>
        <View style={{ flex: 1, flexDirection: 'row', bottom: 250 }}>
          <CardBlock
            backgroundColor={{ backgroundColor: '#ffffff' }}
            title="WORK SEVERITY"
          >
            <Chart
              width={700}
              height={600}
              chartType="PieChart"
              loader={<View>Loading</View>}
              data={[
                ['Task', 'Hours per Day'],
                ['Low Severity', this.state.lowSeverity],
                ['Major Severity', this.state.majorSeverity],
                ['High Severity', this.state.highSeverity],
              ]}
            />
          </CardBlock>
          <CardBlock title="Work Status">
            <Chart
              width={700}
              height={600}
              chartType="PieChart"
              loader={<View>Loading</View>}
              data={[
                ['Task', 'Hours per Day'],
                ['Σε εξέλιξη', this.state.pending],
                ['Εκλεισε', this.state.closed],
                ['Ακυρώθηκε', this.state.canceled],
              ]}
            />
          </CardBlock>
        </View>
        <View>
          <CardBlock
            backgroundColor={{ backgroundColor: '#ffffff' }}
            title="lorem ipsum"
          >
            <View style={styles.faults}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>
                  {this.state.closed < 10
                    ? (this.state.closed = this.state.closed)
                    : this.state.closed}
                </Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Closed</Text>
                  <Text style={styles.innerText}>Work Orders</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>
                  {this.state.allFaults < 10
                    ? (this.state.allFaults = this.state.allFaults)
                    : this.state.allFaults}
                </Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Total</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>
                  {this.state.canceled < 10
                    ? (this.state.canceled = this.state.canceled)
                    : this.state.canceled}
                </Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Canceled</Text>
                  <Text style={styles.innerText}>Work Orders</Text>
                </View>
              </View>
            </View>
            <View style={styles.faults}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>
                  {this.state.highPriority < 10
                    ? (this.state.highPriority = this.state.highPriority)
                    : this.state.highPriority}
                </Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>High Priority</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>
                  {this.state.lowPriority < 10
                    ? (this.state.lowPriority = this.state.lowPriority)
                    : this.state.lowPriority}
                </Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Low priority</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <Text style={styles.text}>7%</Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Faults</Text>
                  <Text style={styles.innerText}> frequency</Text>
                </View>
              </View>
            </View>
          </CardBlock>
        </View>
      </View>
    );
  }
}

export default Statistics;

const styles = StyleSheet.create({
  faults: {
    flexDirection: 'row',
    marginVertical: 50,
  },
  text: {
    fontSize: 32,
    color: '#ee4023',
    fontWeight: 'bold',
  },
  innerText: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});
