import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native-web';
import InputText from '../components/Inputs/InputText';
import { useRouteMatch } from 'react-router-dom';
import Api from '../api/Api';
import Button from '../components/Button';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { GridLayout, Row, Col } from '../components/Grid';
import CardBlock from '../components/CardBlock';
import Messages from '../components/Messages';
import { createBrowserHistory } from 'history';
let history = createBrowserHistory();

export default function ServicesForm(props) {
  const [service, setService] = useState('');
  const [period, setPeriod] = useState();
  const [periodStep, setPeriodStep] = useState('');
  const [meanManHours, setMeanManHours] = useState('');
  const [cost, setCost] = useState();
  const [notes, setNotes] = useState();
  const [viewMode, setViewMode] = useState('view');
  const [errorMessage, setErrorMessage] = useState('');

  let viewPath = useRouteMatch({
    path: '/services/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/services/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/services/add',
    exact: true,
  });

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(`/services/${props.match.params.id}/edit`)
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  const validate = () => {
    if (!service || !period || !cost || !meanManHours) {
      setErrorMessage('Required fields missing');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  async function addService() {
    if (validate()) {
      let res = await Api.post('/admin/services', {
        service: service,
        period: period,
        period_step: periodStep,
        cost: cost,
        notes: notes,
        mean_manhours: meanManHours,
      }).then((res) => {
        history.goBack();
      });
      console.log(res);
    }
  }

  async function editService() {
    await Api.put('/admin/services/' + props.match.params.id, {
      service: service,
      period: period,
      period_step: periodStep,
      cost: cost,
      notes: notes,
      mean_manhours: meanManHours,
    });
  }

  useEffect(() => {
    const fetchService = async () => {
      const res = await Api.get('/admin/services/' + props.match.params.id);
      let data = res.data.data;
      console.log(data);
      setService(data.service);
      setPeriod(data.period);
      setPeriodStep(data.period_step);
      setCost(data.cost);
      setNotes(data.notes);
      setMeanManHours(data.mean_manhours);
    };
    if (props.match.params.id) {
      fetchService();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setService('');
    setNotes('');
    setPeriod('');
    setPeriodStep('');
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addService()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editService()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  return (
    <View style={[styles.container]}>
      <CardBlock padding={20}>
        <GridLayout>
          <Row>
            <Col lg={6} md={12}>
              <View style={{ width: 300 }}>
                <InputText
                  displayTitle="ΥΠΗΡΕΣΙΑ"
                  viewMode={isView}
                  value={service}
                  onChangeText={(e) => setService(e)}
                  multiline={true}
                  isRequired={true}
                />
                <InputText
                  viewMode={isView}
                  displayTitle="ΩΡΕΣ"
                  value={meanManHours}
                  onChangeText={(e) => setMeanManHours(e)}
                  isRequired={true}
                />
                <View style={{ right: 14, bottom: 20 }}>
                  <PickerChoiceSelection
                    items={[
                      { value: 0, label: 'please select' },
                      { value: 1, label: 'Ημέρα' },
                      { value: 2, label: 'Εβδομαδα' },
                      { value: 3, label: 'Μηνας' },
                      { value: 4, label: 'Ετος' },
                    ]}
                    selectedValue={period}
                    onValueChange={(item) => setPeriod(item)}
                    displayTitle="Περιοδος"
                    viewMode={isView}
                    value={period}
                    isRequired={true}
                  />
                </View>
              </View>
            </Col>
            <Col lg={6} md={12}>
              <View style={{ width: 300 }}>
                <InputText
                  displayTitle="ΒΗΜΑ ΠΕΡΙΟΔΟΥ"
                  viewMode={isView}
                  value={periodStep}
                  onChangeText={(e) => setPeriodStep(e)}
                  multiline={true}
                />
                <InputText
                  viewMode={isView}
                  displayTitle="ΚΟΣΤΟΣ"
                  value={cost}
                  onChangeText={(e) => setCost(e)}
                  isRequired={true}
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Σημειωσεις"
                  value={notes}
                  onChangeText={(e) => setNotes(e)}
                />
              </View>
            </Col>
          </Row>
        </GridLayout>
        {renderButtons()}
        {renderEditButton()}
      </CardBlock>
    </View>
  );
}
const styles = StyleSheet.create({
  buttonForm: {
    width: 300,
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 120,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },
});
