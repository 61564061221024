import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native-web';
import InputView from './InputView';

class InputForm extends PureComponent {
  onChangeText = (...e) => {
    if (this.props.onChangeText) {
      this.props.onChangeText(...e);
    }
    if (this.props.onChangeValue) {
      this.props.onChangeValue(...e);
    }
  };
  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        <Text style={styles.text}>
          {this.props.displayTitle} {this.props.isRequired ? '*' : null}
        </Text>
        {!this.props.viewMode ? (
          <TextInput
            value={this.props.value}
            style={[styles.inputText, this.props.styleInput]}
            placeholder={this.props.placeholder}
            onChangeText={this.onChangeText}
            isRequired={this.props.isRequired}
            multiline={this.props.multiline}
          />
        ) : (
          <View>
            <InputView>{this.props.value}</InputView>
          </View>
        )}
      </View>
    );
  }
}

export default InputForm;

const styles = StyleSheet.create({
  inputText: {
    borderWidth: 1,
    borderColor: '#ee4023',
    borderRadius: 15,
    flex: 1,
    textAlign: 'center',
    padding: 6,
  },

  text: {
    fontSize: 18,
    color: '#ee4023',
    marginLeft: 13,
    marginTop: 10,
    fontFamily: 'ManropeSemiBold',
  },
});
