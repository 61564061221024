import React from 'react';
import { View, Text } from 'react-native-web';
import EStyleSheet from 'react-native-extended-stylesheet';

export default function MapCardBlock(props) {
  return (
    <View style={[styles.container, props.customPadding]}>
      <Text style={styles.title}>{props.title}</Text>
      <View style={[styles.mask, props.backgroundColor]}>
        <View style={styles.children}>{props.children}</View>
      </View>
    </View>
  );
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,

    elevation: 11,
    marginHorizontal: 5,
    marginVertical: 7,
    borderTopWidth: 0,
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    backgroundColor: '#F6F6F6',
    borderColor: '#ee4023',
  },
  mask: {
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,

    elevation: 11,
    borderTopWidth: 50,
    borderColor: '#ee4023',
    flex: 1,
  },

  title: {
    flex: 1,
    position: 'absolute',
    fontSize: 22,
    color: '#ffffff',
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    marginBottom: -25,
    marginLeft: 90,
    zIndex: 1,
    top: 10,
    textTransform: 'uppercase',
  },
});
