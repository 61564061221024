import React from 'react';
import { View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Config from '../config/Config';

export default function CardBlock(props) {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View>
          <Text style={styles.title}>{props.title}</Text>
        </View>
        <View>{props.actions}</View>
      </View>
      <View
        style={[styles.body, props.padding ? { padding: props.padding } : null]}
      >
        {props.children}
      </View>
    </View>
  );
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.16,
    shadowRadius: 6.58,
    elevation: 5,
    borderRadius: 20,
  },

  header: {
    backgroundColor: Config.COLORS.primary.background,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    minHeight: 50,
    flexDirection: 'row',
    alignItems: 'center',
  },

  body: {
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: 'white',
  },

  title: {
    fontSize: 22,
    color: Config.COLORS.primary.text,
    fontFamily: 'ManropeBold',

    marginLeft: 90,
    textTransform: 'uppercase',
  },
});
