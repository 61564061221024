export default class Config {
  static BASE_URL =
    process.env.NODE_ENV === 'production' || true
      ? 'http://api.pvsoft.distemicha.online/'
      : 'https://server.domain/';
  static API_URL = Config.BASE_URL;
  static API_TOKEN = '';
  static LOGO_URL = '';
  static COLORS = {
    primary: {
      text: '#ffffff',
      background: '#ee4023',
    },
    secondary: {
      text: '#ffffff',
      background: '#ffd300',
    },
    third: {
      text: '#ffffff',
      background: '#f6921e',
    },
  };
  static NAME = '';
}
