import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import axios, { interceptors } from 'axios';
import Button from '../components/Button';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import * as accountActions from '../redux/actions/account';
import Api from '../api/Api';
import Config from '../config/Config';

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: '',
    };
  }

  login = () => {
    axios
      .post(Config.API_URL + 'account/login', {
        username: this.state.email,
        password: this.state.password,
      })
      .then(async (res) => {
        const data = res.data;
        // Save token if success save ok else error
        if (data.status === 'ok') {
          cookie.save('token', data.data.token);
          Config.API_TOKEN = data.data.token;
          await this.props.fetchAccount();
          console.log(this.props.account);
          if (this.props.account.role === 'client') {
            window.location.replace('/dashboard');
          } else if (this.props.account.role === 'admin') {
            window.location.replace('/dashboard');
          }
        } else if (data.status !== 'ok') {
          this.setState({
            errorMessage: ' Wrong Email or Password',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.layout}>
          <Text
            style={{
              textAlign: 'center',
              paddingBottom: 20,
              fontSize: 20,
              color: '#ee4023',
              marginReft: 15,
            }}
          >
            MyPVSoft Login
          </Text>
          <TextInput
            style={styles.input}
            placeholder="email"
            onChangeText={(text) => {
              this.setState({ email: text });
            }}
          />
          <TextInput
            style={styles.input}
            placeholder="password"
            secureTextEntry={true}
            onChangeText={(text) => {
              this.setState({ password: text });
            }}
          />
          <Button style={styles.button} title="Login" onPress={this.login} />
          <Button
            style={styles.button}
            title="Ξεχασα τον κωδικο"
            onPress={() => window.location.replace('/requestpassword')}
          />
          {this.state.errorMessage && (
            <View style={styles.errorMessage}>
              <Text style={styles.errorMessageText}>
                {this.state.errorMessage}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.accountReducer });
export default connect(mapStateToProps, accountActions)(LoginScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#ee4023',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  input: {
    placeholderTextColor: 'black',
    backgroundColor: 'white',
    borderRadius: '10px',
    margin: '10px',
    padding: '20px',
    borderWidth: 2,
    borderColor: '#ee4023',
  },
  layout: {
    padding: 50,
    backgroundColor: '#eeeeee',
  },
  button: {
    marginLeft: 5,
    width: 270,
  },
  errorMessage: {
    left: 10,
    marginTop: 20,
  },

  errorMessageText: {
    color: 'black',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'ManropeBold',
    lineHeight: 2,
  },
});
