import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, CheckBox } from 'react-native';
import PickerChoiceSelection from '../Inputs/PickerChoiceSelection';
import Button from '../Button';
import InputText from '../Inputs/InputText';
import { Col, GridLayout, Row } from '../Grid';
import Messages from '../Messages';

class PersonalInfo extends Component {
  constructor() {
    super();
    this.state = {
      errorMessage: '',
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const {
      firstName,
      lastName,
      address,
      city,
      country,
      username,
      handleChange,
      createUser,
      status,
    } = this.props;
    return (
      <>
        <Text>Enter your client information:</Text>
        <GridLayout>
          <Row>
            <Col xs={6} lg={6} md={3}>
              <TextInput
                type="text"
                username="username"
                value={username}
                placeholder="email *"
                onChange={handleChange('username')}
                style={styles.input}
              />
              <TextInput
                type="text"
                firstName="firstName"
                value={firstName}
                placeholder="First Name *"
                onChange={handleChange('firstName')}
                style={styles.input}
              />
              <TextInput
                type="text"
                name="lastName"
                value={lastName}
                placeholder="Last Name *"
                onChange={handleChange('lastName')}
                style={styles.input}
              />
              <PickerChoiceSelection
                items={[
                  { value: 1, label: 'Enabled' },
                  { value: 0, label: 'Disabled' },
                ]}
                selectedValue={status}
                onValueChange={(item) =>
                  handleChange('category')({ target: item })
                }
                displayTitle="ΚΑΤΑΣΤΑΣΗ"
                viewMode={false}
              />
            </Col>
            <Col lg={6}>
              <TextInput
                type="text"
                name="address"
                value={address}
                placeholder="Address"
                onChange={handleChange('address')}
                style={styles.input}
              />
              <TextInput
                type="text"
                name="city"
                value={city}
                placeholder="City"
                onChange={handleChange('city')}
                style={styles.input}
              />
              <TextInput
                type="text"
                name="country"
                value={country}
                placeholder="Country"
                onChange={handleChange('country')}
                style={styles.input}
              />

              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  marginTop: 20,
                  marginLeft: 10,
                }}
              >
                <Text style={{ fontSize: 25 }}>Create this user</Text>
                <CheckBox
                  value={createUser}
                  onValueChange={() =>
                    handleChange('createUser')({
                      target: { value: !createUser },
                    })
                  }
                  style={{ marginTop: 12, marginLeft: 10 }}
                />
              </View>
            </Col>
          </Row>
        </GridLayout>
        <View style={{ width: 220 }}>
          <Button title=" Next »" onPress={this.continue} />
        </View>
      </>
    );
  }
}

export default PersonalInfo;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: '#ee4023',
    padding: 20,
    borderRadius: 15,
    width: 250,
    fontSize: 18,
    marginVertical: 20,
  },
});
