import axios from 'axios';
import cookie from 'react-cookies';
import Config from '../config/Config';

const instance = axios.create({
  baseURL: Config.API_URL,
  headers: { Token: cookie.load('token') },
});

instance.interceptors.request.use(
  (data) => {
    data.headers.Token = Config.API_TOKEN;
    return data;
  },

  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  function (response) {
    if (
      response.data.status === 'error' &&
      response.data.error === 'login_required'
    ) {
      window.location.replace('/login');
      return null;
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace('/login');
      return null;
    }
    return Promise.reject(error);
  }
);

export default instance;
