import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Picker } from 'react-native-web';
import InputLookUp from '../components/Inputs/InputLookUp';
import { useRouteMatch } from 'react-router-dom';
import Api from '../api/Api';
import Button from '../components/Button';
import { TabView, Tab } from '../components/Tabs';
import InputForm from '../components/Inputs/InputText';
import AssignDate from '../components/AssignDate';
import moment from 'moment';
import WorkTasksForm from '../components/WorkTasksForm';
import { TabBar } from 'react-native-tab-view';
import { createBrowserHistory } from 'history';
import CardBlock from '../components/CardBlock';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { GridLayout, Col, Row } from '../components/Grid';
import DetailedTable from '../components/Tables/DetailedTable';
import Messages from '../components/Messages';

export default function WorksForm(props) {
  const [client, setClient] = useState({ client: '', id: null });
  const [company, setCompany] = useState({ name: '', id: null });
  const [site, setSite] = useState({ name: '', id: null });
  const [cause, setCause] = useState({ name: '', id: null });
  const [severity, setSeverity] = useState();
  const [status, setStatus] = useState();
  const [dateAnnounced, setDateAnnounced] = useState();
  const [dateStaffInformed, setDateStaffInformed] = useState();
  const [dateStaffAcknowledged, setDateStaffAcknowledged] = useState();
  const [dateStarted, setDateStarted] = useState();
  const [dateVisited, setDateVisited] = useState();
  const [dateResolved, setDateResolved] = useState();
  const [descriptionComment, setDescriptionComment] = useState();
  const [privateStaffComment, setPrivateStaffComment] = useState();
  const [adminComment, setAdminComment] = useState();
  const [staffComment, setStaffComment] = useState();
  const [partsComment, setPartsComment] = useState();
  const [commentToStaff, setCommentToStaff] = useState();
  const [privateAdminComment, setPrivateAdminComment] = useState();
  const [staffCommentToAdmin, setStaffCommentToAdmin] = useState();
  const [result, setResult] = useState(1);
  const [excludeFromDownTime, setExcludeFromDownTime] = useState();
  const [excludeFromReporting, setExcludeFromReporting] = useState();
  const [priority, setPriority] = useState();
  const [staff, setStaff] = useState({ firstname: '', id: null });
  const [contract, setContract] = useState({ name: '', id: null });
  const [contractService, setContractService] = useState({
    name: '',
    id: null,
  });
  const [disapproveReason, setDisapproveReason] = useState();
  const [clientComment, setClientComment] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [viewMode, setViewMode] = useState('view');

  let history = createBrowserHistory();

  let viewPath = useRouteMatch({
    path: '/works/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/works/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/works/add',
    exact: true,
  });

  const validate = () => {
    if (!client || !site || !company || !cause) {
      setErrorMessage('Required fields');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  function clearState() {
    history.goBack();
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addWork()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editWork()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }
  useEffect(() => {
    const fetchWork = async () => {
      const res = await Api.get('/admin/works/' + props.match.params.id);
      let data = res.data.data;
      setClient({ client: data.client, id: data.client_id });
      setCompany({ name: data.company_name, id: data.company_id });
      setSite({ name: data.site_name, id: data.site_id });
      setCause({ name: data.cause_name, id: data.cause_id });
      setSeverity(data.severity);
      setStatus(data.status);
      setPriority(data.priority);
      setDateAnnounced(new Date(data.date_announced));
      setDateStaffInformed(new Date(data.date_staff_informed));
      setDateStaffAcknowledged(new Date(data.date_staff_acknowledged));
      setDateStarted(new Date(data.date_started));
      setDateVisited(new Date(data.date_visited));
      setDateResolved(new Date(data.date_resolved));
      setDescriptionComment(data.description_comment);
      setAdminComment(data.admin_comment);
      setStaffComment(data.staff_comment);
      setPartsComment(data.parts_comment);
      setCommentToStaff(data.comment_to_staff);
      setPrivateAdminComment(data.private_admin_comment);
      setResult(data.result);
      setExcludeFromDownTime(data.exclude_from_down_time);
      setExcludeFromReporting(data.exclude_from_reporting);
      setStaff({ firstname: data.staff_first_name, id: data.staff_id });
      setContract({ name: data.contract_name, id: data.contract_id });
      setPrivateStaffComment(data.private_staff_comment);
      setStaffCommentToAdmin(data.staff_comment);
      setDisapproveReason(data.disapprove_reason);
      setClientComment(data.client_comment);
      setContractService({ name: data.service, id: data.service_id });
      console.log(data.service);
    };

    if (props.match.params.id > 0) {
      fetchWork();
    }
  }, [props.match.params.id, viewMode]);

  async function addWork() {
    if (validate()) {
      let res = await Api.post('/admin/works', {
        client_id: client.id,
        company_id: company.id,
        site_id: site.id,
        cause_id: cause.id,
        severity: severity,
        status: status,
        priority: priority,
        date_announced: moment(dateAnnounced).format('YYYY-MM-DDTHH:mm'),
        date_staff_informed: moment(dateStaffInformed).format(
          'YYYY-MM-DDTHH:mm'
        ),
        date_started: moment(dateStarted).format('YYYY-MM-DDTHH:mm'),
        date_visited: moment(dateVisited).format('YYYY-MM-DDTHH:mm'),
        date_resolved: moment(dateResolved).format('YYYY-MM-DDTHH:mm'),
        date_staff_acknowledged: moment(dateStaffAcknowledged).format(
          'YYYY-MM-DDTHH:mm'
        ),
        description_comment: descriptionComment,
        admin_comment: adminComment,
        staff_comment: staffComment,
        parts_comment: partsComment,
        private_staff_comment: privateStaffComment,
        private_admin_comment: privateAdminComment,
        comment_to_staff: commentToStaff,
        result: result,
        exclude_from_down_time: excludeFromDownTime,
        exclude_from_reporting: excludeFromReporting,
        staff_id: staff.id,
        disapprove_reason: disapproveReason,
        client_comment: clientComment,
      }).then((response) => {
        history.goBack();
      });
    }
  }

  async function editWork() {
    let res = await Api.put('/admin/works/' + props.match.params.id, {
      client_id: client.id,
      company_id: company.id,
      site_id: site.id,
      cause_id: cause.id,
      severity: severity,
      priority: priority,
      status: status,
      date_announced: moment(dateAnnounced).format('YYYY-MM-DDTHH:mm'),
      date_staff_informed: moment(dateStaffInformed).format('YYYY-MM-DDTHH:mm'),
      date_started: moment(dateStarted).format('YYYY-MM-DDTHH:mm'),
      date_visited: moment(dateVisited).format('YYYY-MM-DDTHH:mm'),
      date_resolved: moment(dateResolved).format('YYYY-MM-DDTHH:mm'),
      date_staff_acknowledged: moment(dateStaffAcknowledged).format(
        'YYYY-MM-DDTHH:mm'
      ),
      description_comment: descriptionComment,
      admin_comment: adminComment,
      staff_comment: staffComment,
      parts_comment: partsComment,
      private_staff_comment: privateStaffComment,
      private_admin_comment: privateAdminComment,
      comment_to_staff: commentToStaff,
      result: result,
      exclude_from_down_time: excludeFromDownTime,
      exclude_from_reporting: excludeFromReporting,
      staff_id: staff.id,
      client_comment: clientComment,
    }).then((res) => {
      history.goBack();
    });
  }
  const renderTabBar = (props) => (
    <TabBar
      {...props}
      scrollEnabled={true}
      tabStyle={{ width: 250 }}
      style={{
        backgroundColor: '#ee4023',
      }}
      getLabelText={({ route }) => route.title}
    />
  );

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(`/works/${props.match.params.id}/edit`)
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  return (
    <View style={styles.container}>
      <CardBlock padding={20}>
        <GridLayout>
          <Row>
            <Col lg={4} md={12}>
              <View style={{ paddingVertical: 20 }}>
                <View style={{ paddingVertical: 5 }}>
                  <InputLookUp
                    viewMode={isView}
                    displayTitle="Πελάτης"
                    displayKey="client"
                    endpoint="/admin/client"
                    searchQueryKey="search"
                    onSelect={(item) => setClient(item)}
                    value={client}
                    isRequired={true}
                    titleStyle={{ marginLeft: 14 }}
                  />
                </View>
                <View style={{ paddingVertical: 5 }}>
                  <InputLookUp
                    viewMode={isView}
                    displayTitle="Εταιρία"
                    value={company}
                    displayKey="name"
                    endpoint="/admin/companies"
                    searchQueryKey="search"
                    onSelect={(item) => setCompany(item)}
                    isRequired={true}
                    titleStyle={{ marginLeft: 14 }}
                  />
                </View>
                <View style={{ paddingVertical: 5 }}>
                  <InputLookUp
                    viewMode={isView}
                    displayTitle="Εγκατάσταση"
                    value={site}
                    displayKey="name"
                    endpoint="/admin/sites"
                    searchQueryKey="search"
                    onSelect={(item) => setSite(item)}
                    isRequired={true}
                    titleStyle={{ marginLeft: 14 }}
                  />
                </View>
                <View style={{ paddingVertical: 5 }}>
                  <InputLookUp
                    viewMode={isView}
                    displayTitle="Αιτία-Βλάβη"
                    value={cause}
                    displayKey="name"
                    endpoint="/admin/cause"
                    searchQueryKey="search"
                    onSelect={(item) => setCause(item)}
                    isRequired={true}
                    titleStyle={{ marginLeft: 14 }}
                  />
                </View>
              </View>
            </Col>
            <Col lg={4} md={12}>
              <View style={{ paddingVertical: 20 }}>
                <View style={{ paddingVertical: 5 }}>
                  <InputLookUp
                    viewMode={isView}
                    displayTitle="Τεχνικός"
                    value={staff}
                    displayKey="firstname"
                    endpoint="/admin/staff"
                    searchQueryKey="search"
                    onSelect={(item) => setStaff(item)}
                    titleStyle={{ marginLeft: 12 }}
                  />
                </View>
                <View style={{ paddingVertical: 5 }}>
                  <InputLookUp
                    viewMode={isView}
                    displayTitle="Συμβόλαιο"
                    value={contract}
                    displayKey="name"
                    endpoint="/admin/contracts"
                    searchQueryKey="search"
                    onSelect={(item) => setContract(item)}
                    titleStyle={{ marginLeft: 14 }}
                  />
                </View>
                <View style={{ paddingVertical: 5 }}>
                  <InputLookUp
                    viewMode={isView}
                    displayTitle="Υπηρεσία"
                    value={contractService}
                    displayKey="service"
                    endpoint={`/admin/services?contract_id=${contract.id}`}
                    searchQueryKey="search"
                    onSelect={(item) => setContractService(item)}
                    titleStyle={{ marginLeft: 14 }}
                  />
                </View>
              </View>
            </Col>
            <Col lg={4} md={12}>
              <View>
                <PickerChoiceSelection
                  items={[
                    { value: 0, label: 'Please Select' },
                    { value: 1, label: 'Major' },
                    { value: 2, label: 'Minor' },
                    { value: 3, label: 'Non Producation' },
                  ]}
                  selectedValue={severity}
                  onValueChange={(item) => setSeverity(item)}
                  displayTitle="Βαρύτητα Βλάβης"
                  viewMode={isView}
                  value={severity}
                  textStyle={{ marginTop: 20, marginLeft: 5 }}
                  textStyleValue={{ marginLeft: 6 }}
                />
              </View>
              <View>
                <PickerChoiceSelection
                  items={[
                    { value: 0, label: 'Please Select' },
                    { value: 1, label: 'Low' },
                    { value: 2, label: 'Medium' },
                    { value: 3, label: 'High' },
                  ]}
                  selectedValue={priority}
                  onValueChange={(item) => setPriority(item)}
                  displayTitle="Προτεραιότητα"
                  viewMode={isView}
                  value={priority}
                  textStyle={{ marginTop: 15, marginLeft: 5 }}
                  textStyleValue={{ marginLeft: 6 }}
                />
              </View>
              <View>
                <PickerChoiceSelection
                  items={[
                    { value: 0, label: 'Please Select' },
                    { value: 1, label: 'Νέο' },
                    { value: 2, label: 'Σε εξέλιξη' },
                    { value: 3, label: 'Έκλεισε' },
                    { value: 4, label: 'Ακυρώθηκε' },
                  ]}
                  selectedValue={status}
                  onValueChange={(item) => setStatus(item)}
                  displayTitle="Κατάσταση"
                  viewMode={isView}
                  value={status}
                  textStyle={{ marginTop: 15, marginLeft: 5 }}
                  textStyleValue={{ marginLeft: 6 }}
                />
              </View>
            </Col>
          </Row>
        </GridLayout>
        <TabView
          style={{
            maxWidth: 1400,
            width: '100%',
            minHeight: 600,
          }}
          renderTabBar={renderTabBar}
        >
          <Tab title="Βασικά Στοιχεία">
            <GridLayout>
              <Row>
                <Col lg={6} md={12}>
                  <View style={{ zIndex: 1000, paddingVertical: 10 }}>
                    <AssignDate
                      displayTitle={'Αναγγελία Βλάβης'}
                      onDateChange={(date) => setDateAnnounced(date)}
                      date={dateAnnounced}
                      id={'1'}
                    />
                  </View>
                  <View style={{ zIndex: 999, paddingVertical: 10 }}>
                    <AssignDate
                      displayTitle={'Ενημέρωση Τεχνικού'}
                      onDateChange={(date) => setDateStaffInformed(date)}
                      date={dateStaffInformed}
                      id={'2'}
                    />
                  </View>
                  <View style={{ zIndex: 998, paddingVertical: 10 }}>
                    <AssignDate
                      displayTitle={'Επιβεβαίωση Τεχνικού'}
                      onDateChange={(date) => setDateStaffAcknowledged(date)}
                      date={dateStaffAcknowledged}
                      id={'3'}
                    />
                  </View>
                </Col>
                <Col lg={6} md={12}>
                  <View style={{ zIndex: 997, paddingVertical: 10 }}>
                    <AssignDate
                      displayTitle={'Έναρξη'}
                      onDateChange={(date) => setDateStarted(date)}
                      date={dateStarted}
                      id={'4'}
                    />
                  </View>
                  <View style={{ zIndex: 996, paddingVertical: 10 }}>
                    <AssignDate
                      displayTitle={'Eπίσκεψη Τεχνικού'}
                      onDateChange={(date) => setDateVisited(date)}
                      date={dateVisited}
                      id={'5'}
                    />
                  </View>
                  <View style={{ zIndex: 995, paddingVertical: 10 }}>
                    <AssignDate
                      displayTitle={'Ολοκλήρωση'}
                      onDateChange={(date) => setDateResolved(date)}
                      date={dateResolved}
                      id={'6'}
                    />
                  </View>
                </Col>
              </Row>
            </GridLayout>
          </Tab>
          <Tab title="Παρατηρησεις Διαχειριστή">
            <GridLayout>
              <Row>
                <Col lg={6} md={12}>
                  <View style={{ width: 500 }}>
                    <InputForm
                      displayTitle="Περιγραφή Βλάβης"
                      placeholder="Περιγραφή Βλάβης"
                      value={descriptionComment}
                      onChangeText={(text) => setDescriptionComment(text)}
                      multiline={true}
                    />
                    <InputForm
                      displayTitle="Παρατηρήσεις Διαχ"
                      placeholder="Παρατηρήσεις Διαχ"
                      value={staffComment}
                      onChangeText={(text) => setStaffComment(text)}
                      multiline={true}
                    />
                    <InputForm
                      displayTitle="Παρατηρήσεις Admin"
                      placeholder="Παρατηρήσεις Admin"
                      value={adminComment}
                      onChangeText={(text) => setAdminComment(text)}
                      multiline={true}
                    />
                    <InputForm
                      displayTitle="Παρατηρήσεις Πελάτη"
                      placeholder="Παρατηρήσεις Πελάτη"
                      value={clientComment}
                      onChangeText={(text) => setClientComment(text)}
                      multiline={true}
                    />
                  </View>
                </Col>
                <Col lg={6} md={12}>
                  <View style={{ width: 500 }}>
                    <InputForm
                      displayTitle={'Οδηγίες προς τεχνικό'}
                      placeholder="Οδηγίες προς τεχνικό"
                      onChangeText={(text) => setCommentToStaff(text)}
                      value={commentToStaff}
                      multiline={true}
                    />
                    <InputForm
                      displayTitle="Παρατηρήσεις Διαχειριστή (admin use only)"
                      placeholder="Παρατηρήσεις Διαχειριστή (admin use only)"
                      value={privateAdminComment}
                      onChangeText={(text) => setPrivateAdminComment(text)}
                      multiline={true}
                    />
                    <InputForm
                      displayTitle="Παρατηρήσεις Υλικών"
                      placeholder="Admin"
                      value={partsComment}
                      onChangeText={(text) => setPartsComment(text)}
                      multiline={true}
                    />
                  </View>
                </Col>
              </Row>
            </GridLayout>
          </Tab>
          <Tab title="Παρατηρήσεις Τεχνικού">
            <View style={{ width: 500 }}>
              <InputForm
                displayTitle="Παρατηρήσεις Τεχνικού σε Διαχ"
                placeholder="Παρατηρήσεις Τεχνικού σε Διαχ"
                value={staffCommentToAdmin}
                onChangeText={(text) => setStaffCommentToAdmin(text)}
                multiline={true}
              />
              <InputForm
                displayTitle="Παρατηρήσεις Τεχνικού (staff use only)"
                placeholder="Παρατηρήσεις Τεχνικού (staff use only)"
                value={privateStaffComment}
                onChangeText={(text) => setPrivateStaffComment(text)}
                multiline={true}
              />
            </View>
          </Tab>
          <Tab title="Αποτέλεσμα">
            <PickerChoiceSelection
              items={[
                { value: 0, label: 'Please Select', key: 0 },
                { value: 1, label: 'Ok', key: 1 },
                { value: 2, label: 'Μη επίλυση', key: 1 },
                { value: 3, label: 'Άλλο', key: 1 },
              ]}
              selectedValue={result}
              onValueChange={(item) => setResult(item)}
              displayTitle="Αποτέλεσμα"
              viewMode={isView}
              value={result}
            />
            <PickerChoiceSelection
              items={[
                { value: 0, label: 'Please Select', key: 0 },
                { value: 1, label: 'Ναί', key: 1 },
                { value: 2, label: 'Όχι', key: 1 },
              ]}
              selectedValue={excludeFromDownTime}
              onValueChange={(item) => setExcludeFromDownTime(item)}
              displayTitle="Εξαίρεση από down time"
              viewMode={isView}
              value={excludeFromDownTime}
            />
            <PickerChoiceSelection
              items={[
                { value: 0, label: 'Please Select', key: 0 },
                { value: 1, label: 'Ναί', key: 1 },
                { value: 2, label: 'Όχι', key: 1 },
              ]}
              selectedValue={excludeFromReporting}
              onValueChange={(item) => setExcludeFromReporting(item)}
              displayTitle="Εξαίρεση από reporting"
              viewMode={isView}
              value={excludeFromReporting}
            />
            <View style={{ width: 300, marginTop: 10, marginLeft: 10 }}>
              <InputForm
                displayTitle="Αίτια Απόρριψης"
                placeholder="Αίτια Απόρριψης"
                value={disapproveReason}
                onChangeText={(text) => setDisapproveReason(text)}
                multiline={true}
              />
            </View>
          </Tab>
        </TabView>
        {renderButtons()}
        <View style={{ bottom: 150, marginLeft: 10 }}>
          {renderEditButton()}
        </View>
        {/*
        <View style={{ paddingVertical: 40 }}>
          <WorkTasksForm
            viewMode={isView}
            workId={props.match.params.id}
            serviceId={contractService?.id}
          />
        </View> */}
        {viewMode !== 'add' ? (
          <TabView
            style={{ maxWidth: 1400, width: '100%' }}
            renderTabBar={renderTabBar}
          >
            <Tab title="Ανταλλακτικά">
              <DetailedTable
                preset="workParts"
                editUrl={`/admin/works/${props.match.params.id}/workparts/:id`}
                addUrl={`/admin/works/${props.match.params.id}/workparts`}
                viewUrl={`/admin/works/${props.match.params.id}/workparts`}
                addData={{ work_id: props.match.params.id }}
              />
            </Tab>
            <Tab title="Υπηρεσίες/Χρεώσεις">
              <DetailedTable
                preset="worksBilling"
                viewUrl={`/admin/works/${props.match.params.id}/billing`}
                addUrl={`/admin/works/${props.match.params.id}/billing`}
                editUrl={`/admin/works/${props.match.params.id}/billing/:id`}
                addData={{ work_id: props.match.params.id }}
              />
            </Tab>
            <Tab title="Φωτογραφίες" />
            <Tab title="Χρεώσεις Τεχνικών">
              <DetailedTable
                preset="staffCost"
                viewUrl={`/admin/works/${props.match.params.id}/staffcost`}
                addUrl={`/admin/works/${props.match.params.id}/staffcost`}
                editUrl={`/admin/works/${props.match.params.id}/staffcost/:id`}
                addData={{ work_id: props.match.params.id }}
              />
            </Tab>
          </TabView>
        ) : null}
      </CardBlock>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonForm: {
    width: 280,
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 110,
    marginLeft: 15,
  },
});
