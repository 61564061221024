import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import Api from '../api/Api';
import CardBlock from '../components/CardBlock';
import Table from '../components/Tables/Table';
import Button from '../components/Button';

class ServiceTaskTemplates extends Component {
  state = {
    data: [],
  };

  fetchServiceTaskTemplates = async () => {
    const res = await Api.get('/admin/serviceTaskTemplates');
    let data = res.data.data;
    data.map((item, i) => {
      data[i].status = item.status === 1 ? 'Enabled' : 'Disabled';
      data[i].buttons = (
        <>
          <View
            style={{
              width: 60,
              flexDirection: 'row',
              marginLeft: 10,
            }}
          >
            <Button
              onPress={() =>
                this.props.history.push(`servicetasktemplates/${item.id}/edit`)
              }
              title="E"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginTop: 10,
                bottom: 5,
              }}
            />
            <Button
              onPress={() =>
                this.props.history.push(`/servicetasktemplates/${item.id}`)
              }
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
                marginTop: 10,
                bottom: 5,
              }}
            />
          </View>
        </>
      );
    });
    this.setState({
      data: data,
    });
  };

  componentDidMount() {
    this.fetchServiceTaskTemplates();
  }
  render() {
    return (
      <View>
        <View style={{ width: 350, flexDirection: 'row', marginBottom: 10 }}>
          <Button
            title="add new template"
            onPress={() => this.props.history.push('/servicetasktemplates/add')}
            style={styles.button}
          />
          <Button
            title="close panel"
            // onPress={() => this.toggle()}
            style={styles.button}
          />
        </View>
        <CardBlock title="Service Task Templates">
          <Table
            data={this.state.data}
            dataMap={{
              buttons: '',
              name: 'Ονομα',
              service: 'Υπηρεσία',
              status: 'Κατασταση',
            }}
          />
        </CardBlock>
      </View>
    );
  }
}

export default ServiceTaskTemplates;

const styles = StyleSheet.create({
  button: {
    width: 180,
    marginTop: 15,
    marginLeft: 5,
  },
});
