import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native-web';
import Api from '../api/Api';
import Button from '../components/Button';
import { createBrowserHistory } from 'history';
import InputText from '../components/Inputs/InputText';
import { useRouteMatch } from 'react-router-dom';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { GridLayout, Row, Col } from '../components/Grid';
import CardBlock from '../components/CardBlock';
import Messages from '../components/Messages';

let history = createBrowserHistory();

export default function AddUserForm(props) {
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState();
  const [notes, setNotes] = useState();
  const [lastName, setLastName] = useState('');
  const [viewMode, setViewMode] = useState('view');
  const [errorMessage, setErrorMessage] = useState('');

  let viewPath = useRouteMatch({
    path: '/users/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/users/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/users/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  const validate = () => {
    if (!lastName || !name || !email || !password || !status) {
      setErrorMessage('Required fields');
      return false;
    }
    setErrorMessage('');
    return true;
  };
  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(`/users/${props.match.params.id}/edit`)
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  async function addUser() {
    if (validate()) {
      Api.post('/admin/users', {
        password: password,
        first_name: name,
        last_name: lastName,
        email: email,
        status: status,
        notes: notes,
      }).then((res) => {
        history.goBack();
      });
    }
  }

  async function editUser() {
    Api.put('/admin/users/' + props.match.params.id, {
      password: password,
      first_name: name,
      email: email,
      status: status,
      notes: notes,
      last_name: lastName,
    }).then((res) => {
      history.goBack();
    });
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addUser()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editUser()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  useEffect(() => {
    const fetchUser = async () => {
      let res = await Api.get('/admin/users/' + props.match.params.id);
      let data = res.data.data;
      setEmail(data.email);
      setNotes(data.notes);
      setStatus(data.status);
      setName(data.first_name);
      setLastName(data.last_name);
    };
    if (props.match.params.id) {
      fetchUser();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setPassword('');
    setName('');
    setEmail('');
    setStatus();
    setNotes();
    setLastName();
  }
  return (
    <View style={[styles.container]}>
      <CardBlock padding={20}>
        <GridLayout>
          <Row>
            <Col lg={4} md={12}>
              <View style={{ width: 250 }}>
                <InputText
                  displayTitle="Ονομα"
                  viewMode={isView}
                  value={name}
                  onChangeText={(e) => setName(e)}
                  multiline={true}
                  placeholder="Add name"
                  isRequired={true}
                />
                {!isView ? (
                  <InputText
                    displayTitle="Password"
                    viewMode={isView}
                    value={password}
                    onChangeText={(e) => setPassword(e)}
                    multiline={true}
                    placeholder="Add password"
                    isRequired={true}
                  />
                ) : (
                  ''
                )}
                <InputText
                  displayTitle="Επιθετο"
                  viewMode={isView}
                  value={lastName}
                  onChangeText={(e) => setLastName(e)}
                  multiline={true}
                  placeholder="Add lastName"
                  isRequired={true}
                />
              </View>
            </Col>
            <Col lg={4} md={12}>
              <View style={{ width: 250 }}>
                <InputText
                  displayTitle="email"
                  viewMode={isView}
                  value={email}
                  onChangeText={(e) => setEmail(e)}
                  multiline={true}
                  placeholder="Add email"
                  isRequired={true}
                />

                <InputText
                  viewMode={isView}
                  displayTitle="Περιγραφη"
                  value={notes}
                  placeholder="Περιγραφη"
                  onChangeText={(e) => setNotes(e)}
                />
              </View>
            </Col>
            <Col lg={4} md={12}>
              <PickerChoiceSelection
                items={[
                  { value: 0, label: 'Disabled', key: 0 },
                  { value: 1, label: 'Enabled', key: 1 },
                ]}
                selectedValue={status}
                onValueChange={(item) => setStatus(item)}
                displayTitle="Κατάσταση"
                viewMode={isView}
                value={status}
                isRequired={true}
              />
            </Col>
          </Row>
        </GridLayout>
        {renderButtons()}
        {renderEditButton()}
      </CardBlock>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    marginHorizontal: 5,
  },
  buttonForm: {
    width: 250,
    flexDirection: 'row',
  },
});
