import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import Api from '../api/Api';
import CardBlock from '../components/CardBlock';
import Table from '../components/Tables/Table';
import Button from '../components/Button';
import history from 'history';
import InputText from '../components/Inputs/InputText';

export default (props) => {
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [search, setSearch] = useState('');
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    const fetchContract = async () => {
      let response = await Api.get('/admin/contracts', {
        params: {
          search: search,
        },
      });
      let data = response.data.data;
      data.map((item, i) => {
        data[i].status = item.status === 1 ? 'Enabled' : 'Disabled';
        data[i].contract_category =
          item.contract_category === 1
            ? 'Συμβόλαιο Πελάτη'
            : 'Συμβόλαιο Συνεργάτη';
        data[i].buttons = (
          <>
            <View style={{ width: 60, flexDirection: 'row', marginLeft: 10 }}>
              <Button
                onPress={() =>
                  props.history.push(`contracts/${item.contract_id}/edit`)
                }
                title="E"
                style={{ width: 30, height: 30, borderRadius: '100%' }}
              />
              <Button
                onPress={() =>
                  props.history.push(`/contracts/${item.contract_id}`)
                }
                title="V"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: '100%',
                  marginLeft: 5,
                }}
              />
            </View>
          </>
        );
      });

      setContracts(data);
      console.log(data);
    };
    fetchContract();
  }, [search]);

  const handleFiltering = (text) => {
    setSearch(text);
    setFilteredContracts(contracts.filter((item) => item.name.includes(text)));
  };

  let filterContracts =
    !search && filteredContracts.length === 0 ? contracts : filteredContracts;

  return (
    <View>
      <View style={{ width: 400, flexDirection: 'row' }}>
        <Button
          title="add new contract"
          onPress={() => props.history.push('/contracts/add')}
          style={styles.button}
        />
        <Button
          title="close panel"
          onPress={() => handleToggle()}
          style={styles.button}
        />
      </View>
      {!toggle && (
        <View style={{ width: 250, marginBottom: 10 }}>
          <InputText
            onChangeText={handleFiltering}
            value={search}
            placeholder="search for a contract"
          />
        </View>
      )}

      <CardBlock title="Contracts">
        <Table
          data={filterContracts}
          dataMap={{
            buttons: '',
            name: 'ΟΝΟΜΑ',
            site_category: 'Κατηγορια Εργου',
            status: 'Κατασταση',
            duration: 'ΔΙΑΡΚΕΙΑ',
            contract_category: 'Κατηγορια',
          }}
        />
      </CardBlock>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    width: 180,
    marginTop: 15,
    marginLeft: 5,
  },
});
