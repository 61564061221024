import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import Button from '../Button';
import InputText from '../Inputs/InputText';
import { Col, GridLayout, Row } from '../Grid';
import PickerChoiceSelection from '../Inputs/PickerChoiceSelection';

class SiteInfo extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  finish = (e) => {
    e.preventDefault();
    this.props.finish();
  };

  render() {
    const {
      siteName,
      description,
      power,
      gaugeNumber,
      provisionNumber,
      initialPerformance,
      lat,
      lng,
      location,
      category,
      handleChange,
    } = this.props;
    return (
      <>
        <Text>Enter your Site information:</Text>
        <GridLayout>
          <Row>
            <Col xs={3} lg={4} md={6}>
              <TextInput
                type="text"
                siteName="siteName"
                value={siteName}
                onChange={handleChange('siteName')}
                placeholder="site Name *"
                style={styles.input}
              />
              <TextInput
                type="text"
                description="description"
                value={description}
                onChange={handleChange('description')}
                placeholder="description"
                style={styles.input}
              />

              <TextInput
                type="text"
                power="power"
                value={power}
                onChange={handleChange('power')}
                placeholder="power"
                style={styles.input}
              />

              <TextInput
                type="text"
                gaugeNumber="gaugeNumber"
                value={gaugeNumber}
                onChange={handleChange('gaugeNumber')}
                placeholder="Gauge Number *"
                style={styles.input}
              />
              <TextInput
                type="text"
                provisionNumber="provisionNumber"
                value={provisionNumber}
                onChange={handleChange('provisionNumber')}
                placeholder="Provision Number *"
                style={styles.input}
              />
            </Col>
            <Col lg={6}>
              <TextInput
                type="text"
                initialPerformance="initialPerformance"
                value={initialPerformance}
                onChange={handleChange('initialPerformance')}
                placeholder="initial Performance"
                style={styles.input}
              />
              <TextInput
                type="text"
                lat="lat"
                value={lat}
                onChange={handleChange('lat')}
                placeholder="lat"
                style={styles.input}
              />

              <TextInput
                type="text"
                lng="lng"
                value={lng}
                onChange={handleChange('lng')}
                placeholder="lng"
                style={styles.input}
              />
              <TextInput
                type="text"
                location="location"
                value={location}
                onChange={handleChange('location')}
                placeholder="location"
                style={styles.input}
              />
              <View style={{ bottom: 30 }}>
                <PickerChoiceSelection
                  items={[
                    { value: 0, label: 'Please Select' },
                    { value: 1, label: 'Φ/Β ΠΑΡΚΟ' },
                    { value: 2, label: 'Φ/Β ΣΤΕΓΗ' },
                    { value: 3, label: 'Φ/Β ΑΓΡΟΤΙΚΟ' },
                    { value: 4, label: 'Φ/Β ΑΥΤΟΝΟΜΟ' },
                    { value: 5, label: 'ΚΤΗΡΙΟ' },
                  ]}
                  selectedValue={category}
                  onValueChange={(item) =>
                    handleChange('category')({ target: item })
                  }
                  // displayTitle="Κατηγορια"
                  viewMode={false}
                  value={category}
                />
              </View>
            </Col>
          </Row>
        </GridLayout>
        <View style={{ flex: 1, flexDirection: 'row', width: 300 }}>
          <Button title="« Back" onPress={this.back} />
          <Button
            style={{ marginLeft: 10 }}
            title="Finish "
            onPress={this.finish}
          />
        </View>
      </>
    );
  }
}

export default SiteInfo;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: '#ee4023',
    padding: 20,
    borderRadius: 15,
    width: 250,
    marginVertical: 10,

    fontSize: 18,
  },
});
