import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import Api from '../api/Api';
import Table from '../components/Tables/Table';
import Button from '../components/Button';
import CardBlock from '../components/CardBlock';
import InputText from '../components/Inputs/InputText';

export default class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      searchInput: '',
      formDisplay: false,
    };
  }

  fetchMaterials = async () => {
    let res = await Api.get('/admin/materials', {
      params: {
        search: this.state.searchInput,
      },
    });
    let data = res.data.data;

    this.setState({
      data: data,
      filteredData: data,
    });
  };

  componentDidMount() {
    this.fetchMaterials();
  }

  render() {
    this.state.data.map((item) => {
      return (item.buttons = (
        <>
          <View
            style={{
              width: 60,
              flexDirection: 'row',
              marginLeft: 15,
              marginBottom: 7,
            }}
          >
            <Button
              onPress={() =>
                this.props.history.push(`materials/${item.id}/edit`)
              }
              title="E"
              style={{ width: 30, height: 30, borderRadius: '100%' }}
            />
            <Button
              onPress={() => this.props.history.push(`/materials/${item.id}`)}
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
              }}
            />
          </View>
        </>
      ));
    });
    return (
      <View style={styles.container}>
        <View style={{ width: 350, flexDirection: 'row' }}>
          <Button
            title="add new material"
            onPress={() => this.props.history.push('/materials/add')}
            style={styles.button}
          />
          <Button
            style={styles.button}
            title="close panel"
            onPress={() => this.Toggle()}
          />
        </View>
        <View style={{ marginTop: 10 }}>
          <CardBlock title="Υλικα">
            <Table
              widthArr={[150, 150, 150, 150, 180, 150, 150, 150, 150, 150]}
              dataMap={{
                buttons: '',
                name: 'Ονομα',
                material_category_id: 'Κατηγορια',
                description: 'Περιγραφή',
                manufacturer_id: 'Κατασκευαστής',
                supplier_id: 'Προμηθευτης',
                status: 'Κατασταση',
                notes: 'Σημειώσεις',
                access_username: 'Χρήστης',
                access_password: 'Συνθηματικό',
              }}
              data={this.state.data}
            />
          </CardBlock>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    width: 180,
    marginTop: 15,
    marginLeft: 5,
  },
});
