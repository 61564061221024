import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import Api from '../api/Api';
import Table from '../components/Tables/Table';
import Button from '../components/Button';
import CardBlock from '../components/CardBlock';
import InputText from '../components/Inputs/InputText';

class AdminClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchInput: '',
      filteredClients: [],
      client: [],
      agents: [],
      filteredAgents: [],
      formDisplay: false,
    };
  }

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  fetchClients = ({ agent_id, client_id, text } = {}) => {
    Api.get('/admin/client', {
      params: {
        search: this.state.searchInput,
        agent_id: agent_id,
      },
    })
      .then((res) => {
        let data = res.data.data;
        data.map((item, i) => {
          data[i].status = item.status === 1 ? 'Enabled' : 'Disabled';
        });
        this.setState({ data: data, filteredClients: data });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchAgents = () => {
    Api.get('/admin/agents')
      .then((res) => {
        let data = res.data.data;
        this.setState({ agents: data });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteClient = (id) => {
    Api.delete('/admin/client/ ' + id).then((res) => {
      console.log('deleted item with id' + id);
    });
  };

  searchTimeout;
  filteredClients = (text) => {
    this.setState({
      searchInput: text,
      filteredData: this.state.data.filter((item) =>
        item.client.includes(text)
      ),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchClients(), 1000);
  };

  componentDidMount() {
    this.fetchClients();
    this.fetchAgents();
  }

  searchTimeout;
  filterAgents = (key) => {
    this.setState({
      filteredAgents: this.state.agents.filter((item) => {
        item.agent_id.includes(key);
      }),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchAgents(), 1000);
  };

  render() {
    let filteredClients =
      !this.state.searchInput && this.state.filteredClients.length === 0
        ? this.state.data
        : this.state.filteredClients;

    this.state.data.map((item) => {
      return (item.button = (
        <Button
          onPress={() => this.props.history.push(`/clients/${item.id}`)}
          title="V"
          style={{ width: 30, height: 30, borderRadius: '100%' }}
        />
      ));
    });

    this.state.data.map((item) => {
      return (item.deleteButton = (
        <Button onPress={() => this.deleteClient(item.id)} title="Delete" />
      ));
    });

    this.state.data.map((item) => {
      return (item.buttons = (
        <>
          <View
            style={{
              width: 60,
              flexDirection: 'row',
              marginLeft: 15,
              marginBottom: 7,
            }}
          >
            <Button
              onPress={() => this.props.history.push(`clients/${item.id}/edit`)}
              title="E"
              style={{ width: 30, height: 30, borderRadius: '100%' }}
            />
            <Button
              onPress={() => this.props.history.push(`/clients/${item.id}`)}
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
              }}
            />
          </View>
        </>
      ));
    });

    return (
      <View style={styles.container}>
        <View style={{ width: 300, flexDirection: 'row' }}>
          <Button
            title="add new client"
            onPress={() => this.props.history.push('/clients/add')}
            style={styles.button}
          />
          <Button
            style={styles.button}
            title="close panel"
            onPress={() => this.Toggle()}
          />
        </View>
        {!this.state.formDisplay && (
          <View style={{ width: 200, marginBottom: 10 }}>
            <InputText
              placeholder="search"
              onChangeText={this.filteredClients}
              value={this.state.searchInput}
            />
          </View>
        )}
        <View style={{ marginTop: 10 }}>
          <CardBlock title="Clients">
            <Table
              dataMap={{
                buttons: '',
                client: 'Πελατης',
                address: 'Διευθυνση',
                city: 'Πολη',
                country: 'Χωρα',
                status: 'Κατασταση',
              }}
              data={filteredClients}
            />
          </CardBlock>
        </View>
      </View>
    );
  }
}

export default AdminClients;

const styles = StyleSheet.create({
  container: {},
  clientsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  textColor: {
    color: 'blue',
  },
  fontSize: {
    fontSize: '30px',
  },
  textUnderline: {
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    paddingRight: 180,
    borderStyle: 'dotted',
  },

  button: {
    width: 180,
    marginTop: 15,
    marginLeft: 5,
  },
});
