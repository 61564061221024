import React, { Component } from 'react';
import InputLookUp from './Inputs/InputLookUp';
import InputText from './Inputs/InputText';
import { View, Picker, TextInput, StyleSheet, Text } from 'react-native-web';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  onChangeFilters = (key, value) => {
    this.setState({ filters: { ...this.state.filters, [key]: value } }, () => {
      this.props.onChangeFilters(this.state.filters);
    });
  };

  selectSearchChoice = (field) => {
    switch (field.type) {
      case 'InputLookUp':
        return (
          <InputLookUp
            {...field.lookupProps}
            value={this.state.filters[field.key]}
            onSelect={(value) => this.onChangeFilters(field.key, value)}
          />
        );
      case 'InputText':
        return (
          <InputText
            {...field.inputFormProps}
            onChangeText={(value) => this.onChangeFilters(field.key, value)}
          />
        );

      case 'Picker':
        return (
          <View>
            <Text style={[styles.displayTitle, this.props.pickerText]}>
              {this.props.displayTitle}
            </Text>
            <Picker
              style={[styles.pickerStyle, this.props.pickerStyling]}
              {...field.pickerProps}
              onValueChange={(value) => {
                this.onChangeFilters(field.key, value);
                if (
                  field.pickerProps &&
                  typeof field.pickerProps.onValueChange === 'function'
                ) {
                  field.pickerProps.onValueChange(value);
                }
              }}
            >
              {field.items.map((item) => (
                <Picker.Item
                  key={item.key}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Picker>
          </View>
        );

      case 'TextInput':
        return (
          <TextInput
            {...field.inputTextProps}
            onChangeText={(value) => this.onChangeFilters(field.key, value)}
          />
        );

      default:
        return <View />;
    }
  };

  render() {
    return (
      <View style={styles.container}>
        {this.props.fields.map((field) => this.selectSearchChoice(field))}
      </View>
    );
  }
}

export default Filters;

const styles = StyleSheet.create({
  pickerStyle: {
    height: 35,
    width: 150,
    borderRadius: 25,
    backgroundColor: '#ee4023',
    borderWidth: 1,
    textAlign: 'center',
    marginBottom: 5,
  },
  displayTitle: {
    marginLeft: 5,
    marginTop: 5,
  },
});
