import React from 'react';
import { View, Text, StyleSheet, Picker } from 'react-native';
// import { Picker } from '@react-native-picker/picker';

export default function PickerChoiceSelection(props) {
  return (
    <View>
      <Text style={[styles.text, props.textStyle]}>
        {props.displayTitle} {props.isRequired ? '*' : null}
      </Text>
      {!props.viewMode ? (
        <View>
          <Picker
            {...props}
            isRequired={props.isRequired}
            style={[styles.picker, props.pickerStyle]}
            onValueChange={(itemValue, itemPosition) => {
              if (props.onValueChange) {
                props.onValueChange(itemValue, itemPosition);
              }
              if (props.onChangeValue) {
                props.onChangeValue(itemValue);
              }
            }}
            selectedValue={props.value}
          >
            {props.items.map((item) => (
              <Picker.Item
                key={item.key || item.value}
                value={item.value}
                label={item.label}
              />
            ))}
          </Picker>
        </View>
      ) : (
        <Text style={[styles.textValue, props.textStyleValue]}>
          {props.value !== undefined && props.value !== null
            ? props.items.find((item) => item.value === props.value)?.label
            : null}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  picker: {
    width: 150,
    height: 35,
    borderRadius: 25,
    backgroundColor: '#ee4023',
    borderWidth: 1,

    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    color: '#ee4023',
    right: 3,
    fontFamily: 'ManropeSemiBold',
  },
  textValue: {
    fontSize: 16,
    fontFamily: 'Manrope-Regular',
  },
});
