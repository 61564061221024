import React from 'react';
import { View, StyleSheet } from 'react-native';

const Messages = ({ isVisible, type, message }) => {
  return (
    <View>
      {isVisible && type === 'success' ? (
        <View style={styles.successMessage}>{message}</View>
      ) : isVisible && type === 'error' ? (
        <View style={styles.errorMessage}>{message}</View>
      ) : null}
    </View>
  );
};

export default Messages;

const styles = StyleSheet.create({
  successMessage: {
    color: 'green',
    letterSpacing: 1,
    fontSize: 18,
    borderWidth: 1,
    borderRadius: 25,
    borderColor: 'green',
  },
  errorMessage: {
    color: 'red',
    letterSpacing: 1,
    fontSize: 18,
    borderWidth: 1,
    borderRadius: 25,
    borderColor: 'red',
    marginTop: 15,
    marginLeft: 10,
    padding: 5,
    marginBottom: 10,
    textAlign: 'center',
  },
});
