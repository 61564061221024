import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native-web';
import Api from '../api/Api';
import Button from '../components/Button';
import Table from '../components/Tables/Table';
import InputText from '../components/Inputs/InputText';
import CardBlock from '../components/CardBlock';

class Users extends Component {
  state = {
    Users: [],
    searchInput: '',
    filteredUsers: [],
    formDisplay: false,
    passwordInput: '',
    name: '',
  };

  componentDidMount() {
    this.fetchUsers();
  }

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  fetchUsers = () => {
    Api.get('admin/users', {
      params: {
        search: this.state.searchInput,
      },
    })
      .then((res) => {
        let data = res.data.data;
        data.filter((item) => Object.keys(item));
        let filteredData = data.filter((item) => item.role === 'admin');
        console.log(filteredData);
        this.setState({ Users: filteredData, filteredUsers: filteredData });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addUser = () => {
    Api.post('admin/users')
      .then((res) => {
        let data = res.data.data;
        this.state.Users.push(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchTimeout;
  filterUsers = (text) => {
    this.setState({
      searchInput: text,
      filteredUsers: this.state.Users.filter((item) =>
        item.first_name.includes(text)
      ),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchUsers(), 1000);
  };

  render() {
    let filteredUsers =
      !this.state.searchInput && this.state.filteredUsers.length === 0
        ? this.state.Users
        : this.state.filteredUsers;
    this.state.Users.map((item) => {
      return (item.buttons = (
        <>
          <View style={{ width: 60, flexDirection: 'row', marginLeft: 10 }}>
            <Button
              onPress={() => this.props.history.push(`users/${item.id}/edit`)}
              title="E"
              style={{ width: 30, height: 30, borderRadius: '100%' }}
            />
            <Button
              onPress={() => this.props.history.push(`/users/${item.id}`)}
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
              }}
            />
          </View>
        </>
      ));
    });

    this.state.Users.map((item) => {
      return (item.deleteButton = (
        <Button onPress={() => this.deleteUser(item.id)} title="Delete User" />
      ));
    });

    return (
      <View style={styles.container}>
        <View style={{ width: 300, flexDirection: 'row' }}>
          <Button
            title="add new user"
            onPress={() => this.props.history.push('/users/add')}
            style={styles.button}
          />
          <Button
            title="close panel"
            onPress={() => this.Toggle()}
            style={styles.button}
          />
        </View>
        {!this.state.formDisplay && (
          <View style={{ width: 250 }}>
            <InputText
              onChangeText={this.filterUsers}
              value={this.searchInput}
              placeholder="search for a client"
            />
          </View>
        )}
        <View style={{ marginTop: 10 }}>
          <CardBlock title="Users">
            <Table
              dataMap={{
                buttons: '',
                first_name: 'Όνομα',
                last_name: 'Επώνυμο',
                email: 'Email',
              }}
              data={filteredUsers}
            />
          </CardBlock>
        </View>
      </View>
    );
  }
}

export default Users;

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  textColor: {
    color: 'blue',
  },
  fontSize: {
    fontSize: '30px',
  },
  textUnderline: {
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    paddingRight: 180,
    borderStyle: 'dotted',
  },
  userInput: {
    padding: 10,
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: 'black',
    width: '20%',
  },

  button: {
    width: 180,
    marginTop: 15,
    marginLeft: 5,
  },
});
