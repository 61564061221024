import React, { Component } from 'react';
import { View, Picker, StyleSheet } from 'react-native';
import Table from './Table';
import Api from '../../api/Api';
import InputText from '../Inputs/InputText';
import Button from '../Button';
import PickerChoiceSelection from '../Inputs/PickerChoiceSelection';
import InputLookUp from '../Inputs/InputLookUp';
import Modal from '../Modals/Modal';
import AssignDate from '../AssignDate';
import DatePickerRange from '../DatePickerRange';

let presets = {
  contracts: {
    viewUrl: 'admin/contracts',
    addUrl: 'admin/contracts',
    editUrl: 'admin/contracts/:id',
    fieldList: {
      name: { name: 'Name', component: InputText },
    },
  },

  staffService: {
    fieldList: {
      contract_id: {
        name: 'Συμβολαιο',
        component: InputLookUp,
        props: { displayKey: 'name', endpoint: '/admin/contracts' },
      },
      service_id: {
        name: 'Υπηρεσια',
        component: InputLookUp,
        props: { displayKey: 'service', endpoint: '/admin/services' },
      },
      cost: {
        name: 'Κόστος',
        component: InputText,
      },
    },
  },

  staffSiteContract: {
    fieldList: {
      site_id: {
        name: 'Εγκατάσταση',
        component: InputLookUp,
        props: {
          displayKey: 'name',
          endpoint: '/admin/sites',
        },
        prepareValue: (item) => {
          return { item: item.name };
        },
      },
      contract_id: {
        name: 'Συμβολαιο',
        component: InputLookUp,
        props: { displayKey: 'name', endpoint: '/admin/contracts' },
        prepareValue: (item) => {
          return item.name;
        },
      },
      contract_ver: {
        name: 'Ενδ.Ονομασια Συμβολαιου',
        width: 350,
        component: InputText,
      },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργο' },
            { value: 2, label: 'Ανενεργο' },
          ],
        },
      },
      date_start: {
        name: 'Από Ημερ/νια',
        component: AssignDate,
      },
      date_end: {
        name: 'Εώς Ημερ/νια',
        component: AssignDate,
      },
    },
  },

  contractServices: {
    fieldList: {
      service_id: {
        name: 'Υπηρεσια',
        component: InputLookUp,
        props: { displayKey: 'service', endpoint: '/admin/services' },
      },
      period: {
        name: 'Περιοδος',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ημέρα' },
            { value: 2, label: 'Εβδομαδα' },
            { value: 3, label: 'Μηνας' },
            { value: 4, label: 'Ετος' },
          ],
        },
      },
      period_step: { name: 'Βημα περιοδου', component: InputText },
      price: { name: 'Αξία', component: InputText },
    },
  },

  companies: {
    viewUrl: '/admin/companies',
    addUrl: 'admin/companies',
    editUrl: 'admin/companies/:id',
    fieldList: {
      name: { name: 'Name', component: InputText },
      address: { name: 'Διευθυνση', component: InputText },
      city: { name: 'Πολη', component: InputText },
      post_code: { name: 'Ταχ Κωδ', component: InputText },
      country: { name: 'Χωρα', component: InputText },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργο' },
            { value: 2, label: 'Ανενεργο' },
          ],
        },
      },
    },
  },
  companyContact: {
    viewUrl: '/admin/companies/:id/contacts',
    fieldList: {
      firstname: { name: 'Ονομα', component: InputText },
      lastname: { name: 'Επωνυμο', component: InputText },
      role: {
        name: 'Θεση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'Please Select' },
            { value: 1, label: 'Μέτοχος' },
            { value: 2, label: 'Στέλεχος' },
            { value: 3, label: 'Δοικ. Υπαλ.' },
            { value: 4, label: 'Τεχνικος' },
            { value: 5, label: 'Συνεργατης' },
            { value: 6, label: 'Αλλο' },
          ],
        },
      },
      social_security: { name: 'Α.Μ.Κ.Α', component: InputText },
      mobile: { name: 'Κινητο', component: InputText },
      tel: { name: 'Τηλεφωνο', component: InputText },
      email: { name: 'Email', component: InputText },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργο' },
            { value: 2, label: 'Ανενεργο' },
          ],
        },
      },
      city: { name: 'Πολη', component: InputText },
      postal_code: { name: 'Ταχ Κωδ', component: InputText },
      country: { name: 'Χωρα', component: InputText },
      vat_no: { name: 'Α.Φ.Μ', component: InputText },
      vat_office: { name: 'Δ.Ο.Υ', component: InputText },
    },
  },
  sites: {
    viewUrl: '/admin/sites',
    addUrl: '/admin/sites/',
    editUrl: '/admin/sites/:id',
    fieldList: {
      name: { name: 'Ονομα', component: InputText },
      power: { name: 'Ισχυς', component: InputText },
      site_category_id: {
        name: 'Κατηγορια',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'Please Select' },
            { value: 1, label: 'Φ/Β ΠΑΡΚΟ' },
            { value: 2, label: 'Φ/Β ΣΤΕΓΗ' },
            { value: 3, label: 'Φ/Β ΑΓΡΟΤΙΚΟ' },
            { value: 4, label: 'Φ/Β ΑΥΤΟΝΟΜΟ' },
            { value: 5, label: 'ΚΤΗΡΙΟ' },
          ],
        },
      },
      number_paroxi: { name: 'Αριθμος παροχης', component: InputText },
    },
  },
  siteMaterials: {
    viewUrl: '/admin/sites/:id/materials',
    fieldList: {
      material_category_id: {
        name: 'Κατηγορια',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'Please Select' },
            { value: 1, label: 'Inverter' },
            { value: 2, label: 'Panel' },
            { value: 3, label: 'Αναλώσιμα' },
            { value: 4, label: 'Άλλο' },
          ],
        },
      },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργο' },
            { value: 2, label: 'Ανενεργο' },
          ],
        },
      },
      material_id: {
        name: 'Υλικό',
        component: InputLookUp,
        width: 210,
        props: { displayKey: 'name', endpoint: '/admin/materials' },
      },
      quantity: {
        name: 'Ποσότητα',
        component: InputText,
      },
      notes: {
        name: 'Σημειώσεις',
        component: InputText,
      },
      // installation_date: {
      //   name: 'Ημερ.Εγκατ.',
      // },
      staff_id: {
        name: 'Υπευθ. Εγκατ.',
        component: InputLookUp,
        width: 200,
        props: { displayKey: 'firstname', endpoint: '/admin/staff' },
      },
    },
  },

  siteCredentials: {
    viewUrl: '/admin/sites/:id/credentials',
    fieldList: {
      credential_type: {
        name: 'Τυπος Προσβασης',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Συναγερμος' },
            { value: 2, label: 'Καμερες/Καταγραφικα' },
            { value: 3, label: 'Inverters' },
          ],
        },
      },
      device_type: { name: 'Τυπος συσκευης ', component: InputText },
      url: { name: 'URL', component: InputText },
      username: { name: 'username', component: InputText },
      password: { name: 'password', component: InputText },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργο' },
            { value: 2, label: 'Ανενεργο' },
          ],
        },
      },
    },
  },
  siteAttributes: {
    addUrl: '/admin/sites/credentials',
    fieldList: {
      attribute_option: { name: 'Επιλογη', component: InputText },
      attribute_id: {
        name: 'Χαρακτηριστικο',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'ΤΥΠΟΣ ΕΓΚΑΤΑΣΤΑΣΗΣ' },
            { value: 2, label: 'ΠΛΑΙΣΙΑ' },
            { value: 3, label: 'ΜΕΤΑΤΡΟΠΕΙΣ' },
            { value: 4, label: 'ΒΑΣΗ ΣΤΗΡΙΞΗΣ' },
            { value: 5, label: 'ΗΛΕΚΤΡΟΝΟΜΟΣ' },
          ],
        },
      },
    },
  },

  sitePerformance: {
    addUrl: '/admin/sites/performance',
    fieldList: {
      pvgis: { name: 'PVGIS', component: InputText },
      acual: { name: 'Αποδοση', component: InputText },
      pr: { name: 'Δεικτης Αποδοσης (P.R)', component: InputText, width: 300 },
      month: {
        name: 'Μηνας',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ιανουαριος' },
            { value: 2, label: 'Φεβρουαριος' },
            { value: 3, label: 'Μαρτιος' },
            { value: 4, label: 'Απριλλιος' },
            { value: 5, label: 'Μαιος' },
            { value: 6, label: 'Iουνιος' },
            { value: 7, label: 'Ιουλιος' },
            { value: 8, label: 'Αυγουστος' },
            { value: 9, label: 'Σεπτεμβριος' },
            { value: 10, label: 'Οκτωβριος' },
            { value: 11, label: 'Νοεμβριος' },
            { value: 12, label: 'Δεκεμβριος' },
          ],
        },
      },
    },
  },
  siteServices: {
    fieldList: {
      contract_id: {
        name: 'Συμβολαιο',
        component: InputLookUp,
        width: 200,
        props: { displayKey: 'name', endpoint: '/admin/contracts' },
      },
      service_id: {
        name: 'Υπηρεσια',
        component: InputLookUp,
        props: { displayKey: 'service', endpoint: '/admin/services' },
      },
      period_step: { name: 'Βημα περιοδου', component: InputText },
      price: { name: 'Αξια', component: InputText },
      period: {
        name: 'Περιοδος',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ημέρα' },
            { value: 2, label: 'Εβδομαδα' },
            { value: 3, label: 'Μηνας' },
            { value: 4, label: 'Ετος' },
          ],
        },
      },
    },
  },

  siteContract: {
    fieldList: {
      contract_id: {
        name: 'Συμβολαιο',
        component: InputLookUp,
        width: 200,
        props: { displayKey: 'name', endpoint: '/admin/contracts' },
      },
      contract_ver: { name: 'Ενδ. Ονομ.', component: InputText },
      duration: { name: 'Διαρκεια', component: InputText, width: 110 },
      description: { name: 'Περιγραφη', component: InputText, width: 200 },
      site_category_id: {
        name: 'Κατηγορια',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'Please Select' },
            { value: 1, label: 'Φ/Β ΠΑΡΚΟ' },
            { value: 2, label: 'Φ/Β ΣΤΕΓΗ' },
            { value: 3, label: 'Φ/Β ΑΓΡΟΤΙΚΟ' },
            { value: 4, label: 'Φ/Β ΑΥΤΟΝΟΜΟ' },
            { value: 5, label: 'ΚΤΗΡΙΟ' },
          ],
        },
      },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργο' },
            { value: 2, label: 'Ανενεργο' },
          ],
        },
      },
      date_start: {
        name: 'Εναρξη',
        component: DatePickerRange,
        width: 300,
      },
      date_end: {
        name: 'Ληξη',
        component: DatePickerRange,
        width: 300,
      },
    },
  },

  works: {
    viewUrl: '/admin/works',
    addUrl: '/admin/works/add',
    editUrl: '/admin/works/:id',
    fieldList: {
      client: { name: 'Πελατης', component: InputText },
      site_name: { name: 'Εγκατασταση', component: InputText },
      name: { name: 'Εταρια', component: InputText },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργο' },
            { value: 2, label: 'Ανενεργο' },
          ],
        },
      },
      severity: { name: 'Bαρυτητα Βλαβης', component: PickerChoiceSelection },
      priority: { name: 'Προτεραιοτητα', component: PickerChoiceSelection },
      admin_comment: { name: 'Admin Comment', component: InputText },
      private_admin_comment: {
        name: 'Private Admin Comment',
        component: InputText,
      },
      staff_comment: { name: 'Staff Comment', component: InputText },
      private_staff_comment: {
        name: 'Private Staff Comment',
        component: InputText,
      },
      comment_to_staff: {
        name: 'Comment to Staff',
        component: InputText,
      },
      result: {
        name: 'Αποτελεσμα',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'ok' },
            { value: 2, label: 'Μη επίλυση' },
            { value: 3, label: 'Αλλο' },
          ],
        },
      },
      exclude_from_down_time: {
        name: 'Εξαιρεση απο down time',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ναι' },
            { value: 2, label: 'Οχι' },
          ],
        },
      },
      exclude_from_reporting: {
        name: 'Εξαιρεση απο reporting',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ναι' },
            { value: 2, label: 'Οχι' },
          ],
        },
      },
    },
  },

  workParts: {
    fieldList: {
      material_id: {
        name: 'Υλικό',
        component: InputLookUp,
        width: 210,
        props: { displayKey: 'name', endpoint: '/admin/materials' },
      },
      quantity: {
        name: 'Ποσότητα',
        component: InputText,
      },
      unit_cost: {
        name: 'Κοστος',
        component: InputText,
      },
      unit_price: {
        name: 'Aξία πώλησης',
        component: InputText,
      },
      vat_percent: {
        name: 'Ποσ. ΦΠΑ',
        component: InputText,
      },
      discount: {
        name: 'Ποσ.Έκπτωσης',
        component: InputText,
      },
      total_price: {
        name: 'Τελική Αξία',
        component: InputText,
      },
    },
  },
  worksBilling: {
    viewUrl: '/admin/works/:id/billing',
    fieldList: {
      service_id: {
        name: 'Υπηρεσία',
        component: InputLookUp,
        width: 300,
        props: { displayKey: 'service', endpoint: '/admin/services' },
      },
      unit_price: {
        name: 'Αξία',
        component: InputText,
      },
      discount: {
        name: 'Έκπτωση',
        component: InputText,
      },
      total_price: {
        name: 'Τελ. Αξία Πελάτη',
        component: InputText,
      },
      quantity: {
        name: 'Ποσότητα',
        component: InputText,
      },
      unit: {
        name: 'Μον.Μετρ',
        component: InputText,
      },
    },
  },
  staffCost: {
    fieldList: {
      service_id: {
        name: 'Υπηρεσία',
        component: InputLookUp,
        props: { displayKey: 'service', endpoint: '/admin/services' },
      },
      unit_cost: {
        name: 'Κόστος Συνεργάτη',
        component: InputText,
        width: 210,
      },
      unit: {
        name: 'Μον.Μετρ',
        component: InputText,
      },
      quantity: {
        name: 'Ποσότητα',
        component: InputText,
      },
      discount: {
        name: 'Έκπτωση',
        component: InputText,
      },
      total_price: {
        name: 'Τελική Αξία Πελάτη',
        component: InputText,
      },
    },
  },
  users: {
    viewUrl: '/admin/users',
    addUrl: '/admin/users',
    editUrl: '/admin/users/:id',
    fieldList: {
      email: { name: 'Email', component: InputText },
      first_name: { name: 'Ονομα', component: InputText },
      last_name: { name: 'Επωνυμο', component: InputText },
      status: {
        name: 'Κατασταση',
        component: PickerChoiceSelection,
        props: {
          items: [
            { value: 0, label: 'please select' },
            { value: 1, label: 'Ενεργος' },
            { value: 2, label: 'Ανενεργος' },
          ],
        },
      },
    },
  },
};

class DetailedTable extends Component {
  config = {};
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableFields: [],
      editData: {},
      addData: {},
      addModalVisible: false,
    };

    let { preset } = props;
    if (preset && presets[preset]) {
      this.config = presets[preset];
    }

    for (let key of [
      'viewUrl',
      'editUrl',
      'addUrl',
      'addData',
      'editData',
      'filters',
      'fieldList',
    ]) {
      if (props[key]) {
        this.config[key] = props[key];
      }
    }

    let tableFieldList = { actionButtons: '' };
    this.config.widthArr = [100];
    for (let key in this.config.fieldList) {
      tableFieldList[key] = this.config.fieldList[key].name;
      this.config.widthArr.push(this.config.fieldList[key].width || 200);
    }
    this.state.tableFields = tableFieldList;
  }

  componentDidMount() {
    this.fetchData();
  }

  toggleEditRow = (id, editMode = null) => {
    let { data, editData } = this.state;
    if (editMode === null) {
      editMode = editData[id] === undefined;
    }

    if (!editMode) {
      delete editData[id];
    } else {
      editData[id] = Object.assign(
        {},
        data.find((item) => item.id === id)
      );
    }
    this.setState({
      editData: editData,
    });
  };

  fetchData = async () => {
    let res = await Api.get(this.config.viewUrl, {
      params: this.config.filters,
    });
    if (res.data.data) {
      this.setState({ data: res.data.data });
    }
  };

  addData = async () => {
    let data = this.config.addData
      ? { ...this.config.addData, ...this.state.addData }
      : this.state.addData;

    for (let k in data) {
      if (typeof data[k] === 'object' && data[k].id !== undefined) {
        data[k] = data[k].id;
      }
    }

    let resp = await Api.post(this.config.addUrl, data);
    if (resp?.data?.status === 'ok') {
      this.setState({ addModalVisible: false });
    }
    window.location.reload();
    return resp;
  };

  editData = async (data) => {
    let newData = this.config.editData
      ? { ...this.config.editData, ...data }
      : { ...data };

    for (let key in newData) {
      if (
        this.config.fieldList?.[key]?.component === InputLookUp &&
        typeof newData[key] === 'object'
      ) {
        newData[key] = newData[key]?.id;
      }
    }

    let res = await Api.put(
      this.config.editUrl.replace(':id', data.id),
      newData
    );
    if (res.data.status === 'ok') {
      this.toggleEditRow(data.id);
      // window.location.reload();
    }
  };

  renderTableData = () => {
    let { editData } = this.state;
    return this.state.data.map((item) => {
      let newRow = {
        actionButtons:
          this.state.editData[item.id] === undefined ? (
            <Button
              style={{ width: 30, height: 30, borderRadius: '100%' }}
              title="e"
              onPress={() => this.toggleEditRow(item.id)}
            />
          ) : (
            [
              <Button
                style={{ width: 30, height: 30, borderRadius: '100%' }}
                title="S"
                onPress={() => this.editData(this.state.editData[item.id])}
              />,
              <Button
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: '100%',
                  marginLeft: 5,
                }}
                title="C"
                onPress={() => this.toggleEditRow(item.id)}
              />,
            ]
          ),
      };

      for (let [key, field] of Object.entries(this.config.fieldList)) {
        if (field.component) {
          let value =
            editData[item.id] !== undefined
              ? editData[item.id][key]
              : item[key];

          if (
            field?.prepareValue &&
            typeof field?.prepareValue === 'function'
          ) {
            value = field.prepareValue(
              editData[item.id] !== undefined ? editData[item.id] : item
            );
          }
          if (
            field.component === InputLookUp &&
            field?.props?.displayKey &&
            typeof value !== 'object'
          ) {
            value = {
              id: value,
              [field.props.displayKey]: item[field.props.displayKey],
            };
          }

          newRow[key] = (
            <field.component
              {...field.props}
              value={value}
              onChangeValue={(val) => {
                let dd = { ...editData };
                dd[item.id][key] = val;
                this.setState({
                  editData: dd,
                });
              }}
              viewMode={editData[item.id] === undefined}
            />
          );
        }
      }
      return newRow;
    });
  };

  renderNewForm = () => {
    let addData = this.state.addData;
    let fields = [];

    for (let [key, field] of Object.entries(this.config.fieldList)) {
      if (field.component) {
        let value = this.state.addData[key] || field?.props?.value;

        fields.push(
          <field.component
            {...field.props}
            value={value}
            displayTitle={field.name}
            onChangeValue={(val) => {
              this.setState({
                addData: { ...addData, ...{ [key]: val } },
              });
            }}
            viewMode={false}
          />
        );
      }
    }

    return fields;
  };

  render() {
    return (
      <View style={styles.container}>
        <Button
          onPress={() => this.setState({ addModalVisible: true })}
          title="add"
          style={styles.button}
        />
        <Table
          widthArr={this.config.widthArr}
          dataMap={this.state.tableFields}
          data={this.renderTableData()}
        />

        <Modal
          onBackdropPress={() => this.setState({ addModalVisible: false })}
          isVisible={this.state.addModalVisible}
        >
          {this.renderNewForm()}
          <Button
            style={styles.button}
            title="add"
            onPress={() => this.addData()}
          />
        </Modal>
      </View>
    );
  }
}

export default DetailedTable;

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  modalView: {
    width: '50%',
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    alignSelf: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  button: {
    width: 100,
    marginBottom: 15,
  },
});
