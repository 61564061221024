import React, { Component } from 'react';
import { View, Text, ScrollView } from 'react-native-web';
import Api from '../api/Api';
import CustomMap from '../components/Map';
import Calendar from '../components/Calendar';
import EStyleSheet from 'react-native-extended-stylesheet';
import CardBlock from '../components/CardBlock';
import Table from '../components/Tables/Table';
import MapCardBlock from '../components/MapCardBlock';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

class AdminDashboard extends Component {
  state = {
    works: [],
    companies: [],
    staff: [],
    workPage: 0,
    staffPage: 0,
    companyPage: 0,
    companiesRefreshing: false,
    worksRefreshing: false,
    staffRefreshing: false,
  };

  fetchCompanies = async (page = this.state.companyPage) => {
    this.setState({ companiesRefreshing: true });
    let res = await Api.get('admin/companies', { params: { page: page } });
    const data = res.data.data;
    if (data.length) {
      this.setState({
        companies: [...this.state.companies, ...data],
        companyPage: page + 1,
      });
    }

    this.setState({ companiesRefreshing: false });
  };

  fetchWorks = async (page = this.state.workPage) => {
    this.setState({ worksRefreshing: true });
    let res = await Api.get('admin/works', { params: { page: page } });
    const data = res.data.data;

    if (data.length) {
      for (let x = 0; x < data.length; x++) {
        data[x].date_announced = moment(data[x].date_announced).format(
          'DD/MM/YYYY'
        );
        data[x].date_resolved = moment(data[x].date_resolved).format(
          'DD/MM/YYYY'
        );
      }
      console.log(data);
      this.setState({
        works: [...this.state.works, ...data],
        workPage: page + 1,
      });
    }

    this.setState({ worksRefreshing: false });
  };

  fetchStaff = async (page = this.state.staffPage) => {
    this.setState({ staffRefreshing: true });
    let res = await Api.get('admin/staff', { params: { page: page } });
    const data = res.data.data;
    if (data.length) {
      this.setState({
        staff: [...this.state.staff, ...data],
        staffPage: page + 1,
      });
    }

    this.setState({ staffRefreshing: false });
  };

  componentDidMount() {
    this.fetchWorks(0);
    this.fetchCompanies(0);
    this.fetchStaff(0);
  }

  onChange = (date) => this.setState({ date });

  render() {
    return (
      <View style={styles.wrapper}>
        <ScrollView style={[styles.scrollViewHeight1, { marginTop: 20 }]}>
          <MapCardBlock title="Sites">
            <CustomMap />
          </MapCardBlock>
        </ScrollView>

        <Calendar />

        <ScrollView style={[styles.scrollViewHeight, { marginTop: 50 }]}>
          <CardBlock padding={20} title="Works">
            <Table
              rowStyle={styles.rowStyle}
              data={this.state.works}
              dataMap={{
                client: 'Client',
                name: 'Name',
                service: 'Service',
                date_announced: 'Date Announced',
                date_resolved: 'Date Resolved',
              }}
            />
          </CardBlock>
        </ScrollView>

        <ScrollView style={[styles.scrollViewHeight, { marginTop: 50 }]}>
          <CardBlock padding={20} title="Staff">
            <Table
              rowStyle={styles.rowStyle}
              data={this.state.staff}
              dataMap={{
                lastname: 'Last Name',
                firstname: 'First Name',
                mobile: 'mobile',
                email: 'email',
              }}
            />
          </CardBlock>
        </ScrollView>
      </View>
    );
  }
}

export default AdminDashboard;

const styles = EStyleSheet.create({
  wrapper: {
    backgroundColor: '#eaeaea',
    width: '95%',
  },

  itemsText: {
    flex: 1,
    fontWeight: 'bold',
    justifyContent: 'space-between',
  },

  scrollViewHeight: {
    backgroundColor: '#eaeaea',
    height: 400,
  },

  scrollViewHeight1: {
    height: 600,
  },
});
