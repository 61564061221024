import React from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import Api from '../api/Api';

export class CustomMap extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  };

  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    sites: [],
  };

  fetchSites = async () => {
    let res = await Api.get('/admin/sites');
    let data = res.data.data;
    console.log(data);
    this.setState({ sites: data });
  };

  componentDidMount() {
    this.fetchSites();
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    return (
      <View>
        <Map
          google={this.props.google}
          zoom={10}
          center={{ lat: 37.970833, lng: 23.72611 }}
          style={{
            flex: 1,
            height: 500,
          }}
        >
          {this.state.sites.map((item) => (
            <Marker
              onClick={this.onMarkerClick}
              name={'Current location'}
              title={item.name}
              key={item.id}
              position={{
                lat: item.lat,
                lng: item.lng,
              }}
            />
          ))}
        </Map>
      </View>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAqwtSEBt-OllcCKxopwbYEdpa8UiNAMBM',
})(CustomMap);
