import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native-web';
import Api from '../api/Api';
import { createBrowserHistory } from 'history';
import InputText from '../components/Inputs/InputText';
import DetailedTable from '../components/Tables/DetailedTable';
import Button from '../components/Button';
import { useRouteMatch } from 'react-router-dom';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { TabView, Tab } from 'react-native-tab-view-easy';
import { TabBar } from 'react-native-tab-view';
import CardBlock from '../components/CardBlock';
import Messages from '../components/Messages';

let history = createBrowserHistory();

export default function AddClientForm(props) {
  const [client, setClient] = useState('');
  const [status, setStatus] = useState();
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [company, setCompany] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyCity, setCompanyCity] = useState('');
  const [companyPostCode, setCompanyPostCode] = useState('');
  const [viewMode, setViewMode] = useState('view');
  const [errorMessage, setErrorMessage] = useState('');

  let viewPath = useRouteMatch({
    path: '/clients/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/clients/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/clients/add',
    exact: true,
  });

  const validate = () => {
    if (!client || !city || !country) {
      setErrorMessage(`Required fields`);
      return false;
    }
    setErrorMessage('');
    return true;
  };

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  function addClient() {
    if (validate()) {
      Api.post('/admin/client', {
        client: client,
        status: status,
        address: address,
        city: city,
        country: country,
      }).then((res) => {
        history.goBack();
      });
    }
  }

  function editClient() {
    Api.put('/admin/client/' + props.match.params.id, {
      client: client,
      status: status,
      address: address,
      city: city,
      country: country,
    }).then((res) => {
      history.goBack();
    });
  }

  useEffect(() => {
    const fetchClient = async () => {
      const res = await Api.get('/admin/client/' + props.match.params.id);
      let data = res.data.data;
      setClient(data.client);
      setStatus(data.status);
      setAddress(data.address);
      setCity(data.city);
      setCountry(data.country);
    };
    if (props.match.params.id) {
      fetchClient();
    }
  }, [props.match.params.id]);

  useEffect(() => {
    const fetchClientCompanies = async () => {
      const res = await Api.get('/admin/companies');
      let data = res.data.data;
      setCompany(data.name);
      setCompanyAddress(data.address);
      setCompanyPostCode(data.post_code);
      setCompanyCity(data.city);
      setCountry(data.country);
    };
    fetchClientCompanies();
  }, []);

  const clearState = () => {
    return history.goBack();
  };

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(`/clients/${props.match.params.id}/edit`)
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addClient()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editClient()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      scrollEnabled={true}
      // tabStyle={{width: 'auto'}}
      style={{ backgroundColor: '#ee4023' }}
      getLabelText={({ route }) => route.title}
    />
  );

  return (
    <View style={styles.container}>
      <CardBlock>
        <View style={{ marginLeft: 10 }}>
          <PickerChoiceSelection
            items={[
              { value: 2, label: 'Disabled', key: 0 },
              { value: 1, label: 'Enabled', key: 1 },
            ]}
            selectedValue={status}
            onValueChange={(item) => setStatus(item)}
            displayTitle="Κατάσταση"
            viewMode={isView}
            value={status}
            textStyle={{ marginLeft: 13 }}
            textStyleValue={{ marginLeft: 14 }}
          />
        </View>
        <View style={{ width: 200, marginLeft: 10 }}>
          <InputText
            viewMode={isView}
            displayTitle="Πελατης"
            style={styles.inputText}
            value={client}
            onChangeText={(e) => setClient(e)}
            multiline={true}
            placeholder="Add Client"
            isRequired={true}
          />
          <InputText
            viewMode={isView}
            displayTitle="Διευθυνση"
            style={styles.inputText}
            value={address}
            onChangeText={(e) => setAddress(e)}
            multiline={true}
            placeholder="Add Address"
          />

          <InputText
            viewMode={isView}
            displayTitle="Πολη"
            style={styles.inputText}
            value={city}
            onChangeText={(e) => setCity(e)}
            multiline={true}
            placeholder="Add City"
            isRequired={true}
          />
          <InputText
            viewMode={isView}
            displayTitle="Χωρα"
            style={styles.inputText}
            value={country}
            onChangeText={(e) => setCountry(e)}
            multiline={true}
            placeholder="Add name"
            isRequired={true}
          />
          {renderButtons()}
          <View style={{ marginBottom: 20 }}>{renderEditButton()}</View>
        </View>
      </CardBlock>
      <View style={{ marginTop: 50 }}>
        {viewMode !== 'add' ? (
          <CardBlock>
            <TabView
              style={{ maxWidth: 1320, width: '100' }}
              renderTabBar={renderTabBar}
            >
              <Tab title="Εταιριες">
                <DetailedTable
                  preset="companies"
                  addData={{ client_id: props.match.params.id }}
                  viewUrl={`/admin/companies?client_id=${props.match.params.id}`}
                />
              </Tab>
              <Tab title="Χρηστες">
                <DetailedTable
                  viewUrl={`/admin/users?client_id=${props.match.params.id}`}
                  preset="users"
                  addData={{ client_id: props.match.params.id }}
                />
              </Tab>
            </TabView>
          </CardBlock>
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  buttonForm: {
    width: 200,
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 100,
    height: 35,
    alignSelf: 'flex-end',
    marginLeft: 15,
  },
});
