import React, { Component } from 'react';
import { View, TextInput, StyleSheet, Text } from 'react-native-web';
import Api from '../api/Api';
import Table from '../components/Tables/Table';
import Button from '../components/Button';
import CardBlock from '../components/CardBlock';
import InputText from '../components/Inputs/InputText';

class Companies extends Component {
  state = {
    data: [],
    searchInput: '',
    filters: {},
    filteredData: [],
    formDisplay: false,
  };

  componentDidMount() {
    this.fetchCompanies();
  }

  fetchCompanies = () => {
    Api.get('/admin/companies', {
      params: {
        client_id: this.state.filters.client_id,
        search: this.state.searchInput,
      },
    })
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        this.setState({ data: data, filteredData: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchTimeout;
  filteredCompanies = (text) => {
    this.setState({
      searchInput: text,
      filteredData: this.state.data.filter((item) => item.name.includes(text)),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchCompanies(), 1000);
  };

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  deleteCompany = (id) => {
    Api.delete('/admin/companies/ ' + id).then((res) => {
      console.log('deleted item with id' + id);
    });
  };

  render() {
    let filteredCompanies =
      !this.state.searchInput && this.state.filteredData.length === 0
        ? this.state.data
        : this.state.filteredData;

    filteredCompanies = filteredCompanies.map((item) => {
      item.button = (
        <Button onPress={() => this.deleteCompany(item.id)} title="Delete" />
      );
      return item;
    });

    this.state.data.map((item) => {
      return (item.buttons = (
        <>
          <View
            style={{
              width: 60,
              flexDirection: 'row',
              marginLeft: 15,
              marginBottom: 7,
            }}
          >
            <Button
              onPress={() =>
                this.props.history.push(`companies/${item.id}/edit`)
              }
              title="E"
              style={{ width: 30, height: 30, borderRadius: '100%' }}
            />
            <Button
              onPress={() => this.props.history.push(`/companies/${item.id}`)}
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
              }}
            />
          </View>
        </>
      ));
    });

    return (
      <View style={styles.container}>
        <View style={{ width: 350, flexDirection: 'row' }}>
          <Button
            title="Add New Company"
            onPress={() => this.props.history.push('companies/add')}
            style={styles.button}
          />
          <Button
            style={styles.button}
            title="close panel"
            onPress={() => this.Toggle()}
          />
        </View>

        {!this.state.formDisplay && (
          <View style={{ width: 200, marginBottom: 10 }}>
            <InputText
              placeholder="search"
              onChangeText={this.filteredCompanies}
              value={this.state.searchInput}
            />
          </View>
        )}

        <CardBlock title="Companies">
          <Table
            data={filteredCompanies}
            dataMap={{
              buttons: '',
              client: 'Πελατης',
              name: 'Εταιρια',
              city: 'Πολη',
              country: 'Χωρα',
              post_code: 'Ταχ.Κωδ.',
              tax_authority: 'Εφορια',
              vat: 'Α.Φ.Μ',
            }}
          />
        </CardBlock>
      </View>
    );
  }
}

export default Companies;

const styles = StyleSheet.create({
  container: {},

  text: {
    fontSize: 26,
    color: 'blue',
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    paddingRight: 180,
    borderStyle: 'dotted',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  input: {
    width: '20%',
    borderWidth: 1,
    borderRadius: 15,
  },

  button: {
    width: 180,
    marginTop: 15,
    marginLeft: 5,
  },
});
