import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import CardBlock from '../components/CardBlock';
import { PieChart } from 'react-charts-d3';

function randomNumber() {
  return Math.random() * (40 - 0) + 0;
}

const data = [
  { label: 'Closed Work', value: randomNumber() },
  { label: 'Work In progress', value: randomNumber() },
  { label: 'New work', value: randomNumber() },
];

class adminAnalytics extends Component {
  state = {};
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.rowDirection}>
          <CardBlock
            backgroundColor={{ backgroundColor: '#ffffff' }}
            title="WORK STATUS"
          >
            <PieChart
              labelOffset={180}
              height={400}
              displayAsDoughnut={true}
              data={data}
              colorScale={{ from: 'red', to: '#ffd300' }}
            />
          </CardBlock>

          <CardBlock
            backgroundColor={{ backgroundColor: '#ffffff' }}
            title="WORK SEVERITY"
          >
            <PieChart
              labelOffset={180}
              colorScale={{ from: 'red', to: '#ffd300' }}
              height={400}
              displayAsDoughnut={true}
              data={data}
            />
          </CardBlock>
        </View>
        <View style={{ marginTop: 30 }}>
          <CardBlock
            backgroundColor={{ backgroundColor: '#ffffff' }}
            title="lorem ipsum"
          >
            <View style={styles.faults}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>22</Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Closed</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>22</Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Total</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>22</Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Canceled</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
            </View>
            <View style={styles.faults}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>22</Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>High Priority</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.text}>22</Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Low priority</Text>
                  <Text style={styles.innerText}> Work Orders</Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <Text style={styles.text}>7%</Text>
                <View style={styles.column}>
                  <Text style={styles.innerText}>Faults</Text>
                  <Text style={styles.innerText}> frequency</Text>
                </View>
              </View>
            </View>
          </CardBlock>
        </View>
      </View>
    );
  }
}

export default adminAnalytics;

const styles = StyleSheet.create({
  container: {
    marginRight: 20,
    padding: 20,
  },

  rowDirection: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  faults: {
    flexDirection: 'row',
    marginVertical: 50,
  },
  text: {
    fontSize: 32,
    color: '#ee4023',
    fontWeight: 'bold',
  },
  innerText: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});
