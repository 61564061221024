import React, { PureComponent } from 'react';
import ModalView from '../Modals/ModalView';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native-web';
import InputView from './InputView';

export default class InputLookUp extends PureComponent {
  modalRef = React.createRef();

  onSelect = (...e) => {
    if (this.props.onSelect) {
      this.props.onSelect(...e);
    }
    if (this.props.onChangeValue) {
      this.props.onChangeValue(...e);
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <Text style={[styles.title, this.props.titleStyle]}>
          {this.props.displayTitle} {this.props.isRequired ? '*' : null}
        </Text>
        {!this.props.viewMode ? (
          <View style={styles.modal}>
            <TouchableOpacity
              style={styles.border}
              onPress={() => {
                this.modalRef.current.setModalVisible(true);
                this.modalRef.current.search();
              }}
            >
              <Text>{this.props.value?.[this.props.displayKey]} </Text>
            </TouchableOpacity>
            <ModalView
              ref={this.modalRef}
              displayKey={this.props.displayKey}
              endpoint={this.props.endpoint}
              searchQueryKey={this.props.search}
              onSelect={this.onSelect}
              isRequired={this.props.isRequired}
            />
          </View>
        ) : (
          <InputView>{this.props.value?.[this.props.displayKey]}</InputView>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  border: {
    borderWidth: 1,
    borderColor: '#ee4023',
    cursor: 'pointer',
    width: 200,
    height: 35,
    borderRadius: 15,
    alignText: 'center',
    textAlign: 'center',
    textAlignVertical: 'center',
  },

  title: {
    fontSize: 18,
    color: '#ee4023',
    fontFamily: 'ManropeSemiBold',
  },

  modal: {
    flexDirection: 'row',
  },
});
