import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import Api from '../api/Api';
import CardBlock from '../components/CardBlock';
import Table from '../components/Tables/Table';
import Button from '../components/Button';

export default (props) => {
  const [siteCategories, setSiteCategories] = useState([]);

  useEffect(() => {
    const fetchSiteCateogries = async () => {
      let res = await Api.get('/admin/sitesCategories');
      let data = res.data.data;
      setSiteCategories(data);
      console.log(data);
    };
    fetchSiteCateogries();
  }, []);

  return (
    <View>
      <View style={{ width: 450, flexDirection: 'row' }}>
        <Button
          title="add new site category"
          onPress={() => props.history.push('/sitecategories/add')}
        />
        <Button
          title="close panel"
          onPress={() => this.toggle()}
          style={{ marginLeft: 5 }}
        />
      </View>
      <CardBlock title="SITE CATEGORIES">
        <Table
          data={siteCategories}
          dataMap={{
            id: 'ID',
            site_category: 'SITE CATEGORY',
          }}
        />
      </CardBlock>
    </View>
  );
};
