import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import InputText from '../components/Inputs/InputText';
import { useRouteMatch } from 'react-router-dom';
import Api from '../api/Api';
import Button from '../components/Button';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { GridLayout, Row, Col } from '../components/Grid';
import { createBrowserHistory } from 'history';
import CardBlock from '../components/CardBlock';
import { set } from 'react-native-reanimated';

export default function MaterialsForm(props) {
  const [viewMode, setViewMode] = useState('view');
  const [name, setName] = useState();
  const [materialCategory, setMaterialCategory] = useState();
  const [description, setDescription] = useState();
  const [manufacturer, setManufacturer] = useState();
  const [serialNumber, setSerialNumber] = useState();
  const [partNumber, setPartNumber] = useState();
  const [supplier, setSupplier] = useState();
  const [buildDate, setBuildDate] = useState();
  const [unitCost, setUnitCost] = useState();
  const [unitPrice, setUnitPrice] = useState();
  const [status, setStatus] = useState();
  const [notes, setNotes] = useState();
  const [accessUsername, setAccessUsername] = useState();
  const [accessPassword, setAccessPassword] = useState();

  let history = createBrowserHistory();

  let viewPath = useRouteMatch({
    path: '/materials/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/materials/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/materials/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  useEffect(() => {
    const fetchMaterial = async () => {
      const res = await Api.get('/admin/materials/' + props.match.params.id);
      let data = res.data.data;
      setName(data.name);
      setMaterialCategory(data.materialCategory.id);
      setDescription(data.description);
      setManufacturer(data.manufacturer_id);
      setSerialNumber(data.serial_number);
      setPartNumber(data.part_no);
      setBuildDate(data.build_date);
      setSupplier(data.supplier_id);
      setUnitCost(data.unit_cost);
      setUnitPrice(data.unitPrice);
      setStatus(data.status);
      setNotes(data.notes);
      setAccessPassword(data.access_password);
      setAccessUsername(data.access_username);
    };
    if (props.match.params.id) {
      fetchMaterial();
    }
  }, [props.match.params.id, viewMode]);

  return (
    <View>
      <CardBlock title={'Material'} padding={20}></CardBlock>
    </View>
  );
}
