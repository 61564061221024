import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native-web';
import Table from '../components/Tables/Table';
import Api from '../api/Api';
import Button from '../components/Button';
import Filters from '../components/Filters';
import CardBlock from '../components/CardBlock';
import InputText from '../components/Inputs/InputText';
import moment from 'moment';

class AdminWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      works: [],
      filteredWorks: [],
      page: 0,
      panelDisplay: false,
      searchInput: '',
      companies: [],
    };
  }

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  fetchWorks = async ({ page = this.state.page, companyId, companyName }) => {
    let res = await Api.get('admin/works', {
      params: {
        page: page,
        company_id: companyId,
        company_name: companyName,
        search: this.state.searchInput,
      },
    });
    const data = res.data.data;
    data.map((item, i) => {
      data[i].severity =
        item.severity === 1
          ? 'Major'
          : item.severity === 2
          ? 'Medium'
          : item.severity === 3
          ? 'Minor'
          : 'Non Producation';

      data[i].priority =
        item.priority === 1 ? 'Low' : item.priority === 2 ? 'Medium' : 'High';
      data[i].status =
        item.status === 1
          ? 'Νέο'
          : item.status === 2
          ? 'Σε εξέλιξη'
          : item.status === 3
          ? 'Έκλεισε'
          : 'Ακυρώθηκε';

      data[i].result =
        item.result === 1 ? 'Ok' : item.result === 2 ? 'Μη επίλυση' : 'Άλλο';
      item.date_announced = moment(item.date_announced).format('DD/MM/YYYY');
      item.date_staff_informed = moment(item.date_staff_informed).format(
        'DD/MM/YYYY'
      );
      item.date_staff_acknowledged = moment(
        item.date_staff_acknowledged
      ).format('DD/MM/YYYY');
      item.date_started = moment(item.date_started).format('DD/MM/YYYY');
      item.date_visited = moment(item.date_visited).format('DD/MM/YYYY');
    });
    this.setState({
      works: data,
      filteredWorks: data,
      page: page + 1,
    });
  };

  deleteWork = async (id) => {
    await Api.delete('admin/works/' + id);
    console.log('deleted item with id ' + id);
  };

  fetchCompanies = async () => {
    let res = await Api.get('admin/companies');
    const data = res.data.data;
    console.log(data);
    this.setState({
      companies: data,
    });
  };

  componentDidMount() {
    this.fetchWorks(0);
    this.fetchCompanies();
  }

  searchTimeout;
  filteredWorks = (text) => {
    this.setState({
      searchInput: text,
      filteredWorks: this.state.works.filter((item) =>
        item.name.includes(text)
      ),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchWorks({ page: 1 }), 1000);
  };

  render() {
    let filteredWorks =
      !this.state.searchInput && this.state.filteredWorks.length === 0
        ? this.state.works
        : this.state.filteredWorks;

    console.log(this.state.filteredWorks);

    filteredWorks = filteredWorks.map((item) => {
      item.button = (
        <Button onPress={() => this.deleteWork(item.id)} title="Delete" />
      );
      return item;
    });

    filteredWorks.map((item) => {
      return (item.buttons = (
        <>
          <View
            style={{
              width: 60,
              flexDirection: 'row',
              marginLeft: 15,
              marginBottom: 7,
            }}
          >
            <Button
              onPress={() => this.props.history.push(`works/${item.id}/edit`)}
              title="E"
              style={{ width: 30, height: 30, borderRadius: '100%' }}
            />
            <Button
              onPress={() => this.props.history.push(`/works/${item.id}`)}
              title="V"
              style={{
                width: 30,
                height: 30,
                borderRadius: '100%',
                marginLeft: 5,
              }}
            />
          </View>
        </>
      ));
    });

    return (
      <View style={styles.container}>
        <View style={{ width: 300, flexDirection: 'row' }}>
          <Button
            onPress={() => this.props.history.push('/works/add')}
            title="Add New Work"
          />
          <Button
            style={{ marginLeft: 5 }}
            title="close panel"
            onPress={() => this.Toggle()}
          />
        </View>
        {!this.state.formDisplay && (
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ top: 5 }}>
              <Filters
                displayTitle="Εταιρία"
                onChangeFilters={(newFilters) => {
                  console.log(newFilters);
                  this.fetchWorks({
                    page: 0,
                    companyId: newFilters.company_id,
                    companyName: newFilters.name,
                  });
                }}
                fields={[
                  {
                    type: 'Picker',
                    key: 'company_id',
                    items: this.state.companies.map((item) => ({
                      key: item.id,
                      value: item.id,
                      label: item.name,
                    })),
                  },
                ]}
              />
            </View>
            <View style={{ width: 200, marginBottom: 10, marginLeft: 5 }}>
              <InputText
                placeholder="search"
                onChangeText={this.filteredWorks}
                value={this.state.searchInput}
              />
            </View>
          </View>
        )}
        <CardBlock title="WORKS">
          <Table
            widthArr={[
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
              170,
            ]}
            data={filteredWorks}
            dataMap={{
              buttons: '',
              client: 'Πελατης',
              contract_name: 'Συμβόλαιο',
              name: 'Εταιρια',
              site_name: 'Εγκατασταση',
              priority: 'Προτεραιότ.',
              cause_name: 'Αιτία-Βλάβη',
              severity: 'Βαρυτητα',
              status: 'Κατάσταση',
              staff_first_name: 'Τεχνικός',
              date_announced: 'Αναγγελία',
              date_staff_informed: 'Ενημέρωση',
              date_staff_acknowledged: 'Επιβεβαίωση',
              date_started: 'Έναρξη',
              date_visited: 'Επισκεψη',
              result: 'Αποτέλεσμα',
            }}
          />
        </CardBlock>
      </View>
    );
  }
}

export default AdminWorks;

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 20,
    color: 'blue',
  },
});
