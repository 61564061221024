import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Api from '../api/Api';
import CardBlock from '../components/CardBlock';
import Table from '../components/Tables/Table';
import Button from '../components/Button';

export default (props) => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchSuppliers = async () => {
      let response = await Api.get('/admin/suppliers', {
        params: {
          search: search,
        },
      });
      let data = response.data.data;
      data.map((item, i) => {
        data[i].status = item.status === 1 ? 'Enabled' : 'Disabled';
        data[i].buttons = (
          <>
            <View style={{ width: 60, flexDirection: 'row', marginLeft: 10 }}>
              <Button
                onPress={() => props.history.push(`suppliers/${item.id}/edit`)}
                title="E"
                style={{ width: 30, height: 30, borderRadius: '100%' }}
              />
              <Button
                onPress={() => props.history.push(`/suppliers/${item.id}`)}
                title="V"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: '100%',
                  marginLeft: 5,
                }}
              />
            </View>
          </>
        );
      });
      setSuppliers(data);
      setFilteredSuppliers(data);
      console.log(data);
    };
    fetchSuppliers();
  }, [props.history, search]);

  return (
    <View>
      <View style={{ width: 350, flexDirection: 'row' }}>
        <Button
          title="add new supplier"
          onPress={() => props.history.push('/suppliers/add')}
        />
        <Button
          title="close panel"
          // onPress={() => this.toggle()}
          style={{ marginLeft: 5 }}
        />
      </View>
      <CardBlock title="Suppliers">
        <Table
          data={suppliers}
          dataMap={{
            buttons: '',
            name: 'Ονομα',
            status: 'Κατασταση',
            city: 'Πολη',
            country: 'Χωρα',
            post_code: 'Ταχ.Κωδ',
            email: 'Email',
            address: 'Διευθ',
            tel: 'Τηλ',
          }}
        />
      </CardBlock>
    </View>
  );
};
