import React, { Component } from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import Modal from './Modal';
import Api from '../../api/Api';
import PropTypes from 'prop-types';
import { TextInput, TouchableOpacity } from 'react-native-web';
import Button from '../Button';
import InputText from '../Inputs/InputText';

export default class ModalView extends Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    searchQueryKey: PropTypes.string,
    onSelect: PropTypes.func,
    queryParams: PropTypes.object,
  };

  static defaultProps = {
    searchQueryKey: 'search',
    queryParams: {},
  };

  state = {
    modalVisible: false,
    data: [],
    searchInput: '',
  };

  setModalVisible = (visible) => {
    this.setState({ modalVisible: visible });
  };

  componentDidMount() {
    this.search();
  }

  searchTimeout;
  inputChange = (text) => {
    this.setState({ searchInput: text });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.search(text), 1000);
  };

  search = () => {
    Api.get(this.props.endpoint, {
      params: {
        ...this.props.queryParams,
        [this.props.searchQueryKey]: this.state.searchInput,
      },
    })
      .then((res) => {
        const data = res.data.data;
        this.setState({ data: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onSelect = (item) => {
    this.setModalVisible(false);
    this.props.onSelect(item);
  };

  renderItem = ({ item }) => {
    return (
      <TouchableOpacity style={styles.item} onPress={() => this.onSelect(item)}>
        <Text>
          {item[this.props.displayKey]}
          {item[this.props.displaySecondKey]}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <View style={[styles.centeredView]}>
        <Modal
          onBackdropPress={() => this.setState({ modalVisible: false })}
          isVisible={this.state.modalVisible}
        >
          <View style={styles.modalView}>
            <View style={styles.search}>
              <InputText onChangeText={this.inputChange} placeholder="search" />
            </View>
            <FlatList data={this.state.data} renderItem={this.renderItem} />
          </View>
          <Button
            style={{
              position: 'absolute',
              right: 10,
              top: 0,
              backgroundColor: 'white',
            }}
            title={'Close'}
            onPress={() => {
              this.setModalVisible(false);
            }}
          />
        </Modal>

        <TouchableOpacity
          style={styles.openButton}
          onPress={() => {
            this.setModalVisible(true);
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    alignItems: 'flex-start',
    bottom: 10,
  },
  modalView: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    alignSelf: 'center',
  },
  openButton: {
    borderColor: '#ee4023',
    cursor: 'pointer',
  },
  text: {
    color: '#ee4023',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  modalText: {
    textAlign: 'center',
  },
  item: {
    padding: 10,
    margin: 2,
    backgroundColor: 'rgb(214, 214, 214)',
  },
  search: {
    marginBottom: 20,
  },
});
