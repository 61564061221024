import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import Api from '../api/Api';
import Button from '../components/Button';
import { createBrowserHistory } from 'history';
import InputText from '../components/Inputs/InputText';
import { useRouteMatch } from 'react-router-dom';
import PickerChoiceSelection from '../components/Inputs/PickerChoiceSelection';
import { GridLayout, Row, Col } from '../components/Grid';
import CardBlock from '../components/CardBlock';
import Messages from '../components/Messages';

let history = createBrowserHistory();

export default function SuppliersForm(props) {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState();
  const [status, setStatus] = useState();
  const [tel, setTel] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [postCode, setPostCode] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/suppliers/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/suppliers/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/suppliers/add',
    exact: true,
  });

  const validate = () => {
    if (!name || !email || !status) {
      setErrorMessage('Required fields Missing');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  const renderEditButton = () => {
    if (viewMode === 'view') {
      return (
        <View style={{ width: 100 }}>
          <Button
            onPress={() =>
              window.location.replace(
                `/suppliers/${props.match.params.id}/edit`
              )
            }
            title="edit"
          ></Button>
        </View>
      );
    }
  };

  async function addSupplier() {
    if (validate()) {
      Api.post('/admin/suppliers', {
        name: name,
        city: city,
        country: country,
        status: status,
        post_code: postCode,
        email: email,
        tel: tel,
        address: address,
      }).then((res) => {
        let data = res.data.data;
        console.log(data);
        history.goBack();
      });
    }
  }

  async function editSupplier() {
    Api.put('/admin/suppliers/' + props.match.params.id, {
      name: name,
      city: city,
      country: country,
      status: status,
      post_code: postCode,
      email: email,
      tel: tel,
      address: address,
    }).then((res) => {
      let data = res.data.data;
      console.log(data);
      history.goBack();
    });
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    return (
      <>
        <View style={styles.buttonForm}>
          {viewMode === 'add' ? (
            <Button onPress={() => addSupplier()} title={'Save'} />
          ) : (
            <Button
              onPress={() => editSupplier()}
              style={styles.button}
              title={'Save'}
            />
          )}
          <Button
            style={{ marginLeft: 5 }}
            onPress={() => clearState()}
            title={'Cancel'}
          />
        </View>
        <Messages
          type={'error'}
          isVisible={!!errorMessage}
          message={errorMessage}
        />
      </>
    );
  }

  useEffect(() => {
    const fetchSupplier = async () => {
      let res = await Api.get('/admin/suppliers/' + props.match.params.id);
      let data = res.data.data;
      console.log(data);
      console.log(data.name);
      setName(data.name);
      setCountry(data.country);
      setCity(data.city);
      setPostCode(data.post_code);
      setStatus(data.status);
      setTel(data.tel);
      setAddress(data.address);
      setEmail(data.email);
    };
    if (props.match.params.id > 0) {
      fetchSupplier();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    history.goBack();
  }
  return (
    <View style={[styles.container]}>
      <CardBlock padding={20}>
        <GridLayout>
          <Row>
            <Col lg={6} md={12}>
              <View style={{ width: 300 }}>
                <InputText
                  displayTitle="Name"
                  viewMode={isView}
                  value={name}
                  onChangeText={(e) => setName(e)}
                  multiline={true}
                  placeholder="Add name"
                  isRequired={true}
                />
                <InputText
                  displayTitle="City"
                  viewMode={isView}
                  value={city}
                  onChangeText={(e) => setCity(e)}
                  multiline={true}
                  placeholder="Add city"
                />
                <InputText
                  displayTitle="Address"
                  viewMode={isView}
                  value={address}
                  onChangeText={(e) => setAddress(e)}
                  multiline={true}
                  placeholder="Add Address"
                />
                <InputText
                  displayTitle="Tηλεφωνο"
                  viewMode={isView}
                  value={tel}
                  onChangeText={(e) => setTel(e)}
                  multiline={true}
                  placeholder="Add Address"
                />
              </View>
            </Col>
            <Col lg={6} md={12}>
              <View style={{ width: 300 }}>
                <InputText
                  displayTitle="Email"
                  viewMode={isView}
                  value={email}
                  onChangeText={(e) => setEmail(e)}
                  multiline={true}
                  placeholder="Add email"
                  isRequired={true}
                />
                <InputText
                  displayTitle="country"
                  viewMode={isView}
                  value={country}
                  onChangeText={(e) => setCountry(e)}
                  multiline={true}
                  placeholder="Add country"
                />
                <InputText
                  viewMode={isView}
                  displayTitle="Ταχ.Κωδικας"
                  value={postCode}
                  placeholder="Add postal code"
                  onChangeText={(e) => setPostCode(e)}
                />

                <PickerChoiceSelection
                  items={[
                    { value: 0, label: 'Disabled', key: 0 },
                    { value: 1, label: 'Enabled', key: 1 },
                  ]}
                  selectedValue={status}
                  onValueChange={(item) => setStatus(item)}
                  displayTitle="Κατάσταση"
                  viewMode={isView}
                  value={status}
                  isRequired={true}
                  textStyle={{ marginLeft: 15, marginTop: 15 }}
                  textStyleValue={{ marginLeft: 15 }}
                />
              </View>
            </Col>
          </Row>
        </GridLayout>
        <View style={{ width: 300 }}>{renderButtons()}</View>
        {renderEditButton()}
      </CardBlock>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    width: 180,
    marginHorizontal: 5,
  },
  buttonForm: {
    flexDirection: 'row',
  },
});
