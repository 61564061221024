import React from 'react';
import Main from '../components/Wizard/Main';
import { View } from 'react-native';

export default () => {
  return (
    <View className="App">
      <View className="Content">
        <Main />
      </View>
    </View>
  );
};
