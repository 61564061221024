import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { View, Text, StyleSheet } from 'react-native';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class AssignDate extends Component {
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{this.props.displayTitle}</Text>
        <DatePicker
          selected={this.props.date}
          onChange={this.props.onDateChange}
          dateFormat="yyyy-MM-dd"
        />
      </View>
    );
  }
}

export default AssignDate;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    zIndex: 15,
    marginLeft: 48,
  },

  text: {
    width: '15%',
    marginTop: 20,
  },
});
