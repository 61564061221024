import 'react-native-gesture-handler';
import React from 'react';
import LoginScreen from './pages/Login';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AdminSites from './pages/Sites';
import AdminClients from './pages/Clients';
import Notifications from './pages/Notifications';
import Companies from './pages/Companies';
import AdminDashboard from './pages/Dashboard';
import Users from './pages/Users';
import UserForm from './pages/UserForm';
import adminAnalytics from './pages/Analytics';
import AdminTasks from './pages/Tasks';
import AdminWorks from './pages/Works';
import AdminLayout from './components/AdminLayout';
import ClientForm from './pages/ClientForm';
import SitesForm from './pages/SitesForm';
import CompanyForm from './pages/CompanyForm';
import WorksForm from './pages/WorksForm';
import EStyleSheet from 'react-native-extended-stylesheet';
import WorkTasksForm from './components/WorkTasksForm';
import RouteWithLayout from './components/RouteWithLayout';
import Contracts from './pages/Contracts';
import ContractsForm from './pages/ContractsForm';
import Suppliers from './pages/Suppliers';
import SuppliersForm from './pages/SuppliersForm';
import Services from './pages/Services';
import ServicesForm from './pages/ServicesForm';
import SiteCategories from './pages/SiteCategories';
import SiteCategoriesForm from './pages/SiteCategoriesForm';
import Wizard from './pages/Wizard';
import cookie from 'react-cookies';
import Config from './config/Config';
import Materials from './pages/Materials';
import MaterialsForm from './pages/MaterialsForm';
import ServiceTaskTemplates from './pages/ServiceTaskTemplates';
import ServiceTaskTemplatesForm from './pages/ServiceTaskTemplatesForm';
import Staff from './pages/Staff';
import StaffForm from './pages/StaffForm';
import Statistics from './pages/Statistics';
import RequestPassword from './pages/ForgottenPassword';
import ResetPassword from './pages/ResetPassword';

function App() {
  EStyleSheet.build();
  Config.API_TOKEN = cookie.load('token');
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/requestpassword" component={RequestPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/sites"
            component={AdminSites}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/clients"
            component={AdminClients}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/companies"
            component={Companies}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/dashboard"
            component={AdminDashboard}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/tasks"
            component={AdminTasks}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/works"
            component={AdminWorks}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/works/:id/tasks"
            component={WorkTasksForm}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/users"
            component={Users}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/analytics"
            component={adminAnalytics}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/servicetasktemplates"
            component={ServiceTaskTemplates}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/staff"
            component={Staff}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/staff/add ', '/staff/:id', '/staff/:id/edit']}
            component={StaffForm}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={[
              '/servicetasktemplates/add ',
              '/servicetasktemplates/:id',
              '/servicetasktemplates/:id/edit',
            ]}
            component={ServiceTaskTemplatesForm}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/materials"
            component={Materials}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/statistics"
            component={Statistics}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/works/add ', '/works/:id', '/works/:id/edit']}
            component={WorksForm}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/materials/add ', '/materials/:id', '/materials/:id/edit']}
            component={MaterialsForm}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/clients/add', '/clients/:id', '/clients/:id/edit']}
            component={ClientForm}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/sites/add', '/sites/:id', '/sites/:id/edit']}
            component={SitesForm}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/users/add', '/users/:id', '/users/:id/edit']}
            component={UserForm}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/companies/add', '/companies/:id', '/companies/:id/edit']}
            component={CompanyForm}
          />

          <RouteWithLayout
            layout={AdminLayout}
            exact
            path="/notifications"
            component={Notifications}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/contracts/add', '/contracts/:id', '/contracts/:id/edit']}
            component={ContractsForm}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={'/contracts'}
            component={Contracts}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={'/suppliers'}
            component={Suppliers}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/suppliers/add', '/suppliers/:id', '/suppliers/:id/edit']}
            component={SuppliersForm}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={'/services'}
            component={Services}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/services/add', '/services/:id', '/services/:id/edit']}
            component={ServicesForm}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={'/siteCategories'}
            component={SiteCategories}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={[
              '/siteCategories/add',
              '/siteCategories/:id',
              '/siteCategories/:id/edit',
            ]}
            component={SiteCategoriesForm}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact
            path={['/wizard']}
            component={Wizard}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
