import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const InputView = (props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{props.children}</Text>
    </View>
  );
};

export default InputView;

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  text: {
    fontSize: 14,
    lineHeight: 1.2,
    marginLeft: 5,
    fontFamily: 'Manrope-Regular',
  },
});
