import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS, createEventId } from '../utils/event-utils';
import moment from 'moment';
import Api from '../api/Api';

import React, { Component } from 'react';

export default class Calendar extends Component {
  state = {
    weekendsVisible: true,
    currentEvents: [],
    works: [],
  };

  fetchWorks = async () => {
    let res = await Api.get('/admin/works');
    let data = res.data.data;
    let val = data.map((item, i) => {
      data[i].severity =
        item.severity === 1
          ? 'Major'
          : item.severity === 2
          ? 'Medium'
          : item.severity === 3
          ? 'Minor'
          : 'Non Producation';
      return {
        id: item.id,
        title: 'Εγκατάσταση :' + ' ' + item.site_name,
        date: moment(item.date_announced).format('YYYY-MM-DD'),
        url: `/works/${item.id}`,
      };
    });

    this.setState({ works: val });
  };

  componentDidMount() {
    this.fetchWorks();
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();
    if (clickInfo.event.url) {
      window.open(clickInfo.event.url);
    }
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
      works: events,
    });
  };

  render() {
    return (
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={this.state.weekendsVisible}
        events={this.state.works}
        select={this.handleDateSelect}
        eventClick={this.handleEventClick}
      />
    );
  }
}
